import axios from "axios";
import CarouselSevices, { servicesTravel } from "../../share/components/CarouselServices/CarouselSevices";
import { useEffect } from "react";
import { useState, useContext } from "react";
import ReturnPage from "../../share/components/return/ReturnPage";
import "./styles.css";
import TextComponent from "../../share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { IoCloseCircle } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import UserContext from "@/Context/user/userProvider";
import Loading from "../../share/components/loading";


const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Obtener las partes individuales de la fecha
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();

    // Obtener las partes de la hora
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Formato final
    return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const MedicalPackagePay = () => {
    const [authUserSession, setAuthUserSession] = useState(null);
    const [payStatus, setPayStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [t, i18n] = useTranslation("global");
    const [flightUrl, setFlightUrl] = useState("/Tourismhealth");
    const [packageInfo, setPackageInfo] = useState([]);
    const [userPayments, setUserPayments] = useState([]);
    const { userDetail } = useContext(UserContext);
    const [reservationSent, setReservationSent] = useState(false);

    const [dollarFlight, setDollarFlight] = useState(
        localStorage.getItem("dollarFlight") === null
            ? "COP"
            : localStorage.getItem("dollarFlight")
    );

    const typeOfCoin = {
        name: dollarFlight,
        abbreviation: dollarFlight,
        simbol: "$",
    };

    const navigate = useNavigate();

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    useEffect(() => {
        setAuthUserSession(userDetail);

        const handleGetStatePay = async () => {
            await getStatePay(userDetail);
        }

        if (userDetail?.id && userDetail?.email) {
            handleGetStatePay();
        }

        const storedData = JSON.parse(localStorage.getItem('request-medical-datapackage'));
        if (storedData && storedData.length > 0) {
            setPackageInfo(storedData[0]);
        }
    }, [userDetail]);

    // useEffect que se ejecuta cuando cambian payStatus o packageInfo
    useEffect(() => {
        // Verifica si el pago está aprobado y packageInfo tiene todos los datos necesarios
        if (
            payStatus &&
            payStatus.status === "APPROVED" &&
            packageInfo.name &&
            packageInfo.lastname &&
            packageInfo.cel &&
            packageInfo.email &&
            packageInfo.packageId &&
            packageInfo.packagePrice &&
            packageInfo.comments &&
            packageInfo.currency &&
            packageInfo.city &&
            !reservationSent
        ) {
            sendReservationData();
        }
    }, [payStatus, packageInfo, reservationSent]);

    const getStatePay = async (authUserData) => {
        try {
            const requestId = localStorage.getItem('request-id-medicalpackage');

            if (!requestId) {
                navigate("/TourismHealth");
            }

            const url = `${process.env.REACT_APP_URL_STATE_PAY}medical-packacage`;

            const medicalPaymentStatus = await axios.post(url, {
                request_id: Number(requestId)
            });

            if (medicalPaymentStatus.data?.pay?.status === "APPROVED") {
                setPayStatus(medicalPaymentStatus.data.pay);

                const paymentsArray = [];
                paymentsArray.push(medicalPaymentStatus.data?.pay);
                setUserPayments(paymentsArray);

                const paymentInformation = medicalPaymentStatus.data?.pay;

                const paymentDataToStore = {
                    user_id: authUserData?.id,
                    email: authUserData?.email,
                    localizator: paymentInformation.localizator,
                    payment_date: paymentInformation.date,
                    payment_created_at: paymentInformation.created_at,
                    payment_updated_at: paymentInformation.updated_at,
                    price: paymentInformation.price.toString(),
                    reference: paymentInformation.reference,
                    reason: paymentInformation.reason,
                    status: paymentInformation.status,
                    request_id: paymentInformation.request_id,
                    type: paymentInformation.type
                }; 

                const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
                await axios.post(url, paymentDataToStore);
                notify("Pago aprobado", "✅");
                notify("Reserva completada!", "✅");
                setLoading(false);
                
            } else {
                setPayStatus(medicalPaymentStatus.data.pay);
            }
            
        } catch (error) {
            setLoading(false);
            console.error('Error fetching package medic:', error);
        }
    };

    const sendReservationData = async () => {
        try {
            if (!packageInfo.name || !packageInfo.lastname || !packageInfo.cel || !packageInfo.email || !packageInfo.packageId || !packageInfo.packagePrice || !packageInfo.comments || !packageInfo.currency || !packageInfo.city) {
                return;
            }

            // Actualiza el estado para indicar que la reserva se ha enviado
            setReservationSent(true);

            const reservationData = {
                user_id: authUserSession.id,
                full_name: `${packageInfo.name} ${packageInfo.lastname}`,
                terms: true,
                tel: packageInfo.cel,
                email: packageInfo.email,
                medical_package_id: packageInfo.packageId,
                state: "reserved",
                total_value: packageInfo.packagePrice,
                origin: "wellezy",
                observations: packageInfo.comments,
                type: "COP",
                city: packageInfo.city,
                payments: JSON.stringify(userPayments),
                date_from:packageInfo.package.trip_from
            };
    
            const url = `${process.env.REACT_APP_URL_API_AIOP}api/medic/package/reserve`;
            await axios.post(url, reservationData) 
            
            localStorage.removeItem('request-medical-datapackage');
            localStorage.removeItem('request-id-medicalpackage');
            localStorage.removeItem('data-pay-medicalpackage');
            
           
        } catch (error) {
            console.error('Error fetching package medic:', error);
            // Si ocurre un error, restablece el estado para permitir reintentos
            setReservationSent(false);
        }
    };

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false} />
            <CarouselSevices current={servicesTravel[0].title} />
            <ReturnPage nav={flightUrl} reload={false} />
            {
                loading &&
                <Loading size={90}/>
            }
            
            <div className='methodPayContainer' >
                <div className='methodPayHeader'>
                    <h3>Estado de tu transaccion</h3>
                    <p>El estatus de tu pago actualmente</p>
                </div>
                <div className='methodPayContain' >
                    <div className='methodPayContainQuestions'>
                        <div className='methodPayContainerTermsContain'>
                            <div className='detailPayFlight'>
                                <div className="detailPayFlightHeader">
                                    <div className="detailPayFlightHeaderImage">
                                        <img src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${packageInfo?.packageImage}`} alt="Paquete Médico" />
                                    </div>
                                    <div className="detailPayFlightInfo">
                                        <h2>
                                            {packageInfo?.packageName}
                                        </h2>
                                    </div>
                                </div>

                                <h3>Informacion de tu paquete</h3>

                                <div className="itemDetailsFlights">
                                    <div className="itemDetailsFlights1">
                                        <div className="itemDetailsFlightsColumn1">
                                            <p className="typeFlight">
                                                <strong>Fecha de pago</strong> {formatDate(payStatus?.created_at)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="itemDetailsFlights2">
                                        <div className="itemDetailsFlightsColumn1">
                                            <p className="typeFlight">
                                                <strong>Referencia de pago</strong> {payStatus?.reference}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="methodPayContainInfo">
                        <div className="methodPayImageContain">
                            <div className="methodPayContainInfoContain">
                                <div
                                    className={`contentStatePay ${payStatus?.status === "APPROVED"
                                        ? "APPROVED"
                                        : payStatus?.status === "PENDING"
                                            ? "PENDING"
                                            : payStatus?.status === "REJECTED"
                                                ? "REJECTED"
                                                : ""
                                        }`}
                                >
                                    {payStatus?.status === "APPROVED" ? (
                                        <GoCheckCircleFill size={40} />
                                    ) : payStatus?.status === "PENDING" ? (
                                        <TbLoader2 size={40} />
                                    ) : payStatus?.status === "REJECTED" ? (
                                        <IoCloseCircle size={40} />
                                    ) : (
                                        <BiQuestionMark size={40} />
                                    )}
                                    <div>
                                        <div className="contentStatePayMessage">
                                            <h3>Estado:</h3>
                                            <p>
                                                {payStatus?.status === "APPROVED"
                                                    ? "Aprobada"
                                                    : payStatus?.status === "PENDING"
                                                        ? "Pendiente"
                                                        : payStatus?.status === "REJECTED"
                                                            ? "Denegada"
                                                            : "Cargando..."}
                                            </p>
                                        </div>
                                        <p>
                                            {payStatus?.status === "APPROVED"
                                                ? "¡Pago realizado con exito!"
                                                : payStatus?.status === "PENDING"
                                                    ? "¡Pago pendiente!"
                                                    : payStatus?.status === "REJECTED"
                                                        ? "¡No fue posible realizar el pago!"
                                                        : "Cargando..."}
                                        </p>
                                    </div>
                                </div>
                                <div className="methodPayContainInfoContainPadding">
                                    <div>
                                        <h3>Compra finalizada</h3>
                                        <p>Total de la compra</p>
                                    </div>
                                    <div className="methodValuesContaint">
                                        <div className="methodValuesItem">
                                            <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                                            <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                                        </div>
                                        {/* 
                                        <div className="methodValuesItem">
                                            <h4>{t("fligth.form_passenger.step3.other_taxes")}:</h4>
                                            <span>$ 1223</span>
                                        </div>*/}
                                        <div className="methodValuesItemTotal">
                                            <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                                            <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="securityPay">
                                <SvgIcon
                                    color={"var(--bluePrimary)"}
                                    name={iconList.IconKey}
                                    size={40}
                                />
                                <div>
                                    <TextComponent
                                        children={"Pago Seguro"}
                                        size={"md"}
                                        fontWeight={"bold"}
                                        tag="h4"
                                    />
                                    <TextComponent
                                        children={"Los niveles más altos para los pagos con tarjetas"}
                                        size={"md2"}
                                        fontWeight={"light"}
                                        tag="span"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {payStatus?.status === "APPROVED" && (
                    <p>
                        Su transacción  fue aprobada por la entidad financiera. En caso de tener dudas sobre el vuelo, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a reservas@wellezy.com
                    </p>
                )}

                {payStatus?.status === "REJECTED" && (
                    <p>
                        Su transacción fue rechazada por la entidad financiera. En caso de tener dudas, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a reservas@wellezy.com
                    </p>
                )}
            </div>
        </div>
    );
};

export default MedicalPackagePay;
