import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

function MyMovements({ userId }) {
  const [movements, setMovements] = useState([]);
  const [totalPaid, setTotalPaid] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [t] = useTranslation("global");

  // Calcula el índice de inicio y fin para la paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMovements = movements.slice(indexOfFirstItem, indexOfLastItem);

  const nextHandler = () => {
    if (currentPage < Math.ceil(movements.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const getUserPayments = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments/${userId}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(t("profile.modules.my_movements.errors.get_payments"), error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUserPayments = async () => {
      const payments = await getUserPayments();

      const totalPaid = payments.reduce((total, payment) => {
          return total + payment.price;
      }, 0);

      setTotalPaid(totalPaid);
      setMovements(payments);
    };
    
    fetchUserPayments();
  }, []);

  return (
    <div className={styles.myMovements}>
      <h1 className="flex justify-between">
        <span>{t("profile.modules.my_movements.title")}</span>
        <span>{t("profile.modules.my_movements.total_payments")}: {formatPriceCOP(totalPaid)}</span>
      </h1>
    
      <div className={styles.movementItems}>
        {movements.length > 0 ? (
          movements.map((movement, index) => (
            <div key={index} className={styles.movementItem}>
              <div className={styles.details}>
                {/* show date with hours and minutes */}
                <p className="!text-customBlue !font-semibold"><span className="!text-gray-500">{index + 1}</span> - <span>{movement.reference}</span> {new Date(movement.payment_date).toLocaleString()}</p>
                <p class="flex gap-2">
                  <span className="font-bold text-customBlue">
                    {
                      movement.type === "PackTurist" ? 
                      t("profile.modules.my_movements.tourism_package")
                      : movement.type === "medicalPackage" ? 
                      t("profile.modules.my_movements.medical_package") : ""
                    }
                  </span>
                  -
                  <span className={movement.status === "APPROVED" ? "!text-green-700" : "text-red-700"}>
                    {
                      movement.status === "APPROVED" ? 
                      t("profile.modules.my_movements.approved_payment") 
                      : t("profile.modules.my_movements.pending_or_rejected_payment") 
                    }
                  </span> 
                </p>
              </div>
              <h3 className={styles.amount}>
                {formatPriceCOP(movement.price)}
              </h3>
            </div>
          ))
        ) : (
          <div className={styles.noItems}>
            <div className={styles.message}>
              {t("profile.modules.my_movements.without_movements")}
              <AiOutlineExclamationCircle
                size={20}
                color="var(--bluePrimary)"
              />
            </div>

            <Link to="/" className={styles.buy}>
              {t("profile.modules.my_movements.go_to_buy")}
            </Link>
          </div>
        )}
      </div>

      {currentMovements.length > 0 && (
        <Paginado
          nextHandler={nextHandler}
          prevHandler={prevHandler}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={movements}
          itemsPerPage={itemsPerPage}
        />
      )}
    </div>
  );
}

export default MyMovements;
