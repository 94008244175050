import axios from 'axios';
import { useState, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Api from './Api';
import { set } from 'date-fns';
import useTrasnlateBuildPackage from '@/Hooks/BuildPackage/useTranslateBuildPackage/useTranslateBuildPackage';
import { uuid } from '@/ui/utils';
import { setDataCart } from '@/store/slices/dataCartBuy/dataCartBuySlice';
import { useDispatch, useSelector } from 'react-redux';


const ResultSearch_Model = () => {
  const dispatch = useDispatch()
  const dataCart = useSelector((state) => state.dataCartBuy.dataCart);

  const extract = (stringt) => {
    const startIndex = pathname.indexOf(stringt);
    if (startIndex !== -1) {
      // Encontrado, buscar el índice del ampersand siguiente
      const endIndex = pathname.indexOf("&", startIndex);

      if (endIndex !== -1) {
        // Extraer el valor de dateOfDeparture
        const dateOfDeparture = pathname.slice(
          startIndex + stringt.length,
          endIndex
        );

        return dateOfDeparture;
      }
    }
  };

  const [menuHamburger, setMenuHamburger] = useState(false)
  const [buscadorMenu, setBuscadorMenu] = useState(false)
  const [filtersMenu, setFiltersMenu] = useState(false)
  const [passengerFilter, setPassengerFilter] = useState(1)
  const [filtrado, setFiltrado] = useState([0])
  const [activeFilterP, setActiveFilterP] = useState(false)
  const [activeFilterPrice, setActiveFilterPrice] = useState(false)
  const { state,pathname } = useLocation()
  const [resultados, setResultados] = useState([])
  const [resusltadosFirst, setResultadosFirst] = useState([])
  const [priceMin, setPriceMin] = useState(0)
  const [priceMax, setPriceMax] = useState(0)
  const [passMin, setPassMin] = useState(1)
  const [passMax, setPassMax] = useState(18)
  const [range, setRange] = useState([0, 0])
  const [firstRange, setFirstRange] = useState(0)
  const [secondRange, setSecondRange] = useState(0)
  const [errorFilter, setErrorFilter] = useState('')
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  const [isMouseDown, setIsMouseDown] = useState(false);
  const { servicesUrl } = Api()

  const routeStartsWithBuildPackageTrasnport = pathname.startsWith(
    "/build-package/transport"
  );

  const menuHambur = (type) => {
    setMenuHamburger(!menuHamburger)
    type ? setFiltersMenu(!filtersMenu) : setBuscadorMenu(!buscadorMenu)
  }

  const {
    nextStep
  } = useTrasnlateBuildPackage();

  const resetFilter = () => {
    setRange([priceMin, priceMax])
    setPassengerFilter(passMin)
    setResultados(resusltadosFirst)
    setMenuHamburger(false)
  }


  const handleDoubleRangeChange = (event, newRange) => {
    setRange(newRange)
  };

  const handleDoubleRangeChangeCommitted = (event, newRange) => {
    filterPerPrice(Number(newRange[0]), Number(newRange[1]));
    setMenuHamburger(false)
  };

  const handlePassRangeChange = (event, newRange) => {
    setPassengerFilter(newRange)
  };

  const handlePassRangeChangeCommitted = (event, newRange) => {
    filterPerPassenger();
    setMenuHamburger(false)
  };




  const filterPerPassenger = () => {
    if (resusltadosFirst.length > 0) {
      const filtrados = resusltadosFirst.filter(item => {
        const number = item.passengerCapacity.split('-')
        return Number(number[0]) <= passengerFilter && Number(number[1]) >= passengerFilter && item?.totalPriceUSD >= range[0] && item?.totalPriceUSD <= range[1]
      })
      setResultados(filtrados)
      setMenuHamburger(false)
    }
  }

  const filterPerPrice = (one, two) => {
    if (one === 0 || two === 0) {
      setErrorFilter('Es necesario llenar los 2 campos')
    } else {
      if (resusltadosFirst.length > 0) {
        setErrorFilter('')
        const filtrados = resusltadosFirst.filter(item => {
          const number = item.passengerCapacity.split('-')
          return item?.totalPriceUSD >= one && item?.totalPriceUSD <= two && Number(number[0]) <= passengerFilter && Number(number[1]) >= passengerFilter
        })

        setResultados(filtrados)
      }
    }
  }


  const request = {
    "transfer": extract('typeT='),
    "token": localStorage.getItem('politicas'),
    "city": extract('cityId='),
    "origin": extract('originId='),
    "destination": extract('destinyId='),
    "date_time": extract('dateApi='),
    "passenger": extract('adult='),
    "user": extract('userId='),
    "baby": extract('baby=')
  }



  // useEffect(() => {
  //   setRange([priceMin, priceMax])
  // }, [priceMax, priceMin])

  //   useEffect(() => {
  //     window.addEventListener('mousedown', handleMouseDown);
  //     window.addEventListener('mouseup', handleMouseUp);

  //     return () => {
  //         window.removeEventListener('mousedown', handleMouseDown);
  //         window.removeEventListener('mouseup', handleMouseUp);
  //     };
  // }, []);


  useEffect(() => {
    setLoader(true)
  
      axios.post(servicesUrl, request)
        .then(res => {
   
          if (!res.data.response) {
            setLoader(false)
          } else {
            setLoader(false)
            setResultados(res.data.response)
            setResultadosFirst(res.data.response)
            let minPrice = Infinity;
            let maxPrice = -Infinity;
            let minPass = Infinity;
            let maxPass = -Infinity;
            res.data.response.forEach(element => {
              if (element.totalPriceUSD < minPrice) {
                minPrice = element.totalPriceUSD
              }
              if (element.totalPriceUSD > maxPrice) {
                maxPrice = element.totalPriceUSD
              }
            });
            res.data.response.forEach(item => {
              const number = item.passengerCapacity.split('-')
              if (Number(number[0]) < minPass) {
                minPass = Number(number[0])
              }
              if (Number(number[1]) > maxPass) {
                maxPass = Number(number[1])
              }
            });
            setPriceMin(minPrice)
            setPriceMax(maxPrice)
            setPassMin(minPass)
            setPassMax(maxPass)
            setRange([minPrice, maxPrice]) // Actualizar el rango aquí
          }
        })
        .catch(error => {
          console.log(error)
          setLoader(false)
        })
   
  }, [pathname])


  const goToReservation = (item) => {

    const stateProps = {
      price: item?.totalPriceUSD,
      dateTime: extract('dateApi='),
      passengers: extract('adult='),
      baby: extract('baby='),
      token: localStorage.getItem('politicas'),
      product: item?.id,
      user: extract('userId='),
      type: extract('typeT='),
      origin: extract('originId='),
      destination: extract('destinyId='),
      city: extract('cityId='),
      service: item,
      id: uuid(extract('cityId=')),
      type: "Transport",
      url:pathname
    }
    
    if(routeStartsWithBuildPackageTrasnport){
      nextStep({
        price: item?.totalPriceUSD,
        dateTime: extract('dateApi='),
        passengers: extract('adult='),
        baby: extract('baby='),
        token: localStorage.getItem('politicas'),
        product: item?.id,
        user: extract('userId='),
        type: extract('typeT='),
        origin: extract('originId='),
        destination: extract('destinyId='),
        city: extract('cityId='),
        service: item
      })
    }else{
      dispatch(setDataCart([...dataCart, stateProps]));
      navigate("/transport/reserve", {
        state: stateProps
      })
    }
  }




  return {
    menuHambur,
    menuHamburger,
    buscadorMenu,
    filtersMenu,
    passengerFilter,
    setPassengerFilter,
    filtrado,
    activeFilterP,
    setActiveFilterP,
    resetFilter,
    setActiveFilterPrice,
    activeFilterPrice,
    setFirstRange,
    firstRange,
    secondRange,
    setSecondRange,
    errorFilter,
    resultados,
    filterPerPrice,
    filterPerPassenger,
    loader,
    goToReservation,
    setMenuHamburger,
    priceMax,
    priceMin,
    handleDoubleRangeChange,
    range,
    handleDoubleRangeChangeCommitted,
    handlePassRangeChange,
    handlePassRangeChangeCommitted,
    passMax,
    passMin
  }
}

export default ResultSearch_Model