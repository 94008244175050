import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IoWarning,
  IoCheckmarkCircle,
  IoCloseCircle,
  IoInformationCircle,
  IoClose,
} from "react-icons/io5";

function NotificationModal({ notifications, onClose, onMarkAsRead }) {
  const [modalNotifications, setModalNotifications] = useState([]);
  const {goToProfileRoute} = useUserProfile();

  const [t, i18n] = useTranslation("global");

  const handleMarkAsRead = async (notificationId) => {
    try {
      await onMarkAsRead(notificationId);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  }

  const handleSeeNotifications = () => {
    goToProfileRoute("unread");
  };

  const manageNotificationTitle = (notificationTitle) => {
    switch (notificationTitle) {
      case "Bienvenido/a":
        return t("profile.modules.my_notifications.titles.welcome");
      case "Reserva completada":
        return t("profile.modules.my_notifications.titles.completed_reserve");
      case "Pago realizado":
        return t("profile.modules.my_notifications.titles.payment_done");
      default:
        return notificationTitle
    }
  }

  return (
    <div className="absolute top-full right-0 mt-2 w-96 bg-white shadow-lg rounded-lg border border-gray-200 z-50">
      <div className="flex items-center justify-between p-4 border-b">
        <div>
          <h3 className="text-lg font-semibold">{t("home.notifications.unread")}</h3>
          <button className={`text-xs ${window.location.pathname === '/profile' ? 'text-gray-500' : 'underline cursor-pointer text-customBlue hover:text-customHoverBlue'}`} 
            onClick={handleSeeNotifications} disabled={window.location.pathname === '/profile'}>
              {t("home.notifications.go_to")}
            </button>
        </div>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <IoClose size={20} />
        </button>
      </div>
      <div className="max-h-80 overflow-y-auto customScroll">
        {notifications?.length > 0 ? (
          notifications
            .filter((notification) => notification.is_read === 0) 
            .map((notification) => (
              <div
                key={notification.id}
                className="flex items-center p-4 hover:bg-gray-50 transition w-full"
              >
                <div className="mr-4">
                  {notification.type === "info" && (
                    <IoInformationCircle className="text-blue-500 w-5 h-5" color="blue" />
                  )}
                  {notification.type === "success" && (
                    <IoCheckmarkCircle className="text-green-500 w-5 h-5" />
                  )}
                  {notification.type === "warning" && (
                    <IoWarning className="text-yellow-500 w-5 h-5" />
                  )}
                  {notification.type === "error" && (
                    <IoCloseCircle className="text-red-500 w-5 h-5" />
                  )}
                </div>
                <div className="w-full">
                  <h4 className="text-sm font-semibold text-customBlue">
                    {manageNotificationTitle(notification.title)}
                  </h4>
                  <p className="text-xs text-gray-600">{notification.message}</p>
                  <div className="flex justify-between mt-2">
                    <button
                      onClick={() => handleMarkAsRead(notification.id)}
                      className="text-xs underline text-green-700 hover:text-green-900 cursor-pointer"
                    >
                       {t("home.notifications.mark_as_read")}
                    </button>
                    <span className="text-xs text-customBlue">
                      {new Date(notification.created_at).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 p-4">{t("home.notifications.without_notifications")}</p>
          )}
      </div>
    </div>
  );
}

export default NotificationModal;