import { useState, useEffect, useContext } from "react";
import useJoinScreen from "../../../viewModels/videoSdk/joinScreen.viewModel";
import { useMeeting } from "@videosdk.live/react-sdk";
import Image from "./assets/meet.png";
import { CallContext } from "@/Context/videoCall/CallProvider";
import { useTranslation } from "react-i18next";

function JoinScreen(props) {
  const {
    code = "",
    setCode,
    createRoom,
    disableButton,
  } = useJoinScreen(props);

  const [t] = useTranslation("global");

  const { setCallData, setIsCallActive, setMeetingId, setParticipants, setPresenterId } = useContext(CallContext);

  const joinMeeting = () => {
    if (code) {
      setCallData((prev) => ({
        ...prev,
        status: "JOINING",
        closeMeeting: true,
      }));
      join({ meetingId: code });
      
    }
  };

  const {
    meetingId,
    isValidatingRoom,
    isDisabledRoom,
    onMeetingLeave,
  } = props;

  const [joined, setJoined] = useState(null);

  const { join, leave, participants, presenterId} = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
      setCallData((prev) => ({
        ...prev,
        status: "JOINED",
      }));
      setIsCallActive(true);

    },
    onMeetingLeft: () => {
      onMeetingLeave();
      setCallData((prev) => ({
        ...prev,
        status: "DISCONNECTED",
      }));
      setParticipants([])
      setIsCallActive(false);
    },
  });

  useEffect(() => {
    if (code.length > 7) {
      createRoom(true);
    }
    setMeetingId(meetingId)
  }, [code, disableButton]);

  useEffect(() => {
    setParticipants([...participants.keys()])
    setPresenterId(presenterId);
  }, [participants])
  


  return (
    <div className="videsdk joinScreen">
      <div className="form">
        <div className="card">
          <h2 className="cardTitle">
            {t("profile.modules.telemedicine.first_title")} 
            <br /> 
            {t("profile.modules.telemedicine.second_title")} 
          </h2>
          <p className="cardText">
            {t("profile.modules.telemedicine.first_description")} 
            <br /> 
            {t("profile.modules.telemedicine.second_description")} 
          </p>
          <div className="containerJoinInput">
           
            <input
              type="text"
              className="inputJoin"
              placeholder={t("profile.modules.telemedicine.enter_code")}
              value={code}
              onChange={(e) => {
                setCode(e.target.value || "")
                setMeetingId(e.target.value || "")
              }}
            />

            <button
              onClick={joinMeeting}
              className={`${disableButton || !code
                ? "joinScreenDisableButtonOn"
                : "joinScreenDisableButtonOff"
                }`}
            >
              <p className="btnText">{t("profile.modules.telemedicine.join")}</p>
            </button>
          </div>
          {!isValidatingRoom && isDisabledRoom && (
            <p className="errorMeeting">
              {t("profile.modules.telemedicine.errors.unavailable_room")}
            </p>
          )}
        </div>


        <div className="joinScreenImage">
          <img src={Image} alt="Meet" />
        </div>
      </div>
    </div>
  );
}

export default JoinScreen;
