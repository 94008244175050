import axios from "axios";
import CarouselSevices, { servicesTravel } from "../../share/components/CarouselServices/CarouselSevices";
import { useEffect } from "react";
import { useState } from "react";
import ReturnPage from "../../share/components/return/ReturnPage";
import "./styles.css";
import TextComponent from "../../share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { IoCloseCircle, IoAdd, IoRemove, IoCheckmark } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import InputSimple from "@/Components/TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple";
import { CircularProgress } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";

const TourismPackagePay = () => {
  const [authUser, setAuthUser] = useState(null);
  const [payStatus, setPayStatus] = useState(null);
  const [t, i18n] = useTranslation("global");
  const [packageUrl, setPackageUrl] = useState("");
  const [packageInfo, setPackageInfo] = useState([]);
  const [isModalOpenPackage, setIsModalOpenPackage] = useState(false);
  const [observations, setObservations] = useState("");
  const [passengerCount, setPassengerCount] = useState(1)
  const [termConditions, setTermConditions] = useState(false);
  const [validate, setValidate] = useState("");
  const [isLoadingAuthInfo, setIsLoadingAuthInfo] = useState(true);
  const [userPayments, setUserPayments] = useState([]);
  const [isLoadingReserve, setIsLoadingReserve] = useState(false);

  const navigate = useNavigate();

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  const notify = (title, icon) =>
    toast(title, {
        icon: icon,
    });

  function closeModal() {
    setIsModalOpenPackage(false);
    setPassengerCount(1);
    setObservations("");
  }

  useEffect(() => {
    setIsLoadingAuthInfo(true);
    // Verify if there is an authenticated user
    const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
    if (!authUserSession?.user?.id) {
      navigate("/login");
    }


    setAuthUser(authUserSession?.user);

    // Verify if in the store Storage there is a request-ID-Pay-Tourism-Package to verify the payment status
    const requestId = localStorage.getItem("request-id-pay-tourism-package");
    const requestData = localStorage.getItem("data-pay-tourism-package");
    if (requestId) {
      checkReservationPaymentStatus(authUserSession?.user);
    } else {
      navigate("/packages");
    }

    if (requestData) {
      try {
        const data = JSON.parse(requestData);
        const packageId = data.packageId

        getPackageInfo(packageId);
        setPackageUrl(`/packages/${packageId}`);
      } catch (error) {
        console.error("Error al obtener los datos del paquete:", error);
      }
    }

    setIsLoadingAuthInfo(false);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // obtain the individual parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // The months begin at 0
    const year = date.getFullYear();

    // Obtain the parts of the hour
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Final format
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const getPackageInfo = async (packageId) => {
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/packs/tourism/${packageId}`;
      const response = await axios.get(url);
      setPackageInfo(response.data.pack);
    } catch (error) {
      console.error("Error al obtener los datos del paquete:", error);
    }
  }
  const checkReservationPaymentStatus = async (authUserData) => {
    try {
      const requestId = localStorage.getItem("request-id-pay-tourism-package");
      const url = process.env.REACT_APP_URL_STATE_PAY;
      const paymentStatus = await axios.post(`${url}pack`, { request_id: Number(requestId) });
      
      localStorage.setItem("data-payment-tourism-package-status", JSON.stringify(paymentStatus.data));

      if (paymentStatus.data?.pay?.status === "APPROVED") {
        const paymentsArray = []
        paymentsArray.push(paymentStatus.data?.pay)
        setUserPayments(paymentsArray);

        const paymentInformation = paymentStatus.data?.pay;

        const paymentDataToStore = {
          user_id: authUserData.id,
          email: authUserData.email,
          localizator: paymentInformation.localizator,
          payment_date: paymentInformation.date,
          payment_created_at: paymentInformation.created_at,
          payment_updated_at: paymentInformation.updated_at,
          price: paymentInformation.price.toString(),
          reference: paymentInformation.reference,
          reason: paymentInformation.reason,
          status: paymentInformation.status,
          request_id: paymentInformation.request_id,
          type: paymentInformation.type
        }

        const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-tourism-package')

        if (!checkIfHasBeenPaid) {
          const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
          await axios.post(url, paymentDataToStore);
          localStorage.setItem('has-been-paid-tourism-package', 'true');
          notify("Pago aprobado", "✅");
        } else {
          notify("El pago ya ha sido aprobado", "✅");
        }
      }
      else if (paymentStatus.data?.pay?.status === "REJECTED") {
        notify("Error: pago rechazado", "❌");
      } else {
        notify("Error: pago pendiente", "❌");
      }


      setPayStatus(paymentStatus.data?.pay);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOutside = (event) => {
    setTermConditions(false)
    event.stopPropagation()
    closeModal()
  }

  const handleClickedInside = (event) => {
    event.stopPropagation()
  }

  const handleFormSubmit = async () => {
    setIsLoadingReserve(true);
    if (!termConditions) {
      setValidate("termConditions");
      return;
    }

    const data = {
      user_id: authUser.id,
      name: authUser.name,
      email: authUser.email,
      tel: authUser.prex_phone_public + authUser.phone_public,
      city: packageInfo?.destination,
      observations,
      terms: termConditions,
      passengers: passengerCount,
      pack_id: packageInfo?.id,
      total_value: packageInfo?.price,
      payments: JSON.stringify(userPayments),
    };

    try {
      await axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/pack/form`, data);

      notify("Reserva completada! Redirigiendo a tu perfil...", "✅");

      setIsModalOpenPackage(false);
      localStorage.removeItem("request-id-pay-tourism-package");
      localStorage.removeItem("data-payment-tourism-package-status");
      localStorage.removeItem("data-pay-tourism-package");
      
      setTimeout(() => {
        navigate("/profile"); 
      }, 1500);
    } catch (error) {
      console.error("Error creando la reserva:", error);
    } finally {
      setIsLoadingReserve(false);
    }
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <CarouselSevices current={servicesTravel[0].title} />
      <ReturnPage nav={packageUrl} reload={true} />

      <div className='methodPayContainer' >
        <div className='methodPayHeader'>
          <h3>Estado de tu transaccion</h3>
          <p>El estatus de tu pago actualmente</p>
        </div>
        <div className='methodPayContain' >
          <div className='methodPayContainQuestions'>
            <div className='methodPayContainerTermsContain'>
              <div className='detailPayFlight'>
                <div className="detailPayFlightHeader">
                  <div className="detailPayFlightHeaderImage">
                    <img src={`${process.env.REACT_APP_URL_API_AIOP}storage/pack/${packageInfo?.image}`} alt="Paquete Médico" />
                  </div>
                  <div className="detailPayFlightInfo">
                    <h2>
                      {packageInfo?.name}
                    </h2>
                  </div>
                </div>

                <h3>Informacion de tu paquete</h3>
                <div>
                  <p>
                    <strong>Destino:</strong> {packageInfo?.destination}
                  </p>
                  <p>
                    <strong>Fecha de inicio:</strong> {packageInfo?.start_date}
                  </p>
                  <p>
                    <strong>Fecha de finalización:</strong> {packageInfo?.due_date}
                  </p>

                  {payStatus?.status === "APPROVED" && (
                    <p className="mt-14">
                      <span>
                        <strong>
                          El pago se ha almacenado en el módulo de tu perfil 
                          <a href="/profile" target="_blank" className="ml-2 underline cursor-pointer text-green-700 hover:text-green-900">'Mis Movimientos'</a>
                        </strong>
                      </span>
                    </p>
                  )}
                </div>

                <div className="itemDetailsFlights">
                  <div className="itemDetailsFlights1">
                    <div className="itemDetailsFlightsColumn1">
                      <p className="typeFlight">
                        <strong>Fecha de pago</strong> {formatDate(payStatus?.created_at)}
                      </p>
                    </div>
                  </div>
                  <div className="itemDetailsFlights2">
                    <div className="itemDetailsFlightsColumn1">
                      <p className="typeFlight">
                        <strong>Referencia de pago</strong> {payStatus?.reference}
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="methodPayContainInfo">
            <div className="methodPayImageContain">
              <div className="methodPayContainInfoContain">
                <div
                  className={`contentStatePay ${payStatus?.status === "APPROVED"
                    ? "APPROVED"
                    : payStatus?.status === "PENDING"
                      ? "PENDING"
                      : payStatus?.status === "REJECTED"
                        ? "REJECTED"
                        : ""
                    }`}
                >
                  {payStatus?.status === "APPROVED" ? (
                    <GoCheckCircleFill size={40} />
                  ) : payStatus?.status === "PENDING" ? (
                    <TbLoader2 size={40} />
                  ) : payStatus?.status === "REJECTED" ? (
                    <IoCloseCircle size={40} />
                  ) : (
                    <BiQuestionMark size={40} />
                  )}
                  <div>
                    <div className="contentStatePayMessage">
                      <h3>Estado:</h3>
                      <p>
                        {payStatus?.status === "APPROVED"
                          ? "Aprobada"
                          : payStatus?.status === "PENDING"
                            ? "Pendiente"
                            : payStatus?.status === "REJECTED"
                              ? "Denegada"
                              : "Cargando..."}
                      </p>
                    </div>
                    <p>
                      {payStatus?.status === "APPROVED"
                        ? "¡Pago realizado con exito!"
                        : payStatus?.status === "PENDING"
                          ? "¡Pago pendiente!"
                          : payStatus?.status === "REJECTED"
                            ? "¡No fue posible realizar el pago!"
                            : "Cargando..."}
                    </p>
                  </div>
                </div>
                <div className="methodPayContainInfoContainPadding">
                  <div>
                    <h3>Compra finalizada</h3>
                    <p>Total de la compra</p>
                  </div>
                  <div className="methodValuesContaint">
                    <div className="methodValuesItem">
                      <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                      <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                    </div>
                    {/* 
                                        <div className="methodValuesItem">
                                            <h4>{t("fligth.form_passenger.step3.other_taxes")}:</h4>
                                            <span>$ 1223</span>
                                        </div>*/}
                    <div className="methodValuesItemTotal">
                      <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                      <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="securityPay">
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <TextComponent
                    children={"Pago Seguro"}
                    size={"md"}
                    fontWeight={"bold"}
                    tag="h4"
                  />
                  <TextComponent
                    children={"Los niveles más altos para los pagos con tarjetas"}
                    size={"md2"}
                    fontWeight={"light"}
                    tag="span"
                  />
                </div>
              </div>

              {payStatus?.status === "APPROVED" && (
                <div className="content-btn-reserve-modal-prereserve flex justify-end">
                  <button onClick={() => setIsModalOpenPackage(true)} className="btn-reserve-hotel-modal-prereserve flex gap-2">
                    <FaCircleCheck size={20} color="#fff" />
                    <span>
                      Finalizar reserva
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {payStatus?.status === "APPROVED" && (
          <p>
            Su transacción  fue aprobada por la entidad financiera. En caso de tener dudas sobre el vuelo, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a reservas@wellezy.com
          </p>
        )}

        {payStatus?.status === "REJECTED" && (
          <p>
            Su transacción fue rechazada por la entidad financiera. En caso de tener dudas, te recomendamos ponerte en contacto con nuestra línea de soporte/atención al cliente en 3004040116 o, alternativamente, puedes enviar un correo electrónico a reservas@wellezy.com
          </p>
        )}
      </div>

      {isModalOpenPackage && (
        <div className="form-package-container" onClick={handleClickOutside}>
          {isLoadingAuthInfo ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (

            <div>
              <div className="modal-header-package !w-full">
                <p>COMPLETA TU RESERVA AHORA</p>
              </div>
              <div className="modal-form-package" onClick={handleClickedInside}>

                <div className="">
                    <p style={{ marginBottom: "10px" }}>Cantidad de pasajeros</p>
                    <div className="flex justify-between">
                      <button className="cursor-pointer" onClick={() => passengerCount > 1 && setPassengerCount(passengerCount - 1)}>
                        <IoRemove size={20} color="#104172" />
                      </button>
                      <input className="border border-gray-300 rounded-md py-2 text-center" type="number" value={passengerCount} readOnly />
                      <button className="" onClick={() => setPassengerCount(passengerCount + 1)}>
                        <IoAdd size={20} color="#104172"/>
                      </button>
                    </div>
                </div>

                <div className="observatiosn-textarea">
                  <p>Observaciones</p>
                  <textarea
                    style={{ border: "1px solid #D9D9D9", borderRadius: "8px", width: "100%", padding: "10px" }}
                    rows={3}
                    onChange={(e) => setObservations(e.target.value)}
                  />
                </div>
                <div className="input-see-data-passenger">
                  <input
                    type="checkbox"
                    id="termConditions"
                    onClick={() => setTermConditions(!termConditions)}
                  />
                  <label htmlFor="termConditions" className="term-conditions">
                    {termConditions ? <IoCheckmark size={20} color="white" /> : null}
                  </label>
                  <div>
                    <p>Acepto términos y condiciones *</p>
                    {validate === "termConditions" && (
                      <p className="span-simple-error">Debes aceptar los términos y condiciones</p>
                    )}
                  </div>
                </div>
                <div className="button-container-package">
                  <button disabled={!observations || !termConditions} onClick={handleFormSubmit} className={!observations || !termConditions ? "!bg-gray-500" : ""}>{isLoadingReserve ? <CircularProgress style={{ color: "white" }} /> : "Reservar"}</button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TourismPackagePay;