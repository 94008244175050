import { Grid } from '@mui/material';
import styles from "../index.module.css";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { useState } from 'react';
import { IoChevronUp } from 'react-icons/io5';
import HotelInfo from './HotelInfo';
import ClinicInfo from './ClinicInfo';
import SpecialistInfo from './SpecialistInfo';
import FormInfo from './FormInfo';
import FlightInfo from './FlightInfo';
import { useTranslation } from 'react-i18next';

const truncateText = (text, maxLength) => {
    let truncatedText = text;
    if (text.length > maxLength) {
        truncatedText = text.substring(0, maxLength) + '...';
    }
    return truncatedText.charAt(0).toUpperCase() + truncatedText.slice(1).toLowerCase();
};

const truncateTextSinPuntos = (text, maxLength) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '';
    }
    return text;
};


const MyItineraryMain = ({ dataItineraryApi }) => {
    const {t, i18n} = useTranslation("global");
    const [expandedIndex, setExpandedIndex] = useState({ flight: null, hotel: null, std: null, clinic: null, specialist: null });

    const handleFlightClick = (index) => {
        setExpandedIndex({
            flight: expandedIndex.flight === index ? null : index,
            hotel: null,
            std: null,
            clinic: null,
            specialist: null
        });
    };

    const handleHotelClick = (index) => {
        setExpandedIndex({
            flight: null,
            hotel: expandedIndex.hotel === index ? null : index,
            std: null,
            clinic: null,
            specialist: null
        });
    };

    const handleStdClick = (index) => {
        setExpandedIndex({
            flight: null,
            hotel: null,
            std: expandedIndex.std === index ? null : index,
            clinic: null,
            specialist: null
        });
    };

    const handleClinicClick = (index) => {
        setExpandedIndex({
            flight: null,
            hotel: null,
            std: null,
            clinic: expandedIndex.clinic === index ? null : index,
            specialist: null
        });
    };

    const handleSpecialistClick = (index) => {
        setExpandedIndex({
            flight: null,
            hotel: null,
            std: null,
            clinic: null,
            specialist: expandedIndex.specialist === index ? null : index
        });
    };

    const handleCloseAll = () => {
        setExpandedIndex({ flight: null, hotel: null, std: null, clinic: null, specialist: null });
    };

    if (!dataItineraryApi || dataItineraryApi.length === 0) {
        return <div style={{display:'none'}} >
            <h3>{t("profile.modules.my_itinerary.without_created_packages")}</h3>
            <button>
                <a href="/build-package">
                    {t("profile.modules.my_itinerary.build_package")}
                </a>
            </button>
        </div>;
    }

    return (
        <>
            <div className={styles.itineraryDataApiDiv}>
                <h2>
                    {t("profile.modules.my_itinerary.built_packages")} ({dataItineraryApi?.length || 0})
                </h2>
            </div>

            <div className={styles.ItineraryMainApi}>
                {dataItineraryApi?.map((item, index) => {

                    const flight = item?.flight;
                    const hotel = item?.hotel;
                    const clinic = item?.clinic;
                    const itinerary = flight?.itinerary;
                    const specialist = item?.specialist;
                    const from = itinerary && itinerary.length > 0 ? itinerary[0].from : ' ';
                    const to = itinerary && itinerary.length > 0 ? itinerary[0].to : ' ';

                    const isFlightActive = expandedIndex?.flight === index;
                    const isHotelActive = expandedIndex?.hotel === index;
                    const isStdActive = expandedIndex?.std === index;
                    const isClinicActive = expandedIndex?.clinic === index;
                    const isSpecialistActive = expandedIndex?.specialist === index;

                    const visibleButtons = [flight, hotel, clinic, specialist, true].filter(Boolean)?.length;
                    const columnSize = 12 / visibleButtons;

                    const isAnyExpanded = isFlightActive || isHotelActive || isStdActive || isClinicActive || isSpecialistActive;

                    return (
                        <Grid container key={index} className={styles.ItineraryMainApi2}
                            sx={{ marginBottom: '1.8rem', }}
                        >

                            {flight && (
                                <Grid item xs={12} sm={6} lg={columnSize} sx={{
                                    borderRight: {
                                        xs: 'none',
                                        md: '3px solid #104172',
                                    },
                                    padding: '4px',
                                }}>
                                    <div className={isFlightActive ? styles.divTopCardPacgaMainactive : styles.divTopCardPacgaMain} onClick={() => handleFlightClick(index)}>
                                        <div>
                                            <SvgIcon color={isFlightActive ? "white" : "var(--bluePrimary)"} name={iconList.IconPlane} size={56} />
                                        </div>
                                        <div className={isFlightActive ? styles.divBottomCardPacgaActive : styles.divBottomCardPacga}>
                                            <h3>{t('profile.modules.my_itinerary.flight_to')} {truncateText(to, 10)}</h3>
                                            <span>{t('profile.modules.my_itinerary.from')} {truncateTextSinPuntos(from, 13)}</span>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            {specialist && (
                                <Grid item xs={12} sm={6} lg={columnSize} sx={{
                                    borderRight: {
                                        xs: 'none',
                                        md: '3px solid #104172',
                                    },
                                    padding: '4px',
                                }}>
                                    <div className={isSpecialistActive ? styles.divTopCardPacgaMainactive : styles.divTopCardPacgaMain} onClick={() => handleSpecialistClick(index)}>
                                        <div>
                                            <SvgIcon color={isSpecialistActive ? "white" : "var(--bluePrimary)"} name={iconList.IconDoctor} size={56} />
                                        </div>
                                        <div className={isSpecialistActive ? styles.divBottomCardPacgaActive : styles.divBottomCardPacga}>
                                            <h3>{truncateTextSinPuntos(item?.specialist?.name_specialist, 10)}</h3>
                                            <span>{truncateText(item?.specialist?.category ?? ' ', 13)}</span>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            {hotel && (
                                <Grid item xs={12} sm={6} lg={columnSize} sx={{
                                    borderRight: {
                                        xs: 'none',
                                        md: '3px solid #104172',
                                    },
                                    padding: '4px',
                                }}>
                                    <div className={isHotelActive ? styles.divTopCardPacgaMainactive : styles.divTopCardPacgaMain} onClick={() => handleHotelClick(index)}>
                                        <div>
                                            <SvgIcon color={isHotelActive ? "white" : "var(--bluePrimary)"} name={iconList.IconHotel} size={56} />
                                        </div>
                                        <div className={isHotelActive ? styles.divBottomCardPacgaActive : styles.divBottomCardPacga}>
                                            <h3>{truncateTextSinPuntos(item?.hotel?.hotel, 10)}</h3>
                                            <span>{truncateText(item?.hotel?.address ?? ' ', 13)}</span>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            {clinic && (
                                <Grid item xs={12} sm={6} lg={columnSize} sx={{
                                    borderRight: {
                                        xs: 'none',
                                        md: '3px solid #104172',
                                    },
                                    padding: '4px',
                                }}>
                                    <div className={isClinicActive ? styles.divTopCardPacgaMainactive : styles.divTopCardPacgaMain} onClick={() => handleClinicClick(index)}>
                                        <div>
                                            <SvgIcon color={isClinicActive ? "white" : "var(--bluePrimary)"} name={iconList.IconHospital} size={56} />
                                        </div>
                                        <div className={isClinicActive ? styles.divBottomCardPacgaActive : styles.divBottomCardPacga}>
                                            <h3>{truncateTextSinPuntos(item?.clinic?.clinic_name, 10)}</h3>
                                            <span>{truncateText(item?.clinic?.clinic_address ?? ' ', 13)}</span>
                                        </div>
                                    </div>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={6} lg={columnSize} sx={{
                                padding: '4px',
                            }}>
                                <div className={isStdActive ? styles.divTopCardPacgaMainactive : styles.divTopCardPacgaMain} onClick={() => handleStdClick(index)}>
                                    <div>
                                        <SvgIcon color={isStdActive ? "white" : "var(--bluePrimary)"} name={iconList.IconWalletOutLine} size={56} />
                                    </div>
                                    <div className={isStdActive ? styles.divBottomCardPacgaActive : styles.divBottomCardPacga}>
                                        <h3>{i18n.language === 'es' ? 'STD' : 'TDS'}</h3>
                                        <span>{i18n.language === 'es' ? 'Sistema de tr...' : 'Triage and di...'}</span>
                                    </div>
                                </div>
                            </Grid>


                            <div style={{ borderBottom: '2px solid #ECECEC', width: '100%', marginTop: '1.5rem', marginBottom: '1.7rem' }}> <p style={{ display: 'none' }}>.</p> </div>

                            {expandedIndex.flight === index && (
                                <FlightInfo dataFlight={item.flight} />
                            )}

                            {expandedIndex.hotel === index && (
                                <HotelInfo dataHotel={item.hotel} />
                            )}

                            {expandedIndex.std === index && (
                                <FormInfo dataForm={item.form} />
                            )}

                            {expandedIndex.clinic === index && (
                                <ClinicInfo dataClinic={item.clinic} />
                            )}

                            {expandedIndex.specialist === index && (
                                <SpecialistInfo dataSpecialist={item.specialist} />
                            )}

                            {isAnyExpanded && (
                                <div className={styles.CloseUpItinerary} onClick={handleCloseAll}>
                                    <IoChevronUp />
                                </div>
                            )}
                        </Grid>
                    );
                })}
            </div>
        </>
    );
};

export default MyItineraryMain;