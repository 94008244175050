// SpecialistInfo.jsx
import React from 'react';
import styles from "../index.module.css";

const SpecialistInfo = ({ dataSpecialist }) => {
    return (
        <div className={styles.mainClinicDivPackage}>
            <div className={styles.topClinicDivPackage}>
                <h3>{dataSpecialist?.name_specialist}</h3>
                <h4>{dataSpecialist?.category}</h4>
            </div>

            <div className={styles.infoSpecialistPackage}>

                <div className={styles.descriptionSpecialist}> 
                    <div className={styles.dataDesctip} >
                        <h3>Procedimientos del doctor</h3>
                        <p>
                            {dataSpecialist.specialties?.map((item, index) => item.specialty).join(', ')}
                        </p>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default SpecialistInfo; 