import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
import ModalWishToInclude from "../../../modalWishToInclude";
import ListFlights from "../listFlights";
import ContainerPriceDinamic from "../../../containerPriceDinamic";
import SvgIcon, { iconList } from "../../../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import UpsellChoose from "../../../upsellChoose";
import toast, { Toaster } from "react-hot-toast";
import Loading from "@/ui/views/share/components/loading";
import useFlightBuildPackage from "@/Hooks/BuildPackage/useFlightBuildPackage/useFlightBuildPackage";
import SessionModal from "@/ui/views/session/components/SessionModal/SessionModal";
import { setDataCart } from "@/store/slices/dataCartBuy/dataCartBuySlice";
import { useDispatch, useSelector } from "react-redux";
import { uuid } from "@/ui/utils";


function ContainerFlights({
  nameOrigin,
  nameDestiny,
  isRoundTrip,
  rangePrice,
  Seg1,
  Seg2,
  recommendation,
  allPassengersSelected,
  currency,
  bestOffer
}) {
  const dispatch = useDispatch();
  const dataCart = useSelector((state) => state.dataCartBuy.dataCart);
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const optionsRef = useRef(null);
  const urlpath = useLocation().pathname;
  const [outwardJourney, setOutwardJourney] = useState(null); //hora viaje de ida
  const [returnJourney, setReturnJourney] = useState(null); //hora viaje retorno
  const [openModalWish, setOpenModalWish] = useState(false);
  const [dataCabin, setDataCabin] = useState();
  const [dataComponents, setDataComponents] = useState();
  const [getUpsell, setGetUpsell] = useState(false);
  const [condicion, setCondicion] = useState(false)
  const [priceRecommendation, setPriceRecommendation] = useState({
    fareAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  });
  const [priceUpsell, setPriceUpsell] = useState({
    fareAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  });
  const routeStartsWithBuildPackageFlight = location.pathname.startsWith(
    "/build-package/flight"
  );

  useEffect(() => {
    if (condicion) {
      setReturnJourney(null)
      setOutwardJourney(null)
    } else {
      setCondicion(true)
    }
  }, [recommendation])


  const bagsLis = [iconList?.IconBagBig];

  function getPrice() {
    const totalWithTax = parseInt(
      recommendation?.recPriceInfo?.monetaryDetail[0].amount
    );
    const total = parseInt(
      recommendation?.recPriceInfo?.monetaryDetail[1].amount
    );
    const onlyTax = totalWithTax - total;
    setPriceRecommendation({
      fareAmount: onlyTax,
      taxAmount: total,
      totalAmount: totalWithTax,
    });
  }

  function getPricesperPAssengerType() {
    const all = recommendation?.paxFareProduct;
    const response = [];
    for (const a in all) {
      const obj = all[a];
      const data = {
        type: obj?.paxReference?.ptc,
        price: parseInt(obj?.paxFareDetail?.totalFareAmount),
        priceTaxt: parseInt(obj?.paxFareDetail?.totalTaxAmount),
        priceWithoutTaxt: parseInt(
          obj?.paxFareDetail?.totalFareAmount -
          obj?.paxFareDetail?.totalTaxAmount
        ),
      };
      response.push(data);
    }
    return response;
  }

  const {
    nextStep,
    activeSessionModal,
    setActiveSessionModal,
    setUrlSpecialist
  } = useFlightBuildPackage();

  function transformFlightData() {
    let groupCounter = 1; // Contador de grupos

    const data = [outwardJourney];
    if (isRoundTrip === "true") {
      data.push(returnJourney);
    }

    return data.map((item,index) => {
      const mainSegment = item.segments[0];
      const scaleSegments = item.segments.slice(1);

      // Formatear fechas y horas
      const formatDateTime = (date, time) => `${date}T${time}:00+0000`;

      // Armar objeto principal
      const mainObject = {
        group: groupCounter++,
        from: mainSegment.location[0].locationId,
        to: mainSegment.location[1].locationId,
        bookingClass:recommendation?.paxFareProduct[0]?.fareDetails[index]?.groupOfFares[0]
        ?.productInformation?.cabinProduct?.rbd,
        dateDeparture: formatDateTime(
          mainSegment.productDateTime.dateOfDeparture,
          mainSegment.productDateTime.timeOfDeparture
        ),
        dateArrival: formatDateTime(
          mainSegment.productDateTime.dateOfArrival,
          mainSegment.productDateTime.timeOfArrival
        ),
        company: mainSegment.companyId.marketingCarrier,
        flightNumber: mainSegment.flightOrtrainNumber,
        family:dataComponents[index]?.fareFamilyDetails?.fareFamilyname
      };

      // Añadir escalas solo si existen
      if (scaleSegments.length > 0) {
        mainObject.scale = scaleSegments.map((scaleSegment) => ({
          group: groupCounter++,
          from: scaleSegment.location[0].locationId,
          to: scaleSegment.location[1].locationId,
          bookingClass:recommendation?.paxFareProduct[0]?.fareDetails[index]?.groupOfFares[index]
          ?.productInformation?.cabinProduct?.rbd,
          dateDeparture: formatDateTime(
            scaleSegment.productDateTime.dateOfDeparture,
            scaleSegment.productDateTime.timeOfDeparture
          ),
          dateArrival: formatDateTime(
            scaleSegment.productDateTime.dateOfArrival,
            scaleSegment.productDateTime.timeOfArrival
          ),
          company: scaleSegment.companyId.marketingCarrier,
          flightNumber: scaleSegment.flightOrtrainNumber,
          family:dataComponents[index]?.fareFamilyDetails?.fareFamilyname
        }))
      }

      return mainObject;
    });
  }

  function getItinerary(isFromUpsell) {
    let intinerary;
    const response = [];
    const scales = [];
    const bigArray = [outwardJourney];
    if (isRoundTrip === "true") {
      bigArray.push(returnJourney);
    }

    function getIntinerary(data, number, pF = 0, pS = 0) {
      const dateDeparture = data?.productDateTime.dateOfDeparture;
      const timeDeparture = data?.productDateTime.timeOfDeparture;
      const dateArrival = data?.productDateTime.dateOfArrival;
      const timeArrival = data?.productDateTime.timeOfArrival;
      const obj = {
        group: parseInt(number),
        from: data?.location[0]?.locationId,
        to: data?.location[1]?.locationId,
        bookingClass:
          recommendation?.paxFareProduct[0]?.fareDetails[pF]?.groupOfFares[pS]
            ?.productInformation?.cabinProduct?.rbd,
        dateDeparture: `${dateDeparture}T${timeDeparture}:00+0000`,
        dateArrival: `${dateArrival}T${timeArrival}:00+0000`,
        company: data?.companyId?.marketingCarrier,
        flightNumber: data?.flightOrtrainNumber,
      };
      if (isFromUpsell) {
        obj.family = dataComponents[pF]?.fareFamilyDetails?.fareFamilyname;
      }
      return obj;
    }

    for (let i = 0; i < bigArray.length; i++) {
      const vuelo = bigArray[i];
      if (vuelo?.segments.length > 1) {
        intinerary = getIntinerary(vuelo?.segments[0], i + 1, i, 0);
        const scalesChild = vuelo?.segments;
        for (const s in scalesChild) {
          const child = scalesChild[s];
          if (s > 0) {
            const number = parseInt(i) + parseInt(s) + 1;
            const childItinerary = getIntinerary(child, number, i, i);
            scales.push(childItinerary);
          }
        }
        intinerary.scale = scales;
        response.push(intinerary);
      } else {
        intinerary = getIntinerary(vuelo?.segments[0], i + 1, i, 0);
        response.push(intinerary);
      }
    }

    return response;
  }

  function getCompany() {
    let response;
    const paxFareDetail = recommendation?.paxFareProduct[0]?.paxFareDetail;

    if (Array.isArray(paxFareDetail?.codeShareDetails)) {
      response = paxFareDetail?.codeShareDetails[0]?.company;
    } else {
      response = paxFareDetail?.codeShareDetails?.company;
    }

    return response;
  }

  function continueBuy(type = false, price = false) {
    const isFromUpsell = type;
    setOpenModalWish(false);
    const body = document.querySelector("body");
    body.style.overflow = "";

    const priceFrom = isFromUpsell ? price : priceRecommendation;


    const stateProps = {
      passengersSelected: allPassengersSelected,
      company: getCompany(),
      priceTotal: priceFrom?.totalAmount,
      priceTaxes: priceFrom?.taxAmount,
      priceWithOutTaxes: priceFrom?.fareAmount,
      passengersPrice: price ? price?.passengers : false,
      prices: getPricesperPAssengerType(),
      bag: false,
      currency: currency,
      fare: "RP",
      type:"Vuelo",
      itinerary: transformFlightData(),
      url:location.pathname,
      id:uuid(getCompany())
    };


    dispatch(setDataCart([...dataCart, stateProps]));

    const pathname = window.location.pathname.split("/");
    const isIframe = pathname.includes("iframe");
    const route = isIframe
      ? "/iframe/flightPassengersForm"
      : "/flightPassengersForm";

    if (routeStartsWithBuildPackageFlight) {
      nextStep(stateProps);
    } else if (urlpath.includes("/packages")) {
      localStorage.setItem("flightSelected", JSON.stringify(stateProps));
      navigate("/packages/pay");
    } else {
      if (allPassengersSelected?.adult * 2 >= (allPassengersSelected?.baby + allPassengersSelected?.boy + allPassengersSelected?.seat)) {
        console.log(stateProps)
        navigate(`${route}`, { state: stateProps });
      } else {
        setOpenModalWish(false)
        notify("Por cada adulto son 2 menores a cargo", "❌");
      }

    }

    // flightsCheckout
  }

  function chooseUpsell() {
    setOpenModalWish(false);
    setGetUpsell(true);
  }

  const closeGetandOpen = () => {
    setGetUpsell(false);
  };

  function toBuy(isFromUpsell) {
    if (!isFromUpsell) {
      setOpenModalWish(true);
    } else {
      continueBuy(true);
    }
  }

  const checkOptionsVisibility = () => {
    const rect = optionsRef.current.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
    if (!isVisible) {
      optionsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    openModalWish
      ? (body.style.overflow = "hidden")
      : (body.style.overflow = "");
  }, [openModalWish]);

  // useEffect(() => {
  //   if (getUpsell) {
  //     optionsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     checkOptionsVisibility();
  //   }
  // }, [getUpsell]);

  useEffect(() => {
    getPrice();
  }, [Seg1, Seg2, allPassengersSelected, recommendation]);

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

  const handleConfigFlight = (value) => {
    setOpenUpsell(true);
 
  };

  const [openUpsell, setOpenUpsell] = useState(false);

  return (
    <div className="cardFather">
      <SessionModal
        activeSessionModal={activeSessionModal}
        setActiveSessionModal={setActiveSessionModal}
        url={setUrlSpecialist.specialist}
      />
      <Toaster position="top-center" reverseOrder={false} />
      <ModalWishToInclude
        show={openModalWish}
        close={setOpenModalWish}
        continueBuy={continueBuy}
        chooseUpsell={chooseUpsell}
      />
      <div className="columnCards">
        <div className="containerFlightsSelects">
          <div className="containerFlightsSelectsColumns">
            <ListFlights
              isRoundTrip={isRoundTrip}
              Seg1={Seg1}
              Seg2={Seg2}
              nameOrigin={nameOrigin}
              nameDestiny={nameDestiny}
              recommendation={recommendation}
              outwardJourney={outwardJourney}
              returnJourney={returnJourney}
              setOutwardJourney={setOutwardJourney}
              setReturnJourney={setReturnJourney}
            />
            <div className="sectionConfigFlight" ref={optionsRef}>
              <span style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {bagsLis.map((index, key) => {
                  return (
                    <div key={key} className="bagsContainerIcon">
                      <SvgIcon
                        name={bagsLis[key]}
                        color="#1d1d1d45"
                        size={50}
                      />
                    </div>
                  );
                })}
                Agrega equipaje cambiando de categoría
              </span>
              <UpsellChoose
                continueBuy={continueBuy}
                getandOpen={getUpsell}
                closeGetandOpen={closeGetandOpen}
                isRoundTrip={isRoundTrip}
                outwardJourney={outwardJourney}
                returnJourney={returnJourney}
                recommendation={recommendation}
                price={priceRecommendation}
                toBuy={toBuy}
                setDataCabin={setDataCabin}
                setDataComponents={setDataComponents}
                allPassengersSelected={allPassengersSelected}
                setPriceUpsell={setPriceUpsell}
                openUpsell={openUpsell}
                setOpenUpsell={setOpenUpsell}
                currency={currency}
                activeSessionModal={activeSessionModal}
              />
            </div>

            {bestOffer && <span className="bestOfferFlight">
              Vuelo más conveniente
            </span>}
          </div>
          <div className="sectionPriceDinamicContainer">
            <ContainerPriceDinamic price={priceRecommendation} getChoose={chooseUpsell} toBuy={toBuy} currency={currency} />
          </div>


        </div>


      </div>
    </div>
  );
}
export default ContainerFlights;
