import { useState, useContext } from "react";
import { iconList } from "../../views/share/components/iconSVG";
import { logOut } from "../session/index.js";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../Context/user/userProvider";
import axios from "axios";
import { useTranslation } from "react-i18next";


function useUserProfile() {
  const [optionActive, setOptionActive] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationLength, setUnreadNotificationLength] = useState(0);
  const [notificationsSelecteds, setNotificationsSelecteds] = useState([]);
  const { userDetail, userToken } = useContext(UserContext);
  const [codeTeleconsutation, setCodeTeleconsutation] = useState("");
  const [codeMyMovements, setCodeMyMovements] = useState("");
  const [codeMyNotifications, setCodeMyNotifications] = useState("");
  
  const [t] = useTranslation("global");
  const navigate = useNavigate()

  
  const UserProfessional = [
    { value: 1, text: t("profile.modules.my_profile.text"), icon: iconList.IconProfile },
    { value: 3, text: t("profile.modules.my_movements.text"), icon: iconList.IconWalletOutLine },
    { value: 4, text: t("profile.modules.my_itinerary.text"), icon: iconList.IconOrders },
    { value: 5, text: t("profile.modules.my_appointments.text"), icon: iconList.IconCalendarOutLine2 },
    {
      value: 6,
      text: t("profile.modules.telemedicine.text"),
      icon: iconList.IconPeoples,
    },
    { value: 14, text: t("profile.modules.my_notifications.text"), icon: iconList.IconWalletOutLine },
    { value: 15, text: t("profile.modules.my_cart.text"), icon: iconList.IconCartBuy },
  ];
  

  const getUserNotifications = async () => {
    try {
      const userDetailLocalStorage = JSON.parse(
        localStorage.getItem("@authUserSession")
      )

      const userId = userDetailLocalStorage ? userDetailLocalStorage?.user?.id : userDetail.id
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-notifications/${userId}`;

      const response = await axios.get(url);
      const unread = response.data.filter((n) => n.is_read === 0).length;

      setNotifications(response.data)
      setUnreadNotificationLength(unread);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const markUserNotificationAsRead = async (notificationId) => {
    try {
      const userDetailLocalStorage = JSON.parse(localStorage.getItem("@authUserSession"));

      const userId = userDetail.id ? userDetail.id : userDetailLocalStorage?.user?.id

      const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-notifications/mark-as-read/${userId}/${notificationId}`;
      await axios.put(url);

      const updatedNotifications = notifications.map((n) =>
        n.id === notificationId ? { ...n, is_read: 1 } : n
      );

      setNotifications(updatedNotifications);

      const unread = updatedNotifications.filter((n) => n.is_read === 0).length;
      setUnreadNotificationLength(unread);
  
      // Shoot a global event to notify other components
      const event = new CustomEvent("notificationUpdated", {
        detail: { notificationId },
      });
      
      window.dispatchEvent(event);
    } catch (error) {
      console.log(error)
    }
  }

  function isSelectedThisNotification(id) {
    return !!(notificationsSelecteds.length > 0
      ? notificationsSelecteds.find((element) => element.id === id)
      : false);
  }

  function selectOrDeselectNotification(elementId) {
    const selected = isSelectedThisNotification(elementId);
    if (selected) {
      const newArray = notificationsSelecteds.filter(
        (elemente) => elemente.id !== elementId
      );
      setNotificationsSelecteds(newArray);
    } else {
      const newArray = [...notificationsSelecteds, { id: elementId }];
      setNotificationsSelecteds(newArray);
    }
  }

  const [configProfile, setConfigProfile] = useState([
    {
      showInProfile: true,
      id: "sms_notification",
      name: "Notificaciones de citas por SMS",
      description:
        "Recibirás mensajes SMS según la configuración del remitente",
      isActive: false,
    },
    {
      showInProfile: true,
      id: "email_marketing",
      name: "Notificaciones de marketing por email",
      description: "Recibe ofertas y novedades por email",
      isActive: true,
    },
    {
      showInProfile: true,
      id: "sms_marketing",
      name: "Notificaciones de marketing por SMS",
      description:
        "Si has enviando el SMS STOP y no sigues suscrito, responde START para volver a suscribirte",
      isActive: false,
    },
  ]);

  const switchOptionConfig = (id, value) => {
    setConfigProfile((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return { ...item, isActive: value };
        }
        return item;
      });
    });
  };

  const optionsList = UserProfessional;

  const newNotifications = async () => {
    try {
      const notifications = await getUserNotifications();
      setNotificationsSelecteds(notifications);
    } catch (error) {
      console.log(error);
    }
  };

  async function closeSession() {
    try {
      await logOut();
    } catch (error) {
      console.error("Error in closeSession(),", error);
    }
  }

  function goToTeleConsultation(code = "") {
    if (code) {
      setCodeTeleconsutation(code);
      setOptionActive(6);
    }
  }

  function goToMyMovements(code = "") {
    if (code) {
      setCodeMyMovements(code);
      setOptionActive(3);
    }
  }

  function goToMyNotifications(code = "") {
    if (code) {
      setCodeMyNotifications(code);
      setOptionActive(14);
    }
  }

  function goToProfileRoute(filterBy = "") {
    if (window.location.pathname !== "/profile") {
      navigate("/profile", { state: { codeMyNotifications: "notifications", filterBy } });
    }
  }
  
  return {
    userDetail,
    notifications,
    setNotifications,
    notificationsSelecteds,
    optionsList,
    optionActive,
    setOptionActive,
    selectOrDeselectNotification,
    isSelectedThisNotification,
    newNotifications,
    configProfile,
    switchOptionConfig,
    closeSession,
    goToTeleConsultation,
    codeTeleconsutation,
    goToMyMovements,
    codeMyMovements,
    goToMyNotifications,
    codeMyNotifications,
    getUserNotifications,
    markUserNotificationAsRead,
    goToProfileRoute, 
    setCodeMyNotifications,
    setOptionActive,
    unreadNotificationLength,
    setUnreadNotificationLength
  };
}

export default useUserProfile;
