import React from "react";
import styles from "./styles.module.css";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";

const ModalAction = ({ open, setOpen }) => {
  const handleCloseModal = (e) => {
    if (e.target.classList.contains(styles.containModal)) {
      setOpen(false);
    }
  };

  const { 
    setOptionActive, 
  } = useUserProfile();

  const navigate = useNavigate();

  const newSearch = () => {
    // delete data localStorage
    localStorage.removeItem('form-data-procedure-std');
    localStorage.removeItem('data-std');
  };

  const handleNewSearch = () => {
    newSearch();
    navigate('/profile?option=4');
  };

  return (
    <div
      className={`${styles.containModal} ${open ? styles.active : ""}`}
      onClick={handleCloseModal}
    >
      <div
        className={`${styles.contentModal} ${open ? styles.active : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={() => setOpen(false)}
          className={styles.contentModalButtonClose}
        >
          <IoClose size={20} />
        </button>

        <div className={styles.iconSuccess}>
          <FaCheck size={35} />
        </div>

        <div className={styles.contentText}>
          <h2>¡Paquete armado con éxito!</h2>
          <p>
            ¡Tu paquete ha sido armado con éxito! <br /> Nuestras asesoras se
            comunicarán contigo pronto.
          </p>
        </div>

        <button className={styles.linkCreatePackage} onClick={handleNewSearch}>
          Finalizar
        </button>
      </div>
    </div>
  );
};

export default ModalAction;
