import React, { useState, useEffect } from "react";
import useUserUpdate from "@/ui/viewModels/session/update.user.viewModel";
import style from "./styles.module.css";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FaSpinner } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import { LuCalendarCheck } from "react-icons/lu";
import DateInputWithPicker from "@/ui/views/BuildPackage/pages/std/components/SelectedDate/DateInputWithPicker";
import { capitalizeFirstLetterOfArray } from "@/Api/stringUtils";
import { genderList } from "@/Api/genderList";
import SearchInputNew from "@/ui/views/session/components/SearchableInput/SearchInputNew";
import { useTranslation } from "react-i18next";

const UserUpdate = ({ show }) => {
  const { formData, errors, responseStatus, loading, responseMessage, messageType, handleChange, handleBlur, handleSubmit, setFormData, validateField } = useUserUpdate();

  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [birthDate, setBirthDate] = useState("")
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);

  const {t, i18n} = useTranslation('global');

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleCurrentPasswordVisibility = () => setShowCurrentPassword(!showCurrentPassword);

  useEffect(() => {
    if (responseStatus.status === 200 || messageType) {
      setShowResponseMessage(true);
      const timer = setTimeout(() => {
        setShowResponseMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [responseStatus.status, messageType]);

  useEffect(() => {
    if (formData.birth_date) {
      const parsedDate = new Date(formData.birth_date);
      if (parsedDate instanceof Date && !isNaN(parsedDate)) {
        setSelectedBirthDate(parsedDate);
      } else {
        console.error("Invalid birth_date:", formData.birth_date);
      }
    }
  }, [formData.birth_date]);

  useEffect(() => {
    if (formData.phone_public && formData.phone_prefix) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        prex_and_phone_public: `${formData.phone_prefix}${formData.phone_public}`,
      }))
    }
  }, [formData.phone_public, formData.phone_prefix]);


  useEffect(() => {
    if (!show) {
      handleChange({ target: { name: 'password', value: '' } });
      handleChange({ target: { name: 'confirm_password', value: '' } });
      handleChange({ target: { name: 'current_password', value: '' } });
    }
  }, [show]);

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.prex_and_phone_public &&
      formData.phone_public &&
      formData.gender &&
      formData.current_password &&
      formData.password &&
      formData.confirm_password &&
      !errors.name &&
      !errors.email &&
      !errors.prex_phone_public &&
      !errors.phone_public &&
      !errors.current_password &&
      !errors.password &&
      !errors.confirm_password &&
      formData.password === formData.confirm_password
    );
  };

  return (
    <div className={style.formUpdateUser}>
      <h3>{t('profile.modules.my_profile.update_profile.title')}</h3>
      <form onSubmit={handleSubmit} className={style.flexRowContainItemForm}>
        {/* Campos del formulario aquí */}

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="name" className={style.label}>
            {t('profile.modules.my_profile.update_profile.full_name')}
          </label>
          <div className={`${style.input} ${errors.name ? style.error : ''}`}>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.name && <p className={style.messageInput}>{errors.name}</p>}
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="email" className={style.label}>
            {t('profile.modules.my_profile.update_profile.email')}
          </label>
          <div className={`${style.input} ${errors.email ? style.error : ''}`}>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.email && <p className={style.messageInput}>{errors.email}</p>}
        </div>

        <div className={style.inputsFlex}>
          <div className={style.flexRowContainItemInput}>
            <label htmlFor="prex_and_phone_public" className={style.label}>
              {t('profile.modules.my_profile.update_profile.prefix_and_phone')}
            </label>
            <PhoneInput
              country={"co"}
              value={formData.prex_and_phone_public}
              onChange={(value, data) => {
                setFormData((prevValues) => ({
                  ...prevValues,
                  prex_and_phone_public: value,
                  phone_prefix: data.dialCode
                }));

                validateField("prex_and_phone_public", value);
              }}
              inputStyle={errors.prex_and_phone_public ? { border: "1px solid #FF5151", width: "100%", borderRadius: "8px" } : {}}
              enableSearch={true}
            />

            {errors.prex_and_phone_public && <p className={style.messageInput}>{errors.prex_and_phone_public}</p>}
          </div>
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="birth_date" className={style.label}>
            {t('profile.modules.my_profile.update_profile.birth_date')}
          </label>
          <DateInputWithPicker
            inputId="birth_date"
            selectedDate={selectedBirthDate}
            setSelectedDate={(selectedBirthDate) => {
              setSelectedBirthDate(selectedBirthDate);
              setFormData((prevValues) => ({
                ...prevValues,
                birth_date: selectedBirthDate,
              }));
            }}
            setBirthDate={setBirthDate}
            isBirthday={true}
            placeholder="dd/mm/yyyy"
            icon={() => <LuCalendarCheck color="var(--blueLightTransparent)" size={17} />}
            hasButton={false}
            validationForm={!selectedBirthDate ? true : false} // Pasa el estado de error
          />

          {!selectedBirthDate && <p className={style.messageInput}>
             {t('profile.modules.my_profile.update_profile.errors.required_birth_date')}
            </p>}
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="gender" className={style.label}>
            {t('profile.modules.my_profile.update_profile.gender')}
          </label>

          <SearchInputNew
            name="gender"
            options={genderList.map((item) => t(`gender_list.${item}`))}
            value={
              i18n.language === "es" ? formData.gender : formData.gender === "Masculino" ? "Male" : formData.gender === "Femenino" ? "Female" : 
              formData.gender === "No especificado" ? "Unspecified" : formData.gender
            }
            onChange={handleChange}
            placeholder={i18n.language === "es" ? "Selecciona un género" : "Select a gender"}
            onBlur={handleBlur}
            type="text"
            id="gender"
            inputErrors={errors.birth_date}
          />


          {errors.gender && <p className={style.messageInput}>{errors.gender}</p>}
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="current_password" className={style.label}>
            {t('profile.modules.my_profile.update_profile.current_password')}
          </label>
          <div className={`${style.input} ${errors.current_password ? style.errorInput : ''}`}>
            <input
              type={showCurrentPassword ? "text" : "password"}
              id="current_password"
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button type="button" onClick={toggleCurrentPasswordVisibility} className={style.eyeIcon}>
              {showCurrentPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
            </button>
          </div>
          {errors.current_password && <p className={style.messageInput}>{errors.current_password}</p>}
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="password" className={style.label}>
            {t('profile.modules.my_profile.update_profile.new_password')}
          </label>
          <div className={`${style.input} ${errors.password ? style.errorInput : ''}`}>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button type="button" onClick={togglePasswordVisibility} className={style.eyeIcon}>
              {showPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
            </button>
          </div>
          {errors.password && <p className={style.messageInput}>{errors.password}</p>}
        </div>

        <div className={style.flexRowContainItemInput}>
          <label htmlFor="confirm_password" className={style.label}>
            {t('profile.modules.my_profile.update_profile.confirm_password')}
          </label>
          <div className={`${style.input} ${errors.confirm_password ? style.errorInput : ''}`}>
            <input
              type={showPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button type="button" onClick={togglePasswordVisibility} className={style.eyeIcon}>
              {showPassword ? <FaEyeSlash size={20} color="var(--bluePrimary)" /> : <FaEye size={20} color="var(--bluePrimary)" />}
            </button>
          </div>
          {errors.confirm_password && <p className={style.messageInput}>{errors.confirm_password}</p>}
        </div>

        {showResponseMessage && messageType === "success" && (
          <p className={`${style.responseSave} ${style[messageType]}`}>
            {responseMessage}
          </p>
        )}

        <button
          type="submit"
          className={style.submitButton}
          disabled={loading || !isFormValid()}
        >
          {
            loading ? <FaSpinner size={20} className={style.spinner} /> 
            : t('profile.modules.my_profile.update_profile.button_text') 
          }
        </button>
      </form>
    </div>
  );
};

export default UserUpdate;
