import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import styles from "./styles.module.css";

const SearchInputNew = ({
    name,
    type,
    id,
    options,
    inputErrors,
    value,
    onChange,
    placeholder,
    onBlur,
}) => {
    const [searchTerm, setSearchTerm] = useState(value || "");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setSearchTerm(value || "");
    }, [value]);

    useEffect(() => {
        setFilteredOptions(
            options.filter((option) =>
                option.toLowerCase().includes(searchTerm.toLowerCase()) // Conver to lowercase to handle case-insensitive search
            )
        );
    }, [searchTerm, options]);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (containerRef.current && !containerRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);
        return () => document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };

    const handleOptionClick = (option) => {
        setSearchTerm(`${option}`);
        setIsOpen(false);
        onChange({ target: { value: `${option}`, name: id } });
    };

    const handleBlur = (e) => {
        if (onBlur && typeof onBlur === "function") {
            onBlur(e);
        }
    };

    const handleFocus = () => {
        setIsOpen(true);
    };

    const handleClear = () => {
        setSearchTerm("");
        setIsOpen(true);
        inputRef.current.focus();
    };

    return (
        <div
            ref={containerRef}
            className={`${styles.searchInput} ${inputErrors ? styles.searchInputError : ""}`}
            style={{ padding: "10px" }}
        >
            <input
                autoComplete="off"
                ref={inputRef}
                name={name}
                type={type}
                id={id}
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={placeholder}
                onBlur={handleBlur}
                onFocus={handleFocus}
                className="text-sm"
            />
            {isOpen && (
                <div className={styles.dropdownMenu}>
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <div
                                key={index}
                                className={styles.dropdownOption}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))
                    ) : (
                        <div className={styles.dropdownOption}>No hay resultados</div>
                    )}
                </div>
            )}
            {searchTerm ? (
                <button type="button" onClick={handleClear} className={styles.clearButton}>
                    <IoClose />
                </button>
            ) : (
                <button type="button" onClick={() => setIsOpen(true)} className={styles.clearButton}>
                    <IoIosArrowDown />
                </button>
            )}
        </div>
    );
};

export default SearchInputNew;
