import UserContext from "@/Context/user/userProvider";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {
  
  const { userDetail } = useContext(UserContext);

  if (!userDetail) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
