import "./styles.css";

import play from "./play.png";
import { useTranslation } from "react-i18next";

function FormContactUs() {
  const { t } = useTranslation("global");
  const btns = {
    need: {
      title: t('pqrs.watch_videos'),
      textButton: t('home.need_information.button_text'),
      description: t('pqrs.wellezy_videos'),
      img: play,
    },
  };

  return (
    <div className="pqrsFormContactUs">
      <div className="mt-12">
        <iframe
          className=""
          width="560"
          height="315"
          src="https://www.youtube.com/embed/SspU4tSoRns?si=xkVco03qqwm-I9Cq"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>

        <div className="pqrdContactYoutube">
          <div className="pqrdContactYoutubeContent">
            <div className="pqrdContactYoutubeImageText">
              <img className="pqrdContactYoutubeImage" src={btns.need.img} alt="Imagen YouTube" />
              <div className="pqrdContactYoutubeText max-w-[280px]">
                <h3>{btns.need.title}</h3>
                <p className="">{btns.need.description}</p>
              </div>
            </div>

            <a className="pqrdContactYoutubeButton" href="https://www.youtube.com/@wellezy4453" target="_blank">
              {btns.need.textButton}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormContactUs;
