import { Grid } from '@mui/material';
import styles from "./index.module.css";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { PiRadioButtonFill } from 'react-icons/pi';


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('es-ES', options);
    const formattedTime = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
    return { formattedDate, formattedTime };
};

const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffMs = endDate - startDate;
    const diffHrs = Math.floor(diffMs / 3600000); // 1 hour = 3600000 milliseconds
    const diffMins = Math.round((diffMs % 3600000) / 60000); // 1 minute = 60000 milliseconds
    return `${diffHrs > 0 ? `${diffHrs} hora${diffHrs > 1 ? 's' : ''} ` : ''}${diffMins} min`;
};

const Flight = ({ dataPackage }) => {

    const { adult = 0, baby = 0, boy = 0, seat = 0 } = dataPackage?.flight?.passengersSelected || {};
    const itinerary = dataPackage?.flight?.itinerary || [];

    console.log("Passenger : ", dataPackage?.flight?.passengersSelected )

    const passengers = [];
    if (adult > 0) passengers.push(`${adult} adulto${adult > 1 ? 's' : ''}`);
    if (baby > 0) passengers.push(`${baby} bebé${baby > 1 ? 's' : ''}`);
    if (boy > 0) passengers.push(`${boy} niño${boy > 1 ? 's' : ''}`);
    if (seat > 0) passengers.push(`${seat} asiento${seat > 1 ? 's' : ''}`);

    const departure = itinerary[0] ? formatDate(itinerary[0]?.dateDeparture) : {};
    const arrival = itinerary[0] ? formatDate(itinerary[0]?.dateArrival) : {}; 

    const goDuration = itinerary[0] ? calculateDuration(itinerary[0]?.dateDeparture, itinerary[0]?.dateArrival) : ''; 
    const returnDuration = itinerary[1] ? calculateDuration(itinerary[1]?.dateDeparture, itinerary[1]?.dateArrival) : '';
  


    return (
        <div className={styles.mainFlightsDiv} >
            <Grid container spacing={2} className={styles.topFlightsDiv}>
                <Grid item xs={12} md={2.5} className={styles.subContainer1lightsDiv}>
                    <h4>Detalle de compra</h4>
                </Grid>
                <Grid item xs={12} md={9.5} className={styles.subContainer2lightsDiv}>
                    <div>
                        {itinerary?.length === 1 ? 'Solo ida' : 'Ida y vuelta'}
                    </div>

                    <div>
                        {passengers?.join(', ')}
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={1} className={styles.additionalGrid}
                sx={{
                    borderBottom: itinerary?.length === 2 ? '1px solid #f2f1f3' : 'none',
                    marginBottom: itinerary?.length === 2 ? '1.5rem' : '0',
                    paddingBottom: itinerary?.length === 2 ? '1.5rem' : '0',
                    mt:'1rem'
                }}
            >
                <Grid item xs={12} md={3} className={styles.subContainer3}>
                    <span>
                        <SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane} size={55} />
                    </span>
                    <div>
                        <p>Origen</p>
                        <h2>{itinerary[0]?.from}</h2>
                        <h4>{departure?.formattedDate}</h4>
                        <h4>{departure?.formattedTime}</h4>
                    </div>
                </Grid>

                <Grid item xs={12} md={6} className={styles.subContainer4}>
                    <div className={styles.lineFlight}>
                        <div>
                            <p>1</p>
                        </div>
                    </div>

                    <div className={styles.itineraryLineFlight}>
                        <PiRadioButtonFill />
                        <h2>Ida</h2>
                        <h3>
                            {itinerary[0]?.scale && itinerary[0].scale?.length > 0 ? `${itinerary[0]?.scale?.length} escala${itinerary[0]?.scale?.length > 1 ? 's' : ''}` : 'Directo'}
                        </h3>
                        <p>{goDuration}</p>
                    </div>

                    <div className={styles.lineFlight}>
                        <div>
                            <p>2</p>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={3} className={styles.subContainer3}>
                    <span>
                        <SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane} size={55} />
                    </span>
                    <div>
                        <p>Destino</p>
                        <h2>{itinerary[0]?.to}</h2>
                        <h4>{arrival?.formattedDate}</h4>
                        <h4>{arrival?.formattedTime}</h4>
                    </div>
                </Grid>
            </Grid>

            {itinerary.length === 2 && (
                <Grid container spacing={1} className={styles.additionalGrid}>
                    <Grid item xs={12} md={3} className={styles.subContainer3}>
                        <span>
                            <SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane} size={55} />
                        </span>
                        <div>
                            <p>Origen</p>
                            <h2>{itinerary[1]?.from}</h2>
                            <h4>{formatDate(itinerary[1].dateDeparture)?.formattedDate}</h4>
                            <h4>{formatDate(itinerary[1].dateDeparture)?.formattedTime}</h4>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6} className={styles.subContainer4}>
                        <div className={styles.lineFlight}>
                            <div>
                                <p>1</p>
                            </div>
                        </div>

                        <div className={styles.itineraryLineFlight}>
                            <PiRadioButtonFill />
                            <h2>Vuelta</h2>
                            <h3>
                                {itinerary[1]?.scale && itinerary[1].scale?.length > 1 ? `${itinerary[1]?.scale?.length} escala${itinerary[1]?.scale?.length > 1 ? 's' : ''}` : 'Directo'}
                            </h3>
                            <p>{returnDuration}</p>
                        </div>

                        <div className={styles.lineFlight}>
                            <div>
                                <p>2</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={3} className={styles.subContainer3}>
                        <span>
                            <SvgIcon color={"var(--bluePrimary)"} name={iconList.IconPlane} size={55} />
                        </span>
                        <div>
                            <p>Destino</p>
                            <h2>{itinerary[1]?.to}</h2>
                            <h4>{formatDate(itinerary[1]?.dateArrival)?.formattedDate}</h4>
                            <h4>{formatDate(itinerary[1]?.dateArrival)?.formattedTime}</h4>
                        </div>
                    </Grid>
                </Grid>
            )}

        </div>
    );
};

export default Flight;