import { useMeeting, useParticipant } from '@videosdk.live/react-sdk';
import React, { useMemo } from 'react'
import { MdFullscreen } from 'react-icons/md';
import ReactPlayer from 'react-player';



const PresenterView = ({ id }) => {

    const { presenterId } = useMeeting(id)


    const { screenShareStream, screenShareOn } = useParticipant(presenterId);

    //Creating a media stream from the screen share stream
    const mediaStream = useMemo(() => {
        if (screenShareOn && screenShareStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);


    return (
        <>

            <div
                style={{
                    position: "absolute",
                    width: "94%",
                    top: "0",
                    left:"0",
                   height:"-webkit-fill-available"
                }}
            >
                <ReactPlayer
                    //
                    playsinline // extremely crucial prop
                    playIcon={<></>}
                    //
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    //
                    width={"100%"}
                    height={"100%"}
                    url={mediaStream} // passing mediastream here
                    //
                    onError={(err) => {
                        console.log(err, "presenter video error");
                    }}
                />
                
            </div>



        </>
    );
}

export default PresenterView