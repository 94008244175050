import React, { useState } from "react";
import style from "./styles.module.css";
import { MdCameraAlt } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";

const CardTour = ({ data, detailTour, width = "" }) => {
  const { image, name, fare, start_time, end_time, id } = data;

  const location = useLocation();
  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);

  const isTour = location.pathname.includes("/tours");

  const handleClick = () => {
    if (isTour) {
      navigate(`/tour/${id}`);
    } else {
      navigate(`/packages/${id}`);
    }
  };

  const formatearFecha = (fecha) => {
    const fechaObj = new Date(fecha);
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    const dia = fechaObj.getDate();
    const mes = meses[fechaObj.getMonth()];
    return `${dia} de ${mes}`;
  };

  const recortarTexto = (cantidadCaracteres, texto) =>
    texto.length <= cantidadCaracteres
      ? texto
      : texto.slice(0, cantidadCaracteres) + "...";

  return (
    <div className="card-packages-new" style={{ width: width ? width : "" }}>
      {!imageLoaded && (
        <div className="img-transport-skeleton">
          <MdCameraAlt size={40} color="#c8c8c8" />
        </div>
      )}
      <img
        src={`${process.env.REACT_APP_URL_API_AIOP}storage/tour/${image}`}
        alt={name}
        onLoad={() => setImageLoaded(true)}
        style={{ display: imageLoaded ? "block" : "none" }}
      />
      <div className="description">
        <h2>{recortarTexto(22, name)}</h2> 
        <p> 
          Paquete disponible hasta el {end_time} 
        </p>
      </div>
      <div className="price">
        <span className="back-price">{fare}</span>
        <span className="see-more" onClick={handleClick}>
          Ver más
        </span>
      </div>
    </div>
  );
};

export default CardTour;
