import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import styles from "./styles.module.css";
import PresenterView from "../presenterView/PresenterView";

function ParticipantView({ participantId, setIsAnyExpanded }) {
  const micRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    webcamStream,
    micStream,
    webcamOn,
    micOn,
    isLocal,
    displayName,
    screenShareStream,
    screenShareOn,
  } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream([webcamStream.track]);
      return mediaStream;
    }
    return null;
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current && micOn && micStream) {
      const mediaStream = new MediaStream([micStream.track]);
      micRef.current.srcObject = mediaStream;
      micRef.current.play().catch((error) =>
        console.error("Error playing mic stream:", error)
      );
    } else if (micRef.current) {
      micRef.current.srcObject = null;
    }
  }, [micStream, micOn]);

  const renderInitial = displayName?.charAt(0) || "";

  const handleIsExpanded = () => {
    setIsExpanded(!isExpanded);
    setIsAnyExpanded(!isExpanded);
  };

  return (
    <div
      className={`flex flex-col ${
        isExpanded ? "absolute" : styles.participant
      }`}
    >
      <audio ref={micRef} autoPlay playsInline muted={isLocal} />

      {screenShareOn && (
        <div
          className={`relative ${
            isExpanded ? "sm:w-[100dvw] sm:h-[100dvh] md:w-[80dvw] md:h-[80dvh] lg:w-[80dvw] lg:h-[80dvh] xl:w-[80dvw] xl:h-[80dvh] max-h-screen max-w-screen z-10" : "w-[95%] h-[25dvh] z-1"
          }`}
        >
          <ReactPlayer
            playsinline
            width="100%"
            height="100%"
            muted={isLocal}
            playing
            url={
              screenShareStream && new MediaStream([screenShareStream.track])
            }
            onError={(err) => console.log("Video player error:", err)}
          />
          <button
            onClick={handleIsExpanded}
            className={`absolute right-0 bg-blue-500 text-white px-4 py-2 rounded ${
              isExpanded ? "w-full z-10 top-[-35px]" : "z-1 opacity-70 hover:opacity-100 top-2"
            }`}
          >
            {isExpanded ? "Close" : "Expand"}
          </button>
        </div>
      )}
      
      {webcamOn && !isExpanded ? (
        <ReactPlayer
          playsinline
          width={null}
          height={null}
          muted={isLocal}
          playing
          url={videoStream}
          onError={(err) => console.log("Video player error:", err)}
        />

      ) : !webcamOn && !isExpanded && (
        <div className={styles.participantAvatar}>
          <p className={styles.participantAvatarText}>{renderInitial}</p>
        </div>
      )}


      {!isExpanded && (
        <div className={styles.participantInfo}>
          <p>{displayName}</p>
        </div>
      )}
    </div>
  );
}

export default ParticipantView;
