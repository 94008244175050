import { useMeeting, useParticipant } from "@videosdk.live/react-sdk";
import styles from "./styles.module.css";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa6";
import { BsCameraVideoFill, BsCameraVideoOffFill } from "react-icons/bs";
import { MdScreenShare, MdOutlineStopScreenShare } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Controls({ closeMeeting, setCloseMeeting, onLeave, setCode, participantId  }) {
  const { leave, toggleMic, toggleWebcam, toggleScreenShare } = useMeeting();

  const { micOn: micOnSDK, webcamOn: webcamOnSDK, screenShareOn } = useParticipant(participantId);
const navigate = useNavigate()
  // Estados locales para manejar el estado de los íconos
  const [micOn, setMicOn] = useState(micOnSDK);
  const [webcamOn, setWebcamOn] = useState(webcamOnSDK);
  const [screenShareOnS, setScreenShareOnS] = useState(screenShareOn)

  const permissionStates = {
    granted: { value: "granted", showModal: false },
    denied: { value: "denied", showModal: true },
    prompt: { value: "prompt", showModal: true },
    unprompted: { value: "unprompted", showModal: true },
    dismissed: { value: "dismissed", showModal: true },
    inactive: { value: "inactive", showModal: true },
  };

  const permissionsList = {
    microphone: "microphone",
    camera: "camera",
  };

  const requestAccess = async (data) => {
    try {
      await navigator.mediaDevices.getUserMedia({ ...data });
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  const checkPermission = async (permissionName) => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: permissionName,
      });
      if (permissionStatus) {
        const currentPermission = permissionStates[permissionStatus.state];
        return currentPermission.showModal;
      }
    } catch (error) {
      console.error("Error al verificar el estado del permiso:", error);
    }
    return false;
  };

  // Actualiza el estado local cuando el SDK cambia
  const controlMic = async () => {
    toggleMic(); // Cambia el estado del micrófono
    setMicOn((prev) => !prev); // Invierte el estado local
  };

  const controlCam = async () => {
    toggleWebcam(); // Cambia el estado de la cámara
    setWebcamOn((prev) => !prev); // Invierte el estado local
  };

  const controlScreen = async () => {
    toggleScreenShare(); // Cambia el estado de la pantalla
    setScreenShareOnS((prev) => !prev); // Invierte el estado local
  };

  return (
    <div className={styles.controlsContainer}>
      <div onClick={controlMic} className={styles.controlBtnMicro}>
        {micOn ? <FaMicrophone size={19} /> : <FaMicrophoneSlash />}
      </div>

      <div onClick={controlCam} className={styles.controlBtnWebCam}>
        {webcamOn ? <BsCameraVideoFill /> : <BsCameraVideoOffFill />}
      </div>
      <div onClick={controlScreen} className={styles.controlBtnWebCam}>
        {screenShareOnS ? <MdScreenShare /> : <MdOutlineStopScreenShare />}
      </div>
      <div
        onClick={() => {
          leave();
          onLeave();
          navigate("/")
          // setCode("");
        }}
        className={styles.controlBtnExit}
      >
        <div className={styles.controlIconExit}>
          <FaPhoneAlt />
        </div>
      </div>
    </div>
  );
}

export default Controls;
