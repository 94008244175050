import { authToken } from "@/infra/constants/tokens.video.sdk";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import { createContext, useContext, useState } from "react";
import UserContext from "../user/userProvider";

export const CallContext = createContext();

export const CallProvider = ({ children }) => {
  const [isCallActive, setIsCallActive] = useState(false);
  const { userDetail } = useContext(UserContext);
  const [meetingId, setMeetingId] = useState("")
  const [participants, setParticipants] = useState([])
  const [presenterId, setPresenterId] = useState()
  const [callData, setCallData] = useState({
    status: "DISCONNECTED", // 'DISCONNECTED', 'JOINING', 'JOINED'
    closeMeeting: false,
  });

   // Función para cambiar el estado de 'closeMeeting'
   const setCloseMeeting = (value) => {
    setCallData((prevData) => ({
      ...prevData,
      closeMeeting: value,
    }));
  };


  

  return (
    <CallContext.Provider
      value={{
        isCallActive,
        setIsCallActive,
        setMeetingId,
        callData,
        setCallData,
        setCloseMeeting,
        participants, 
        setParticipants,
        setPresenterId,
        presenterId
      }}
    >
      <MeetingProvider
        config={{
          meetingId,
          micEnabled: false,
          webcamEnabled: false,
          name: userDetail?.name,
        }}
        token={authToken}
      >
        {children}
      </MeetingProvider>

    </CallContext.Provider>
  );
};