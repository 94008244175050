// ClinicInfo.jsx
import React from 'react';
import styles from "../index.module.css";

const ClinicInfo = ({ dataClinic }) => { 
    return (
        <div className={styles.mainClinicDivPackage} >
            <div className={styles.topClinicDivPackage}>
                <h3>{dataClinic?.clinic_name}</h3>
                <h4>{dataClinic?.clinic_address}</h4>
            </div>
            <div className={styles.descClinicPackage}>
                <p>{dataClinic?.clinic_description}</p>
            </div>
        </div>
    );
};

export default ClinicInfo; 