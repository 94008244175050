import React, { useState, useRef, useEffect } from "react";
import { formatToDDMMYYYY } from "@/common/services/dateUtils";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import * as locales from "react-date-range/dist/locale";

const DateInputWithPicker = ({
  inputId,
  selectedDate,
  setSelectedDate,
  isBirthday = false,
  setBirthDate,
  setOperationDate,
  minDate = isBirthday ? new Date(1900, 0, 1) : new Date(new Date().setDate(new Date().getDate() + 1)),
  maxDate = isBirthday ? new Date() : new Date(2100, 11, 31),
  placeholder = "dd/mm/yyyy",
  icon: Icon,
  hasButton = false,
  buttonText = "Seleccionar fecha",
  validationForm,
}) => {
  const [t, i18n] = useTranslation("global");
  const [showCalendar, setShowCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      setInputValue(formatToDDMMYYYY(selectedDate));
    }
  }, [selectedDate]);

  const setInitialDate = () => {
    const defaultDate = selectedDate instanceof Date && !isNaN(selectedDate)
      ? selectedDate
      : isBirthday
      ? new Date()
      : new Date(new Date().setDate(new Date().getDate() + 1));

    setSelectedDate(defaultDate);
    setInputValue(formatToDDMMYYYY(defaultDate));
  };

  const handleDateChange = (date) => {
    if (!date) return;

    setSelectedDate(date);
    const formattedDate = formatToDDMMYYYY(date);
    setInputValue(formattedDate);

    if (setBirthDate) {
      setBirthDate(formattedDate);
    }
    if (setOperationDate) {
      setOperationDate(formattedDate);
    }

    if (!hasButton) {
      setShowCalendar(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9/]/g, ""); // Filter only numbers and slabs
    const cursorPosition = inputRef.current.selectionStart;
  
    // Divide the input into segments
    const segments = value.split("/");
    let day = segments[0] || "";// Ensure that the segments are empty chains if they are undefined
    let month = segments[1] || "";
    let year = segments[2] || "";
  
    // Build the new value ensuring that the segments are not run
    const formattedValue = `${day.padEnd(2, " ")}${day ? "/" : ""}${month.padEnd(2, " ")}${month ? "/" : ""}${year.padEnd(4, " ")}`;
  
    setInputValue(formattedValue.trimEnd()); // Remove extra spaces for presentation
  
    // Automatically adjust to the corresponding segment
    let nextCursorPosition = cursorPosition;
    if (cursorPosition === 2 || cursorPosition === 5) {
      nextCursorPosition += 1;
    }
  
    setTimeout(() => {
      inputRef.current.setSelectionRange(nextCursorPosition, nextCursorPosition);
    }, 0);
  
    // Validate if the date is complete
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(formattedValue.trimEnd())) {
      const parsedDate = parseDate(formattedValue);
      if (parsedDate && parsedDate >= minDate && parsedDate <= maxDate) {
        setSelectedDate(parsedDate);
        const formattedDate = formatToDDMMYYYY(parsedDate);
        if (setBirthDate) setBirthDate(formattedDate);
        if (setOperationDate) setOperationDate(formattedDate);
      }
    }
  };  

  const handleKeyDown = (e) => {
    const input = inputRef.current;
    const cursorPosition = input.selectionStart;
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    const isNumberKey = /^[0-9]$/.test(e.key);

    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
      if (!selectedDate) {
        setInitialDate();
      } else {
        adjustDateWithArrows(e.key === "ArrowUp" ? 1 : -1);
      }
    }

    if (!isNumberKey && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    if (e.key === "Backspace") {
      e.preventDefault();
  
      let segments = inputValue.split("/");
      let [day, month, year] = segments;
  
      // Delete a specific segment depending on the position of the cursor
      if (cursorPosition <= 2) {
        day = day.slice(0, -1); // Delete from the day segment
      } else if (cursorPosition > 2 && cursorPosition <= 5) {
        month = month.slice(0, -1); // Delete from the month segment
      } else if (cursorPosition > 5) {
        year = year.slice(0, -1); // Delete from the year segment
      }
  
      const newValue = `${day || ""}${day || (month && year)  ? "/" : ""}${month || ""}${month || (day && year) ? "/" : ""}${year || ""}`;
      setInputValue(newValue);
      
  
      // Adjust cursor to the correct segment
      setTimeout(() => {
        input.setSelectionRange(Math.max(0, cursorPosition - 1), Math.max(0, cursorPosition - 1));
      }, 0);
    }
  };

  const handleFocus = () => {
    if (!selectedDate) {
      setInitialDate();
    }
    setShowCalendar(true);
  };

  const handleBlur = () => {
    if (!inputValue.trim()) {
      setInitialDate();
      return;
    }

    const parsedDate = parseDate(inputValue);
    if (!parsedDate || parsedDate < minDate || parsedDate > maxDate) {
      setInitialDate();
    }
  };

  const adjustDateWithArrows = (increment) => {
    const input = inputRef.current;
    const cursorPosition = input.selectionStart;
    const segments = inputValue.split("/");
    const [day, month, year] = segments.map((v) => parseInt(v, 10));

    let newDay = day || 1;
    let newMonth = month || 1;
    let newYear = year || new Date().getFullYear();

    // Adjust according to the cursor position
    if (cursorPosition <= 2) {
        newDay += increment;
    } else if (cursorPosition > 2 && cursorPosition <= 5) {
        newMonth += increment;
    } else if (cursorPosition > 5) {
        newYear += increment;
    }

    const updatedDate = new Date(newYear, newMonth - 1, newDay);

    // Validate that the new date is within the range
    if (updatedDate >= minDate && updatedDate <= maxDate) {
        const newValue = formatToDDMMYYYY(updatedDate);

        const selectionStart = input.selectionStart;
        const selectionEnd = input.selectionEnd;

        // Update values
        setInputValue(newValue);
        setSelectedDate(updatedDate);

        // Synchronize with specific functions if they are defined
        if (setBirthDate) {
            setBirthDate(newValue);
        }

        if (setOperationDate) {
            setOperationDate(newValue);
        }

        // Restore cursor position
        setTimeout(() => {
            input.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    } else {
        // SPECIAL CASE: if you try to reduce beyond the limit, correct to the minimum or maximum limit
        if (updatedDate < minDate) {
            const minValue = formatToDDMMYYYY(minDate);
            setInputValue(minValue);
            setSelectedDate(minDate);

            if (setOperationDate) {
                setOperationDate(minValue);
            }
        }
        if (updatedDate > maxDate) {
            const maxValue = formatToDDMMYYYY(maxDate);
            setInputValue(maxValue);
            setSelectedDate(maxDate);
        }
    }
  };

  const parseDate = (value) => {
    const [day, month, year] = value.split("/").map((v) => parseInt(v, 10));
    if (
      !isNaN(day) &&
      !isNaN(month) &&
      !isNaN(year) &&
      day > 0 &&
      day <= 31 &&
      month > 0 &&
      month <= 12 &&
      year >= 1900 &&
      year <= 2100
    ) {
      return new Date(year, month - 1, day);
    }
    return null;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dateInputWithPicker} ref={wrapperRef}>
      <div className={styles.inputWrapper}>
        <input
          autoComplete="off"
          style={{ fontSize: "smaller" }}
          type="text"
          id={inputId}
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`${styles.dateInput} ${!selectedDate && validationForm ? styles.errorInput : ""}`}
        />
        {Icon && (
          <span className={styles.icon} onClick={() => setShowCalendar((prev) => !prev)}>
            <Icon />
          </span>
        )}
      </div>
      {showCalendar && (
        <div className={styles.calendarContainer}>
          <Calendar
            locale={i18n.language === "en" ? locales['enUS'] : locales['es']}
            date={selectedDate}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            className="custom-calendar"
          />
          {hasButton && (
            <div className={styles.containButtonSelectedDate}>
              <button onClick={() => setShowCalendar(false)}>{buttonText}</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DateInputWithPicker;