import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import img1 from "../../../../assets/svg/packages1.png";
import img2 from "../../../../assets/svg/BannerBottom.jpg";


import CardGlobal from "../../../share/components/cardGlobal";
import styled from "styled-components";
import Loading from "../../../share/components/loading/index";
import "./styles.css";

import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, Pagination } from "@mui/material";
import CardPackages from "./CardPackages";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { useNavigate } from "react-router-dom";

const MotionConstraints = styled(motion.div)`
  overflow-x: hidden;
`;
const MotionBox = styled(motion.div)`
  width: 400vw;
`;

const PackageSection = ({
  tour,
  inputValue,
  selectValue,
  seker,
  loading,
  dataPackage,
}) => {
  if (!inputValue) {
    inputValue = "";
  }

  //Variables de estado

  const [inputValueSection, setInputValueSection] = useState(inputValue);
  const [selectValueSection, setSelectValueSection] = useState(selectValue);

  //Variables de paginación
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataPackage.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil((dataPackage || []).length / itemsPerPage);
  //Traducción
  const [t] = useTranslation("global");

  //Función para cambiar de página
  const handlePageChange = (event, value) => {
    window.location.href = "#title";
    setCurrentPage(value);
  };

  //Efecto para buscar servicios
  // useEffect(() => {
  //     findServices();
  // }, [inputValueSection, selectValueSection])

  // useEffect(() => {
  //     setInputValueSection(inputValue)
  //     setSelectValueSection(selectValue)
  // }, [inputValue, selectValue])

  const constraintsRef = useRef(null);

  const nextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
    window.location.href = "#pack"
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
    window.location.href = "#pack"
  };


  // useEffect(() => {
  //     if()

  // }, [currentPage])

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/TourismHealth');
  };

  return (
    <div id="pack" className="packages-section" style={{ width: "90%" }}>
      <h2 id="title" className="title-packages-section">
        {tour ? "Tours" : t("packages.packages_home.packages")}
      </h2>
      <p className="p-pack">{t("packages.packages_home.best_price")}</p>
      <div>
        <div>
          <div>
            {seker ? (
              <div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    Lugar
                  </label>
                  <input
                    className="input-filter-packages"
                    type="text"
                    onChange={(event) =>
                      setInputValueSection(event.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    Tipo de destino
                  </label>
                  <select
                    className="select-filter-packages"
                    name=""
                    id=""
                    onChange={(event) =>
                      setSelectValueSection(event.target.value)
                    }
                  >
                    <option value="">Todas</option>
                    <option value="international">Internacional</option>
                    <option value="national">Nacional</option>
                    <option value="regional">Regional</option>
                  </select>
                </div>
              </div>
            ) : null}
          </div>
          <div className="layout-packages">
            {loading ? (
              <Loading />
            ) : currentItems && currentItems.length === 0 ? (
              <h1>No hay paquetes que coincidan con tu busqueda</h1>
            ) : currentItems && currentItems.length > 0 ? (
              <div className="content-packs-new" style={{ marginTop: "10px" }}>
                {currentItems.map((pack, index) => (
                  <CardPackages key={index} pack={pack} imgType={false} />
                ))}
                {/* <CardGlobal id={pack.id} imgType={false} descripcion={pack.description} img={pack.image} price={pack.price} stars={pack.stars} title={pack.name} address={pack.address} currency={pack.currency} /> */}
              </div>
            ) : (
              <h1>No hay paquetes que coincidan con tu búsqueda</h1>
            )}
            {currentItems &&
              currentItems.length === 0 ? null : dataPackage.length > 8 ? (
                <>
                  {/* <Pagination
                    className="pagination-packages"
                    count={Math.ceil(dataPackage.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  /> */}
                  <Paginado
                    currentPage={currentPage}
                    data={dataPackage}
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    pageRangeDisplayed={5}
                  />
                </>

              ) : (
              ""
            )}
          </div>
          <div className="banner-bottom-package" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img src={img2} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSection;
