import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function usePqrsPrimaryScreen() {
  const {t} = useTranslation("global");
  const [activatedTab, setActivatedTab] = useState(0);
  const [isEstablishedSession, setIsEstablishedSession] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);

  const dataPqrs = [
    {
      id: "person",
      name: t("pqrs.tabs.tourism"),
      isActive: true,
      videos: [
        { name: "video 1", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        {
          name: "video videovideo f video 3",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        { name: "video 4", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 5", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 6", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        { name: "video 7", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 8", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 9", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        {
          name: "video 10",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 11",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 12",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        {
          name: "video 13",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 14",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 15",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
      ],
      faq: {
        tourism: [
          {
            title: "first",
            content: "first",
          },
          {
            title: "second",
            content: "second"
          },
          {
            title: "third",
            content: "third",
          },
          {
            title: "fourth",
            content: "fourth",
          },
          {
            title: "fifth",
            content: "fifth",
          },
          {
            title: "sixth",
            content: "sixth",
          },
          {
            title: "seventh",
            content: "seventh",
          },
          {
            title: "eighth",
            content: "eighth",
          },
          {
            title: "ninth",
            content: "ninth",
          },
          {
            title: "tenth",
            content: "tenth",
          }
        ],
      },
    },

    {
      id: "tourisnMedical",
      name: t("pqrs.tabs.health"),
      isActive: true,
      videos: [
        { name: "video 1", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        {
          name: "video videovideo f video 3",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        { name: "video 4", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 5", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 6", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        { name: "video 7", url: "https://www.youtube.com/watch?v=DbLUdJcv0uk" },
        { name: "video 8", url: "https://www.youtube.com/watch?v=NNu7jyCwnD8" },
        { name: "video 9", url: "https://www.youtube.com/watch?v=Vua0Skln52A" },
        {
          name: "video 10",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 11",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 12",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
        {
          name: "video 13",
          url: "https://www.youtube.com/watch?v=DbLUdJcv0uk",
        },
        {
          name: "video 14",
          url: "https://www.youtube.com/watch?v=NNu7jyCwnD8",
        },
        {
          name: "video 15",
          url: "https://www.youtube.com/watch?v=Vua0Skln52A",
        },
      ],
      faq: {
        health: [
          {
            title: "first",
            content: "first",
          },
          {
            title: "second",
            content: "second"
          },
          {
            title: "third",
            content: "third",
          },
          {
            title: "fourth",
            content: "fourth",
          },
          {
            title: "fifth",
            content: "fifth",
          },
          {
            title: "sixth",
            content: "sixth",
          },
          {
            title: "seventh",
            content: "seventh",
          },
          {
            title: "eighth",
            content: "eighth",
          },
          {
            title: "ninth",
            content: "ninth",
          },
          {
            title: "tenth",
            content: "tenth",
          }
        ],
      },
    },
  ];

  useEffect(() => {
    if (dataPqrs && dataPqrs.length > 0) {
      for (const i in dataPqrs) {
        if (dataPqrs[i]?.isActive) {
          setActivatedTab(dataPqrs[i].id);
          break;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (activatedTab) {
      const pqrsSelected = dataPqrs.find((obj) => obj.id === activatedTab);
      if (pqrsSelected) setDataSelected({ ...pqrsSelected });
    }
  }, [activatedTab]);

  return {
    activatedTab,
    setActivatedTab,
    dataPqrs,
    isEstablishedSession,
    dataSelected,
  };
}

export default usePqrsPrimaryScreen;