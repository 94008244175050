// FormInfo.jsx
import React from 'react';
import styles from "../index.module.css";
import AllCampsForms from '../../../../../../../../src/ui/views/BuildPackage/pages/std/AllCampsForm';
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel';
import { formatYYYYMMDDToDDMMYYYY } from '@/common/services/dateUtils';
import { Grid } from '@mui/material';

const FormInfo = ({ dataForm }) => {

    const { cirugiasConfig } = AllCampsForms();   

    return (
        <div className={styles.mainClinicDivPackage} >
            <div className={styles.topClinicDivPackage}>
                <h3>Sistema Triaje y Diagnostico</h3>
            </div>

            {Object.keys(dataForm).map((procedureKey) => (
                <Grid container spacing={1} className={styles.procedureInfo} key={procedureKey}>
                    <Grid item xs={12} md={6} className={styles.aditionalInfoStd} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <h4>{procedureKey?.charAt(0)?.toUpperCase() + procedureKey.slice(1)}</h4>
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            dataForm[procedureKey][field.key] !== undefined && (
                                <div key={field.key} className={styles.divsProcedure}>
                                    <p>{field.label}</p>
                                    <span>
                                        {field.type === 'checkbox' ?
                                            (dataForm[procedureKey][field.key] ? 'Sí' : 'No') :
                                            dataForm[procedureKey][field.key]
                                        }
                                    </span>
                                </div>
                            )
                        ))}
                    </Grid>

                    <Grid item xs={12} md={6} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            field.key === 'biggest_concern_doubt' && (
                                <Grid item xs={12} md={12} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }} key={field.key}>
                                    <h4>{field.label}</h4>
                                    {field.options.map((option) => (
                                        dataForm[procedureKey][option.key] && (
                                            <div key={option.key} className={styles.divsProcedure}>
                                                <p>{option.label}</p>
                                            </div>
                                        )
                                    ))}
                                </Grid>
                            )
                        ))}
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            field.key === 'hope_to_achieve' && (
                                <Grid item xs={12} md={12} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }} key={field.key}>
                                    <h4>{field.label}</h4>
                                    {field.options.map((option) => (
                                        dataForm[procedureKey][option.key] && (
                                            <div key={option.key} className={styles.divsProcedure}>
                                                <p>{option.label}</p>
                                            </div>
                                        )
                                    ))}
                                </Grid>
                            )
                        ))}
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

export default FormInfo; 