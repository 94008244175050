import React, { useEffect, useState } from "react";
import { formatMoney } from "../../../../utils";
import { MdCameraAlt } from "react-icons/md";

import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { Tooltip } from "@mui/material";


const SliderCard = ({
  carousel = false,
  img,
  title,  
  dueDate,
  colorButton = "",
  price,
  typeCurrency,
  boxShadow = false, 
  imgOption = false,
  typePack,
  handleClickPack,
  item,
  loadingImage,
  setLoadingImage
}) => {

  function formatearFecha(fecha) {
    let fechaObj = new Date(fecha);
    let meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    let dia = fechaObj.getDate();
    let mes = meses[fechaObj.getMonth()];
    return `${dia} de ${mes}`;
  }

  function textCutter(cantidadCaracteres, texto) {
    if (texto.length <= cantidadCaracteres) {
      return texto;
    }
    return texto.slice(0, cantidadCaracteres) + "...";
  }



  const onLoad = () => {
    setLoadingImage(false);
  };
  const onError = () => {
    setLoadingImage(true);
  };

  useEffect(() => {
    setLoadingImage(false)
  }, [typePack]);

  const formatDateRange = (start, end) => {
    const startParsed = parseISO(start);
    const endParsed = parseISO(end);

    const startMonth = format(startParsed, 'MMMM', { locale: es });
    const endMonth = format(endParsed, 'MMMM', { locale: es });

    const startDay = format(startParsed, 'd', { locale: es });
    const endDay = format(endParsed, 'd', { locale: es });

    const startYear = format(startParsed, 'yyyy', { locale: es });
    const endYear = format(endParsed, 'yyyy', { locale: es });

    if (startMonth === endMonth && startYear === endYear) {
      return `Paquete disponible del ${startDay} al ${endDay} de ${startMonth} de ${startYear}`;
    } else {
      return `Paquete disponible del ${startDay} de ${startMonth} al ${endDay} de ${endMonth} de ${endYear}`;
    }
  };


  return (
    <div
      className="slider-card"
      style={{
        boxShadow: boxShadow ? "0px 4px 4px 0px #00000040" : "",
        maxWidth: carousel ? "90%" : "none",
        margin: "4px"
      }}
    >
      {loadingImage ? (
        <div className="skeleton-home-img">
          <div className="img-home-skeleton">
            <MdCameraAlt size={40} color="#c8c8c8" />
          </div>
        </div>
      ) : (
        <img
          onLoad={onLoad}
          onError={onError}
          src={
            typePack
              ? `${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${img}`
              : `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${img}`
          }
          alt={img}
          style={{
            minWidth: carousel ? "auto" : "",
            maxHeight: carousel ? "none" : "",
            height: imgOption ? "300px" : "",
          }}
        />
      )}

      <div className="ofertsTurismSaludCardContent">
        <> 
          <div className="ofertsTurismSaludCardText">
            <Tooltip title={title} placement="bottom" >
              <h3
                className="ofertsTurismSaludCardTitle"
                style={{ color: colorButton }}
              >
                {textCutter(21, title)}
              </h3>
            </Tooltip>
            <p> 
              <p>
                Paquete disponible hasta el {dueDate}
              </p>
            </p>
          </div> 
          <div className="ofertsTurismSaludCardButtons">
            <button
              className="ofertsTurismSaludCardButtonn"
              style={{
                backgroundColor: colorButton,
                transition: "all 0.5 ease",
              }}
            >
              {formatMoney(price) + " " + typeCurrency}
            </button>
            <button
              style={{
                color: colorButton,
                transition: "all 0.6 ease",
                fontSize: "12px",
                fontWeight: "700",
                cursor: 'pointer'
              }}
              onClick={() => handleClickPack(item, item?.id)}
              className="ofertsLink"
            >
              Ver más
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default SliderCard;
