import { Grid } from '@mui/material';
import styles from "./index.module.css";
import AllCampsForms from '../../../../../../../src/ui/views/BuildPackage/pages/std/AllCampsForm';
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel';
import { formatYYYYMMDDToDDMMYYYY } from '@/common/services/dateUtils';

const Std = () => {

    const { userDetail } = useHeaderSession()

    const stdData = JSON.parse(localStorage.getItem('data-std')) || {};

    const procedureData = JSON.parse(localStorage.getItem('form-data-procedure-std')) || {};

    const { height, quantityChild, ages, weight, operationDate, consumeMedicaments, drinkAlcohol, drugsConsume, haveIllness, haveSurgery, smoke, sons, youAlergic, youAlergicKnowed, whichSurgery, whichIllness, whichDrugs, whichMedicaments, whichAlergic, whichAlergicKnowed } = stdData;

    const hasData = height || weight || operationDate;

    const {
        cirugiasConfig,
    } = AllCampsForms() 

    return (
        <div className={styles.mainClinicDivPackage} >
            <div className={styles.topClinicDivPackage}>
                <h3>Sistema Triaje y Diagnostico</h3>
            </div>
            <Grid container spacing={1} sx={{ paddingBottom: '16px', borderBottom: '2px solid #ECECEC' }}>
                {hasData && (
                    <Grid item xs={12} md={6} className={styles.aditionalInfoStd}
                        sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <h4 style={{width:'100%'}}>Información adicional</h4>
                        <div>
                            <span>
                                <p>Sr / Sra: {userDetail?.name}</p>
                                <p>Correo: {userDetail?.email}</p>
                                <p>Fecha de nacimiento:  {userDetail?.birth_date && formatYYYYMMDDToDDMMYYYY(userDetail?.birth_date)}</p>
                                <p>Celular: +{userDetail?.prex_phone_public} {userDetail?.phone_public} </p>
                            </span>

                            <span>
                                {operationDate && <p>Fecha que deseas operarte: {operationDate}</p>}
                                {height && <p>Estatura: {height}cm</p>}
                                {weight && <p>Peso: {weight}kg</p>}
                            </span>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} md={6} className={styles.importantQuestionsStd} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <h4>Preguntas importantes</h4>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Consume alcohol? - {drinkAlcohol ? 'Sí' : 'No'}</p>
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Fuma? - {smoke ? 'Sí' : 'No'}</p>
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Tienes hijos? - {sons ? `Sí, ${quantityChild} hijos` : 'No'}</p>
                        {sons && ages && ages.length > 0 && (
                            <span>De {ages.join(', ')} años de edad</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Tienes hijos? - {sons ? `Sí, ${quantityChild} hijos` : 'No'}</p>
                        {sons && ages && ages.length > 0 && (
                            <span>De {ages.join(', ')} años de edad</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Se ha practicado alguna cirugía? - {haveSurgery ? 'Sí' : 'No'}</p>
                        {haveSurgery && (
                            <span>{whichSurgery}</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Sufre alguna enfermedad importante? - {haveIllness ? 'Sí' : 'No'}</p>
                        {haveIllness && (
                            <span>{whichIllness}</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Consume algun estupefaciente? - {drugsConsume ? 'Sí' : 'No'}</p>
                        {drugsConsume && (
                            <span>{whichDrugs}</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Tomas algun medicamento? - {consumeMedicaments ? 'Sí' : 'No'}</p>
                        {consumeMedicaments && (
                            <span>{whichMedicaments}</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Tienes alguna alergia conocida? - {youAlergicKnowed ? 'Sí' : 'No'}</p>
                        {youAlergicKnowed && (
                            <span>{whichAlergicKnowed}</span>
                        )}
                    </div>

                    <div className={styles.answersQuestionsStd}>
                        <p>¿Es alergico algun medicamento o sutura? - {youAlergic ? 'Sí' : 'No'}</p>
                        {youAlergic && (
                            <span>{whichAlergic}</span>
                        )}
                    </div>
                </Grid>
            </Grid>

            {Object.keys(procedureData).map((procedureKey) => (
                <Grid container spacing={1} className={styles.procedureInfo} key={procedureKey}  >
                    <Grid item xs={12} md={6} className={styles.aditionalInfoStd} sx={{ display: 'flex', flexDirection: 'column', gap: '10px', }}>
                        <h4>{procedureKey?.charAt(0)?.toUpperCase() + procedureKey.slice(1)}</h4>
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            procedureData[procedureKey][field.key] !== undefined && (
                                <div key={field.key} className={styles.divsProcedure}>
                                    <p>{field.label}</p>
                                    <span>
                                        {field.type === 'checkbox' ?
                                            (procedureData[procedureKey][field.key] ? 'Sí' : 'No') :
                                            procedureData[procedureKey][field.key]
                                        }
                                    </span>
                                </div>
                            )
                        ))}
                    </Grid>

                    <Grid item xs={12} md={6} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '10px', }}>
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            field.key === 'biggest_concern_doubt' && (
                                <Grid item xs={12} md={12} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }} key={field.key}>
                                    <h4>{field.label}</h4>
                                    {field.options.map((option) => (
                                        procedureData[procedureKey][option.key] && (
                                            <div key={option.key} className={styles.divsProcedure}>
                                                <p>{option.label}</p>
                                            </div>
                                        )
                                    ))}
                                </Grid>
                            )
                        ))}
                        {cirugiasConfig[procedureKey]?.formulario.map((field) => (
                            field.key === 'hope_to_achieve' && (
                                <Grid item xs={12} md={12} className={styles.aditionalInfoStdLocal} sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }} key={field.key}>
                                    <h4>{field.label}</h4>
                                    {field.options.map((option) => (
                                        procedureData[procedureKey][option.key] && (
                                            <div key={option.key} className={styles.divsProcedure}>
                                                <p>{option.label}</p>
                                            </div>
                                        )
                                    ))}
                                </Grid>
                            )
                        ))}
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

export default Std;