import React, { useContext } from "react";
import ParticipantView from "../components/participantView";
import Controls from "../components/controls";
import { MdFullscreen, MdDoNotDisturb } from "react-icons/md";
import ActivityIndicator from "../../share/components/activityIndicator";
import { CallContext } from "@/Context/videoCall/CallProvider";
import useWindowFloat from "@/ui/viewModels/videoSdk/useWindowFloat";
import PresenterView from "../components/presenterView/PresenterView";


function CallView() {
  const {
    isCallActive,
    callData: { status, closeMeeting },
    setCallData,
    setCloseMeeting,
    participants,
    presenterId
  } = useContext(CallContext);


  const {
    divRef,
    handleEnd,
    handleMove,
    handleStart,
    isDragging,
    position,
    offset,
    toggleFullScreen,
    size
  } = useWindowFloat()



  const handleLeave = () => {
    setCallData({
      participants: [],
      status: "DISCONNECTED",
      closeMeeting: false,
    });
  };



  const firstParticipantId = [...participants.keys()][0];

  return (
    <div
      className={`${closeMeeting ? "meetingPeopleTeam initial" : "meetingPeopleTeam finally"
        }`}
      ref={divRef}
      style={{
        position: "fixed",
        top: `${position.y}px`,
        left: `${position.x}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
        resize: "both",
        overflow: "auto",
        cursor: isDragging ? "grabbing" : "move",
      }}
      //   onMouseDown={handleMouseDown}
      //   onMouseMove={handleMouseMove}
      //   onMouseUp={handleMouseUp}
      //   onMouseLeave={handleMouseUp}


      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onMouseLeave={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      <div
        className={`${closeMeeting
          ? "meetingPeopleTeamContent initial"
          : "meetingPeopleTeamContent finally"
          }`}
      >
        <button
          className="meetingPeopleTeamContentClose"
          onClick={() =>
            toggleFullScreen()
          }
        >
          <MdFullscreen size={25} />
        </button>
        {status === "JOINED" ? (
          <div className="meetingNow">
            <h3>Videollamada en vivo</h3>
            <div className="participantContainer">

              {participants.map((participantId) => (
                
                    <ParticipantView
                      participantId={participantId}
                      key={participantId}
                    />
                  

              ))}
            </div>
            <Controls closeMeeting={setCloseMeeting} onLeave={handleLeave} participantId={firstParticipantId} />
          </div>
        ) : status === "JOINING" ? (
          <div className="allCenter">
            <ActivityIndicator size={40} colorFill="#FFFFFF" />
            <p className="onlyText">Uniéndose a la reunión...</p>
          </div>
        ) : (
          <div className="allCenter">
            <p className="onlyText">Lo siento, ocurrió un error</p>
          </div>
        )}
      </div>
    </div>
  );
}


export default CallView;