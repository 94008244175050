import React from 'react';
import { IoClose } from 'react-icons/io5';
import styles from "./styles.module.css"

const ClinicFilters = ({ searchTerm, setSearchTerm, handleClear, handleSearch }) => {
    return (
        <div className={styles.contain_filters}>
            <div className={styles.filters}>
                <h2>Filtros</h2>
            </div>
            <div className={styles.contentFilter}>
                <div className={styles.filters}>
                    <h2>Nombre</h2>
                    <span>Filtro por nombre</span>
                    <div className={styles.inputClose}>
                        <input
                            type="text"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            placeholder="Escribe el nombre"
                        />
                        <button className={styles.closeButton} onClick={handleClear}>
                            <IoClose size={18} />
                        </button>
                    </div>
                </div>
            </div>
            <button className={styles.buttonSearch} onClick={handleSearch}>
                Buscar
            </button>
        </div>
    );
};

export default ClinicFilters;