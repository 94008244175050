import { useEffect, useState } from "react";
import styles from "./index.module.css";
import {
  MdDateRange,
  MdDelete,
} from "react-icons/md";
import { HiOutlineMail, HiOutlineMailOpen } from "react-icons/hi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { Link } from "react-router-dom";
import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";
import { IoCheckmark, IoClose, IoInformation, IoWarning } from "react-icons/io5";
import useDynamicRefetch from "@/common/hooks/useDynamicRefetch";
import { useTranslation } from "react-i18next";

function MyNotifications({ filterBy }) {
  const {notifications, setNotifications, getUserNotifications, markUserNotificationAsRead} = useUserProfile();

  const {t, i18n} = useTranslation("global");

  const handleGetUserNotifications = async () => {
    try {
      await getUserNotifications();
    } catch (error) {
      console.log(error);
    }
  }

  const handleMarkAsRead = async (notificationId) => {
    try {
      await markUserNotificationAsRead(notificationId);
      
      const newNotifications = await getUserNotifications();

      setNotifications(newNotifications);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const manageNotificationTitle = (notificationTitle) => {
    switch (notificationTitle) {
      case "Bienvenido/a":
        return t("profile.modules.my_notifications.titles.welcome");
      case "Reserva completada":
        return t("profile.modules.my_notifications.titles.completed_reserve");
      case "Pago realizado":
        return t("profile.modules.my_notifications.titles.payment_done");
      default:
        return notificationTitle
    }
  }

  useDynamicRefetch(handleGetUserNotifications, { interval: 20000 })

  useEffect(() => {
    handleGetUserNotifications()
  }, [])

  useEffect(() => {
    const handleNotificationUpdate = (event) => {
      handleGetUserNotifications(); // Update notifications after listened event of notificationUpdated
    };

    window.addEventListener("notificationUpdated", handleNotificationUpdate);

    return () => {
      window.removeEventListener("notificationUpdated", handleNotificationUpdate);
    };
  }, []);

  useEffect(() => {
    if (filterBy && filterBy.length) {
      setFilterCriteria((prev) => ({
        ...prev,
        ["status"]: filterCriteria["status"] === "no leída" ? null : "no leída",
      }));
    }
  }, [filterBy])

  const [filterCriteria, setFilterCriteria] = useState({
    status: null,
    important: null,
    date: null,
  });

  const [dateInputVisible, setDateInputVisible] = useState(false);

  const filters = [
    {
      key: "status",
      value: "leída",
      label: "read",
      icon: <HiOutlineMailOpen size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "no leída",
      label: "unread",
      icon: <HiOutlineMail size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "important",
      value: "success",
      label: "success",
      icon: <IoCheckmark size={20} color="green" />,
    },
    {
      key: "important",
      value: "error",
      label: "error",
      icon: <IoClose size={20} color="red" />,
    },
    {
      key: "important",
      value: "warning",
      label: "warning",
      icon: <IoWarning size={20} color="yellow" />,
    },
    {
      key: "important",
      value: "info",
      label: "info",
      icon: <IoInformation size={20} color="blue" />,
    },
    {
      key: "date",
      label: "date",
      icon: <MdDateRange size={20} color="var(--bluePrimary)" />,
      renderInput: dateInputVisible && (
        <input
          type="date"
          id="filter-date"
          className={styles.dateInput}
          value={filterCriteria.date || ""}
          onChange={(e) => handleFilterChange("date", e.target.value)}
        />
      ),
    },
  ];  

  const filterNotifications = (notifications, criteria) => {
    return notifications.filter((notification) => {
      if (
        criteria.status &&
        ((criteria.status === "leída" && notification.is_read !== 1) ||
          (criteria.status === "no leída" && notification.is_read !== 0))
      ) {
        return false;
      }
  
      if (
        criteria.important !== null &&
        notification.type !== criteria.important
      ) {
        return false;
      }
  
      if (criteria.date) {
        const notificationDate = new Date(notification.created_at).toISOString().split("T")[0];
        if (notificationDate !== criteria.date) {
          return false;
        }
      }
  
      return true;
    });
  };  

  const filteredNotifications = filterNotifications(
    notifications,
    filterCriteria
  );

  const handleFilterChange = (key, value) => {
    setFilterCriteria((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleButtonClick = (key, value) => {
    if (key === "date") {
      setDateInputVisible(!dateInputVisible);
      if (filterCriteria.date) {
        setFilterCriteria((prev) => ({
          ...prev,
          date: null,
        }));
      }
    } else {
      setFilterCriteria((prev) => ({
        ...prev,
        [key]: filterCriteria[key] === value ? null : value,
      }));
    }
  };

  const isFilterActive = (key, value) => filterCriteria[key] === value;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMovements = filteredNotifications.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const nextHandler = () => {
    if (currentPage < Math.ceil(filteredNotifications.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className={styles.notificationsSection}>
      <div className={styles.notifications}>
        <h3>{t("profile.modules.my_notifications.text")}</h3>
        <div className={styles.notificationsItems}>
          {currentMovements.length > 0 ? (
            currentMovements.map((notification) => (
              <div key={notification.id} className={`${styles.notificationsItem} ${styles.notificationsItemsScroll} overflow-scroll sm:max-w-[280px] md:max-w-[300px] lg:max-w-full`}>
                <div className={styles.notificationsContent}>
                    <div className={`w-52 ${styles.notificationsIcon} ${notification.type === "info" ? "bg-[var(--blueLight)]" : notification.type === "success" ? "bg-green-500" : notification.type === "warning" ? "bg-yellow-500" : "bg-red-500"}`}>
                        {notification.type === "info" && (
                          <IoInformation size={15} color="white" />
                        )}
                        {notification.type === "success" && (
                          <IoCheckmark size={15} color="white" />
                        )}
                        {notification.type === "warning" && (
                          <IoWarning size={15} color="white" />
                        )}
                        {notification.type === "error" && (
                          <IoClose size={15} color="white" />
                        )}
                    </div>
                  <div className={`font-semibold text-sm text-black`}>
                    {notification.is_read === 0 ? (
                      <div className="flex gap-1">
                        <span className="text-customBlue">
                          {manageNotificationTitle(notification.title)}
                        </span> 
                        - 
                        <button className="text-xs underline text-green-700 hover:text-green-900 cursor-pointer"
                          onClick={() => handleMarkAsRead(notification.id)}>
                            {t("profile.modules.my_notifications.mark_as_read")}
                        </button>
                      </div>
                    ) : (
                      <div className="flex gap-1">
                        <span className="text-customBlue">
                          {manageNotificationTitle(notification.title)}
                        </span> 
                        - 
                        <span className="text-xs text-gray-500 flex items-center">
                          {t("profile.modules.my_notifications.read")}
                        </span>
                      </div>
                    )}
                    <div className="w-96 overflow-y-scroll overflow-x-hidden h-fit max-h-14 customScroll">
                      <p>{notification.message}</p>
                    </div>
                  </div>
                </div>
                <div className="flex align-middle items-end">
                  <div className={styles.notificationsTime}>
                    {new Date(notification.created_at).toLocaleString()}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <div className={styles.message}>
                {t("profile.modules.my_notifications.without_notifications")}
                <AiOutlineExclamationCircle
                  size={20}
                  color="var(--bluePrimary)"
                />
              </div>

              <Link to="/" className={styles.buy}>
                {t("profile.modules.my_notifications.go_to_buy")}
              </Link>
            </div>
          )}
        </div>
        {currentMovements.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredNotifications}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>

      <div className={styles.filters}>
        <h3>{t("profile.modules.my_notifications.filters.text")}</h3>
        <div className={styles.filterButtons}>
          {filters.map(({ key, value, label, icon, renderInput }) => (
            <div key={label} className={styles.filterContainer}>
              <button
                className={`${styles.buttonFilter} ${
                  isFilterActive(key, value) ? styles.activeFilter : ""
                }`}
                onClick={() => handleButtonClick(key, value)}
              >
                {icon} {t(`profile.modules.my_notifications.filters.${label}`)}
              </button>
              {renderInput}
            </div>
          ))}
          <button
            className={styles.buttonFilterDelete}
            onClick={() =>
              setFilterCriteria({ status: null, important: null, date: null })
            }
          >
            <MdDelete size={20} color="var(--bluePrimarys)" /> {t("profile.modules.my_notifications.filters.button_text")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MyNotifications;
