import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { global } from '../../../../global';
import {useTranslation} from 'react-i18next'
import TextComponent from '../fonts/TextComponent';



const {
  AppStoreLogo,
  GooglePlayLogo,
  IATALogo,
  PaypalLogo,
  VisaLogo,
  MasterCardLogo,
} = global.img.logos;


function Footer() {

  const linkAppStore = 'https://apps.apple.com/co/app/wellezy.app/id515352523?l=en';
  const linkGooglePlay = 'https://play.google.com/store/apps/details?id=com.wellezy.android';

  const [t, i18n] = useTranslation("global")

  const currentLanguage = i18n.language;

  return (
    <div className='footerContainer'>
      <div className='footerContainerLogos'>
        <div className='footerContainerLogosGroup footerContainerLogosGroupLeft'>
          <div className='footerLogoContainer small'>
            <img src={VisaLogo} alt="Visa-logo" className='footerLogo' />
          </div>
          <div className='footerLogoContainer small'>
            <img src={MasterCardLogo} alt="MasterCard-logo" className='footerLogo' />
          </div>
          <div className='footerLogoContainer small'>
            <img src={PaypalLogo} alt="PayPal-logo" className='footerLogo' />
          </div>
        </div>
        <div className='footerContainerLogosGroup footerContainerLogosGroupCenter'>
          <p className='footerContainerLogosGroupCenterText'>{t('browse_home.footer.download_app')}</p>
          <div className='footerContainerLogosGroupCenterRow'>

            <a href={linkAppStore}>
              <div className='footerLogoContainer big'>
                <img src={AppStoreLogo} alt="AppStore-logo" className='footerLogo' />
              </div>
            </a>
            <a href={linkGooglePlay}>
              <div className='footerLogoContainer big'>
                <img src={GooglePlayLogo} alt="GooglePlay-logo" className='footerLogo' />
              </div>
            </a>
          </div>
        </div>
        <div className='footerContainerLogosGroup footerContainerLogosGroupRight'>
          <div className='footerLogoContainer'>
            <img src={IATALogo} alt="IATA-logo" className='footerLogo' />
          </div>
          <div className='footerContainerLogosGroupRightTextContainer'>
            {currentLanguage === 'es' ? (
              <div>
                <p className='footerContainerLogosGroupRightText'>{t('footer.agent')}</p>
                <p className='footerContainerLogosGroupRightText'>{t('footer.accredited')}</p>
              </div>
            ): (
              <div>
                <p className='footerContainerLogosGroupRightText'>{t('footer.accredited')}</p>
                <p className='footerContainerLogosGroupRightText'>{t('footer.agent')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='footerContainerInfo'>
        <TextComponent className={"footerContainerInfoText"} children={t('wellezy_rights')} size={"sm"} />
      </div>
    </div>

  )
}

export default Footer;
