import React, { useEffect, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import ActivityIndicator from "../../../share/components/activityIndicator";
import ParticipantView from "../participantView";
import { Call } from "iconsax-react";
import "./styles.css";
import Controls from "../controls";
function MeetingViewPX(props) {
  const { name, meetingId, setIsShowControls, setTotalParticipants } = props;
  const [joined, setJoined] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAnyExpanded, setIsAnyExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Página actual

  console.log(meetingId)

  const statusList = {
    join: { value: "JOIN" },
    joined: { value: "JOINED" },
    joining: { value: "JOINING" },
  };

  const { join, participants,leave } = useMeeting({
    onMeetingJoined: () => {
      setJoined(statusList?.joined?.value);
    },
  });

  const joinMeeting = () => {
    setJoined(statusList?.joining?.value);
    join({ meetingId: meetingId });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Detecta si la pantalla es móvil
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const status = joined && joined == statusList?.joined?.value;
    setIsShowControls(status);
  }, [joined]);

  useEffect(() => {
    setTotalParticipants(participants?.size);

    const localParticipants = [...participants].map((index) => {
      if (index?.data?.local) return index;
      return null;
    });
  }, [participants]);

  const participantArray = [...participants];
  const firstParticipantId = [...participants.keys()][0];

  const changePage = (direction) => {
    setCurrentPage((prev) => {
      const newPage =
        direction === "next"
          ? (prev + 1) % participantArray.length
          : (prev - 1 + participantArray.length) % participantArray.length;
      return newPage;
    });
  };

  return (
    <div className="containerJoinPX">
      {!joined && (
        <div className="meetingViewPX">
          <p className="meetCodeLabelBigPX">Hola {name}!</p>
          <p className="meetCodeLabelPX">Meeting Id:</p>
          <p className="meetCodeValuePX">{meetingId}</p>
          <div className="btnJoinPX" onClick={joinMeeting}>
            <Call size="40" color="#ffffff" />
            <p className="btnJoinTextPX">{statusList?.join?.value}</p>
          </div>
          <div onClick={() => props.back(1)} className="btnBackPX">
            <p className="btnBackTextPX">volver atras</p>
          </div>
        </div>
      )}

      {/* Pantalla de unión */}
      {joined && joined === statusList?.joining?.value && (
        <div className="flex flex-col items-center justify-center flex-grow">
          <ActivityIndicator size={40} colorFill={"#FFFFFF"} />
          <p className="mt-4 text-lg">Joining the meeting...</p>
        </div>
      )}

      {/* Pantalla principal */}
      {joined && joined === statusList?.joined?.value && (
        <>
          {/* Participantes */}
          {!isMobile && (
            <div >
              <div className="flex-grow flex items-center justify-center">
                <div
                  className={`grid gap-4 ${
                    participants.size === 1
                      ? "sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1"
                      : participants.size === 2
                      ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2"
                      : participants.size === 3
                      ? "sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3"
                      : "sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4"
                  }  place-items-center`}
                >
                  {participantArray.slice(0, 10).map((index) => {
                    const id = index[0];
                    const data = index[1];
                    return (
                      <ParticipantView
                        setIsAnyExpanded={setIsAnyExpanded}
                        data={data}
                        participantId={id}
                        key={id}
                        totalParticipants={participants?.size}
                        setMicOn={props?.setMicOn}
                        setWebcamOn={props?.setWebcamOn}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <div className="flex flex-col items-center">
              {/* Mostrar participante actual */}
              {participantArray[currentPage] && (
                <ParticipantView
                  setIsAnyExpanded={setIsAnyExpanded}
                  participantId={participantArray[currentPage][0]}
                  data={participantArray[currentPage][1]}
                  key={participantArray[currentPage][0]}
                  setMicOn={props?.setMicOn}
                  setWebcamOn={props?.setWebcamOn}
                />
              )}
              {/* Controles de paginación */}
              <div className="flex gap-4 mt-4">
                <button
                  onClick={() => changePage("prev")}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Anterior
                </button>
                <button
                  onClick={() => changePage("next")}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Siguiente
                </button>
              </div>
            </div>
          )}

          {/* Controles */}
          <div className={`z-20 ${
              isMobile && isAnyExpanded ? "mt-[150px]" : "mt-2"
            }`}>
            <Controls participantId={firstParticipantId} onLeave={() => leave} />
          </div>
        </>
      )}
    </div>
  );
}
export default MeetingViewPX;
