import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "common/components/Modal";
import UserContext from "@/Context/user/userProvider";

const ConfirmModal = ({ showConfirmModal, setShowConfirmModal, confirmProcedure, hasCloseButton }) => {
  const { userDetail } = useContext(UserContext);
  const [userFirstName, setUserFirstName] = useState("");

  useEffect(() => {
    const userName = userDetail?.name;
    if (userName) {
      const names = userName.split(" ");
      setUserFirstName(names[0]);
    }
  }, [userDetail]);

  return (
    <Modal
      showModal={showConfirmModal}
      onClose={() => setShowConfirmModal(false)}
      hasCloseButton={hasCloseButton}
    >
      <div className="p-2 flex flex-col items-center justify-center">
        <h2 className="!text-base font-bold mb-4 text-center text-customBlue">
          Hola, {userFirstName}!
        </h2>
        <p className="p-4 mb-2 text-center">
          A continuación te vamos a redirigir a nuestro  <br />
          <span className="text-base font-semibold text-customBlue">
            Sistema de Triage y Diagnóstico
          </span>
          <br />
          con el fin de evaluar tu historial clínico para la realización de tu cirugía.
        </p>
        <div className="flex justify-center mt-4 gap-5">
          <button
            onClick={() => setShowConfirmModal(false)}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={confirmProcedure}
            className="px-4 py-2 bg-customBlue text-white rounded hover:bg-customHoverBlue"
          >
            Aceptar
          </button>
        </div>
      </div>
    </Modal>
  );
};

Modal.propTypes = {
  showConfirmModal: PropTypes.bool.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  confirmProcedure: PropTypes.func.isRequired,
  hasCloseButton: PropTypes.bool,
}

Modal.defaultProps = {
  hasCloseButton: true,
}

export default ConfirmModal;