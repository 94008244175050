import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../share/components/loading'
import { PiMedal } from "react-icons/pi"
import { IoClose, IoLocationOutline } from "react-icons/io5"
import { BsCalendar2Date } from "react-icons/bs"
import { FiSend } from "react-icons/fi"
import { MdComputer } from "react-icons/md"
import { IoMdCheckmarkCircleOutline } from "react-icons/io"
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import { MdCameraAlt } from "react-icons/md"
import "./styles.css"
import { BsHospital } from "react-icons/bs"
import styles from "./styles.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { CircularProgress } from '@material-ui/core'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'


const DetailSpecialist = ({ slug, handleClose }) => {
  const [t] = useTranslation("global");
  const { state } = useLocation();
  const navigate = useNavigate()
  const sliderRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date())
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCalendarIndex, setActiveCalendarIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [seeMore, setSeeMore] = useState(false)
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false)
  const [conditionNext, setConditionNext] = useState(false)
  const [conditionPrev, setConditionPrev] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)


  const [dataDoc, setDataDoc] = useState({})
  const [loadingS, setLoadingS] = useState(false);

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [image_profile, setImageProfile] = useState("")
  const [cities, setCities] = useState({})
  const [categories, setCategories] = useState({})
  const [specialties, setSpecialties] = useState({})
  const [description, setDescription] = useState("")
  const [address, setAddress] = useState("")

  const [noDisponibility, setNoDisponibility] = useState(false);

  useEffect(() => {

    const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/get/doctor/${slug}`

    setLoadingS(true)
    axios.get(url)
      .then((res) => {
        setLoadingS(false)
        setDataDoc(res.data)
        setId(res.data?.id)
        setName(res.data?.name)
        setImageProfile(res.data?.image_profile);
        setCities(res.data?.cities)
        setCategories(res.data?.categories)
        setSpecialties(res.data?.specialties)
        setDescription(res.data?.description)
        setAddress(res.data?.address)
      })
      .catch((error) => {
        console.log(error);
        setLoadingS(false)
      });
  }, [])

  console.log("Detalle especialista: ", dataDoc)

  console.log("Especialidades", specialties)



  function removeStylesAndAttributes(html) {
    return html
      .replace(/<(\w+)\s+[^>]*>/g, '<$1>') // Eliminar atributos de las etiquetas
      .replace(/<style[^>]*>.*?<\/style>/g, '') // Eliminar cualquier bloque de estilo
      .replace(/style="[^"]*"/g, '') // Eliminar estilos inline
      .replace(/\s{2,}/g, ' '); // Eliminar espacios en exceso
  }

  const NextArrow = ({ onClick }) => {
    return (
      <div className={`${styles.nextArrow}`} onClick={() => {
        handleNextClick()
        if (onClick) {
          onClick()
          setConditionPrev(false)
          setConditionNext(false)
        }
      }}>
        &#10095;
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className={`${styles.prevArrow}`} onClick={() => {
        handlePrevClick()
        if (onClick) {
          onClick()
          setConditionPrev(false)
          setConditionNext(false)
        }

      }}>
        &#10094;
      </div>
    );
  };


  const settings = {
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 4,
    afterChange: (current) => handleAfterChange(current),
    responsive: [
      {
        breakpoint: 1154,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }
    ],
    nextArrow: <NextArrow onClick={() => handleNextClick()} />,
    prevArrow: <PrevArrow onClick={() => handlePrevClick()} />
  };


  const handleAfterChange = (current) => {
    setCurrentSlide(current);

    const totalSlides = Math.ceil(days.length / settings.slidesToShow);
    // Verificar si estamos al comienzo
    if (current === 0) {
      setConditionPrev(true)
    }
    if (current + settings.slidesToShow >= days.length) {
      setConditionNext(true)
    }
  };

  const setCalendar = async (today = new Date(), prev = false, id) => {
    setLoading(true)
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    let disponibility = await GetDisponibility(id, currentMonth);
    const calendarDays = [];

    if (disponibility.length === 0) {
      setNoDisponibility(true);
    }

    for (let day = today.getDate(); day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day);
      const dayName = date.toLocaleDateString("es-ES", { weekday: "long" });
      const dayNumber = date.toLocaleDateString("es-ES", { day: "numeric" });
      const monthName = date.toLocaleDateString("es-ES", { month: "short" });
      const hours = []
      console.log(disponibility)
      if (disponibility?.length > 0 || disponibility) {
        disponibility.forEach((item) => {
          const itemDate = new Date(item?.date);

          if (
            itemDate.getDate() === date.getDate() && // Compara el día
            itemDate.getMonth() === date.getMonth() && // Compara el mes
            itemDate.getFullYear() === date.getFullYear() // Compara el año
          ) {
            hours.push(item?.hours);
          }
        });
      }

      // Omitimos los domingos
      if (date.getDay() !== 0) {
        if (hours.length > 0) {
          calendarDays.push({
            dayName: capitalizeFirstLetter(dayName),
            dayNumber,
            monthName,
            hours
          });
        } else {
          calendarDays.push({
            dayName: capitalizeFirstLetter(dayName),
            dayNumber,
            monthName
          });
        }

      }
    }

    setDays(calendarDays);
    setLoading(false)
    // if (prev) {
    //   await sliderRef?.current?.slickGoTo(calendarDays.length - 1);
    // }

  }

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const sections = [
    { name: "Sobre Mi", href: "#sobre-mi" },
    // { name: "Experiencias", href: "#experiencias" },
    // { name: "Opiniones", href: "#opiniones" },
    { name: "Procedimientos", href: "#procedimientos" },
    { name: "Consultorios", href: "#consultorios" },
  ];


  const itemConsult = [
    {
      name: "En línea",
      icon: <MdComputer size={25} />,
    },
    {
      name: "Dirección",
      icon: <IoLocationOutline size={25} />,
    },
  ];

  const itemCalendar = [
    {
      name: "Consulta en línea ",
      icon: <MdComputer size={25} color="var(--blueLight)" />,
    },
    {
      name: "Cita  presencial",
      icon: <BsHospital size={25} color="var(--blueLight)" />,
    },
  ];

  const goReservation = () => {
    navigate(`/Specialist/reservation/${state?.name}`, {
      state: {
        name: state?.name,
        categories: state?.subCategories,
        urlImage: state?.pathImage,
        stars: state?.infoDoctor?.stars,
        id: state?.id
      }
    })
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const nextMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    setCurrentDate(newDate);
    sliderRef.current.slickGoTo(0);
    setCalendar(newDate, false, id)
  }

  const prevMonth = () => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    if (newDate > new Date()) {
      setCurrentDate(newDate);
      sliderRef?.current?.slickGoTo(days.length - 1);
      setCalendar(newDate, "true", id)


    } else {
      setCurrentDate(new Date())
      sliderRef?.current?.slickGoTo(days.length - 1);
      setCalendar(new Date(), "true", id)

    }

  }


  function GetDisponibility(id, mes) {
    return new Promise((resolve, reject) => {
      const url = `https://pdtclientsolutions.com/crm-public/api/shedulle/doctor/${id}/${mes + 1}`;

      axios.get(url)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log('Error in the Axios request:', error);
          resolve([]);
        });
    });
  }




  useEffect(() => {
    setCalendar()
  }, []);

  const handleNextClick = () => {
    // Aquí puedes ejecutar tu función personalizada
    if (conditionNext) {
      setConditionPrev(true)
      setConditionNext(false)
      nextMonth()
    }
  };

  const handlePrevClick = () => {

    if (conditionPrev) {
      setConditionPrev(false)
      setConditionNext(false)
      prevMonth()
    }
    // Aquí puedes ejecutar tu función personalizada para el botón "Prev"
  };



  return (
    <div className='contain-detail-specialist'>
      {
        loadingS &&
        <Loading size={90} />
      }
      <div className={styles.contentDetail}>
        <div className='topDetailSpecialist'>
          <IoClose onClick={handleClose} />
        </div>
        <div className="itemDetailDoc">
          <div className="itemDetailDocInfo">
            <div className="itemDetailDocInfoSectionDoc">
              <div className="itemDetailDocInfoSectionDocImg" style={{ background: !imageLoaded && "none" }}>
                {
                  !imageLoaded &&
                  <div className={styles.img_specialist_skeleton}>
                    <MdCameraAlt size={40} color="var(--greyPrimary)" />
                  </div>
                }
                <img
                  className={styles.img_doc} src={`https://pdtclientsolutions.com/crm-public/img/doctors/profile/${image_profile}`} alt={name}
                  onLoad={() => setImageLoaded(true)}
                  style={{ display: imageLoaded ? 'block' : 'none' }}
                />
              </div>

              <div className="itemDetailDocInfoSectionDocName">
                <div className="itemDetailDocInfoSectionDocNameInfo">
                  <h3>{name}</h3>
                  <p>
                    {
                      categories?.length > 0 ?
                        categories?.map((item, index) => {
                          return `${item.name}${categories?.length > 1 ? "," : ""} `
                        })
                        :
                        "No hay informacion"
                    }
                  </p>
                </div>

                <div className="itemDetailDocInfoSectionDocInfo">
                  <div className="itemDetailDocInfoSectionDocInfoIcon">
                    <IoLocationOutline size={25} color="var(--blueLight)" />
                    <p>{
                      cities?.length > 0 ?
                        <>
                          {
                            cities?.length == 1 ? `${cities?.length} sede en` : `${cities?.length} sedes en`} {
                            cities?.map((item, index) => {
                              return `${item.name}${cities.length > 1 ? "," : ""} `
                            })
                          }

                        </>
                        :
                        "No hay informacion"
                    } Ver mapa</p>
                  </div>

                  <div className="itemDetailDocInfoSectionDocInfoIcon">
                    <PiMedal size={25} color="var(--blueLight)" />
                    <p>Registro profesional</p>
                  </div>

                  <div className="itemDetailDocInfoSectionDocInfoButtons">
                    <a
                      style={{ width: '70%' }}
                      href='https://api.whatsapp.com/send?phone=573004040116&text=Hola%20escribo%20desde%20la%20pagina%20de%20Wellezy%20quiero%20conocer%20mas%20sobre%20los%20paquetes%2C%20toda%20la%20info%20que%20me%20puedas%20ofrecer%20de%20turismo'
                      className="itemDetailDocInfoSectionDocInfoButton"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiSend size={23} />
                      Enviar mensaje
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="detail specialist tabs"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: '#00afe8', // Color de la línea del tab activo
                },
              }}
            >
              {sections.map((section, index) => (
                <Tab
                  key={index}
                  label={section.name.charAt(0).toUpperCase() + section.name.slice(1).toLowerCase()} // Primera letra mayúscula
                  sx={{
                    '&.Mui-selected': {
                      color: '#00afe8', // Color de la letra del tab activo
                    },
                    textTransform: 'capitalize', // Solo la primera letra en mayúscula
                  }}
                />
              ))}
            </Tabs>

            {selectedTab === 0 && (
              <div className="contProcedimientosDetailSpecialist" id='sobre-mi'>
                <div className="itemDetailDocInfoSectionDocConsultName">
                  <h3>SOBRE MÍ</h3>
                </div>


                <div className="itemDetailDocInfoSectionDocAboutDesc">
                  {description}
                </div>

                <label
                  htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                  className="itemDetailDocInfoSectionDocConsultLabel"
                >
                  Especialidades:
                </label>

                {Array.isArray(specialties) && specialties.map((item, index) => {
                  // Crear un div temporal para analizar el contenido de la descripción
                  const tempDiv = document.createElement('div');
                  tempDiv.innerHTML = item?.description || '';

                  // Verificar si hay contenido de texto real
                  const textContent = tempDiv.textContent.trim();

                  // Si no hay contenido de texto real, no renderizar
                  if (!textContent) {
                    return null;
                  }
                  return (
                    <React.Fragment key={index}>
                      <label
                        htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                        className="itemDetailDocInfoSectionDocConsultLabel"
                      >
                        {item.specialty}
                      </label>
                      <div
                        className={styles.cont_description}
                        dangerouslySetInnerHTML={{ __html: removeStylesAndAttributes(item.description) }}
                      />
                    </React.Fragment>
                  );
                })}

              </div>
            )}

            {selectedTab === 1 && (
              <div className="itemDetailDocInfoSectionDocProcess" id='procedimientos'>
                <div className="itemDetailDocInfoSectionDocConsultName">
                  <h3>PROCEDIMIENTOS</h3>
                </div>

                <div className="itemDetailDocInfoSectionDocProcessIcons">
                  {
                    specialties?.length > 0 ?
                      specialties?.map((item, index) => {
                        return (
                          <div key={index} className="itemDetailDocInfoSectionDocInfoProcessIcon">
                            <IoMdCheckmarkCircleOutline size={25} color="var(--blueLight)" />
                            <p>{item?.specialty}</p>
                          </div>
                        )
                      })
                      :
                      "No hay informacion"
                  }
                </div>
              </div>
            )}

            {selectedTab === 2 && (
              <div className="itemDetailDocInfoSectionDocConsult" id='consultorios'>
                <div className="itemDetailDocInfoSectionDocConsultName">
                  <h3>CONSULTORIOS</h3>
                </div>

                <div className="itemDetailDocInfoSectionDocConsultItems">
                  {itemConsult.map((section, index) => (
                    <li key={index}>
                      <button
                        className={`itemDetailDocInfoSectionDocConsultItemsButton ${activeCalendarIndex === index ? "active" : ""
                          }`}
                        onClick={() => setActiveCalendarIndex(index)}
                      >
                        {section.icon} {section.name}
                      </button>
                    </li>
                  ))}
                </div>

                <div className="itemDetailDocInfoSectionDocInfoIcons">
                  {
                    activeCalendarIndex === 0 ?
                      <div className="itemDetailDocInfoSectionDocInfoIcon">
                        <BsCalendar2Date size={25} color="var(--blueLight)" />
                        <p>Reservar cita</p>
                      </div>
                      :
                      <div className="itemDetailDocInfoSectionDocInfoIcon">
                        <IoLocationOutline size={25} color="var(--blueLight)" />
                        <p>{address}</p>
                      </div>

                  }
                </div>
              </div>
            )}

          </div>

          <div className="itemDetailDocCalendarSection">
            <div className="itemDetailDocCalendar" style={{
              borderBottom: activeCalendarIndex == 0 && "none"
            }}>
              <div className="itemDetailDocCalendarTitle">
                <h3>AGENDAR CITA</h3>
              </div>

              <div className="itemDetailDocCalendarList">
                {itemCalendar.map((section, index) => (
                  <li key={index}>
                    <button
                      className={`itemDetailDocCalendarListButton ${activeCalendarIndex === index ? "active" : ""
                        }`}
                      onClick={() => setActiveCalendarIndex(index)}
                    >
                      {section.icon} {section.name}
                    </button>
                  </li>
                ))}
              </div>
              {
                activeCalendarIndex === 1 &&
                <div className="itemDetailDocInfoSectionDocInfoIcon">
                  <IoLocationOutline size={25} color="var(--blueLight)" />
                  <p>{address}</p>
                </div>
              }
            </div>

            <div className="itemDetailDocCalendar">

              {noDisponibility && (
                <div className='noAgendaDiv'>
                  <p>Se habilitará cuando hayan agendas disponibles</p>
                </div>
              )}

              <div className="itemDetailDocCalendarSelect">
                <label
                  htmlFor="itemDetailDocInfoSectionDocConsultLabel"
                  className="itemDetailDocInfoSectionDocConsultLabel"
                >
                  Motivo de la visita
                </label>
                <div className={styles.calendar} style={{ height: seeMore && "300px", overflow: seeMore && "scroll" }}>
                  {
                    loading ?
                      <CircularProgress style={{
                        color: "var(--blueLight)",
                        position: 'absolute',
                        left: "0",
                        top: "0",
                        bottom: "0",
                        right: "0",
                        margin: "auto"
                      }} size={30} />
                      :
                      <Slider ref={sliderRef} {...settings}>
                        {days.map((day, index) => {
                          return (
                            <div key={index}>
                              <div className={styles.day}>
                                <h3>{day.dayName}</h3>
                                <p>{day.dayNumber} {day.monthName}</p>
                                <div>
                                  {
                                    day?.hours ?
                                      <ul>
                                        {
                                          day?.hours?.[0]?.map((item, index) => {

                                            return (
                                              <li key={index}>{item?.time.split(':').slice(0, 2).join(':')}</li>
                                            )
                                          })
                                        }
                                      </ul>
                                      :

                                      <ul>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                        <li>-</li>
                                      </ul>
                                  }
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )}
                      </Slider>
                  }


                </div>
              </div>
            </div>

            <button className="itemDetailDocInfoSectionDocShowMore" onClick={() => setSeeMore(!seeMore)}>
              {
                seeMore ?
                  <>
                    Mostrar menos <IoMdArrowDropup size={25} color="var(--blueLight)" />
                  </>
                  :
                  <>
                    Mostrar más <IoMdArrowDropdown size={25} color="var(--blueLight)" />
                  </>
              }

            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailSpecialist