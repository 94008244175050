import { useRef, useState } from "react";

const useWindowFloat = () => {
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: 350, height: 350 });
  const [previousState, setPreviousState] = useState(null); // Guardar estado previo
  const [isFullScreen, setIsFullScreen] = useState(false);
  const divRef = useRef(null); // Referencia al div para medir su tamaño dinámicamente

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      // Restaurar al estado previo
      setPosition(previousState.position);
      setSize(previousState.size);
    } else {
      // Guardar el estado actual antes de cambiar a pantalla completa
      setPreviousState({ position, size });

      // Ajustar a pantalla completa
      setPosition({ x: 0, y: 0 });
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleStart = (e) => {
    setIsDragging(true);
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
 disableScroll(); // Deshabilitar scroll al iniciar el arrastre
    setOffset({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  const handleMove = (e) => {
    if (!isDragging) return;

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Obtener tamaño dinámico del div
    const divWidth = divRef.current.offsetWidth;
    const divHeight = divRef.current.offsetHeight;

    // Asegúrate de que no se salga del viewport
    const newX = Math.min(
      Math.max(0, clientX - offset.x),
      viewportWidth - divWidth // Usar el ancho dinámico del div
    );
    const newY = Math.min(
      Math.max(0, clientY - offset.y),
      viewportHeight - divHeight // Usar el alto dinámico del div
    );

    setPosition({ x: newX, y: newY });
  };

  const handleEnd = () => {
    setIsDragging(false);
    enableScroll(); // Restaurar scroll al finalizar el arrastre
  };

  return {
    position,
    setPosition,
    isDragging,
    setIsDragging,
    offset,
    setOffset,
    divRef,
    handleStart,
    handleMove,
    handleEnd,
    toggleFullScreen,
    size,
  };
};

export default useWindowFloat;
