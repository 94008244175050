import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import appointmentsData from "./appointments";
import { FaUserDoctor } from "react-icons/fa6";
import { AiOutlineExclamationCircle, AiOutlinePushpin } from "react-icons/ai";
import { HiOutlineMail, HiOutlineMailOpen } from "react-icons/hi";
import { GoFileDirectory } from "react-icons/go";
import { MdDateRange, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import axios from "axios";
import UserContext from "@/Context/user/userProvider";
import Loading from "@/ui/views/share/components/loading";
import { useTranslation } from "react-i18next";

function AppointmentRequests({ goToTeleConsultation }) {


  const [appoinments, setAppoinments] = useState([])
  const { userDetail } = useContext(UserContext);
  const [loading, setLoading] = useState(true)
  const [filteredAppointments, setFilteredAppointments] = useState([])
  const [dateFecha, setDateFecha] = useState(null)

  const [filterCriteria, setFilterCriteria] = useState({
    status: null,
    important: null,
    date: null,
  });

  const [dateInputVisible, setDateInputVisible] = useState(false);

  const {t, i18n} = useTranslation("global");


  const getAppoinmentsCrm = () => {
    const url = `${process.env.REACT_APP_URL_API_CRM}/wellezy/get/dates/${userDetail.id}`
    setLoading(true)
    axios.get(url)
      .then(res => {
        setAppoinments(res.data)
        setFilteredAppointments(res.data)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const filters = [
    {
      key: "status",
      value: "all",
      label: "all",
      icon: <HiOutlineMailOpen size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "major",
      label: "upcoming",
      icon: <HiOutlineMail size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "status",
      value: "finish",
      label: "culminated",
      icon: <GoFileDirectory size={20} color="var(--bluePrimary)" />,
    },
    {
      key: "date",
      label: "date",
      icon: <MdDateRange size={20} color="var(--bluePrimary)" />,
      renderInput: dateInputVisible && (
        <input
          type="date"
          id="filter-date"
          className={styles.dateInput}
          value={dateFecha}
          onChange={(e) => handleFilterChange("date", e.target.value)}
        />
      ),
    },
  ];

  const handleFilterChange = (key, value) => {
    const citasIguales = appoinments.filter(item => {
      return item?.fecha === value; // Comparamos si es menor a hoy
    });
    setFilteredAppointments(citasIguales); // Actualizamos el estado con las citas filtradas

   setDateFecha(value)
  };

  const handleButtonClick = (key, value) => {
    setDateInputVisible(false)
    if (value === "all") {
      setFilteredAppointments(appoinments)
    } else if (value === "major") {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0); // Configura la hora a 00:00:00
      const citasMayores = appoinments.filter(item => {
        const fechaCita = new Date(`${item.fecha}T23:00:00`); // Convertimos la fecha del objeto
        return fechaCita > hoy; // Comparamos si es mayor a hoy
      });
      setFilteredAppointments(citasMayores); // Actualizamos el estado con las citas filtradas
    } else if (value === "finish") {
      const hoy = new Date(); // Fecha actual
      hoy.setHours(0, 0, 0, 0); // Configura la hora a 00:00:00
      const citasMenores = appoinments.filter(item => {
        const fechaCita = new Date(`${item.fecha}T23:00:00`); // Convertimos la fecha del objeto
        return fechaCita < hoy; // Comparamos si es menor a hoy
      });
      setFilteredAppointments(citasMenores); // Actualizamos el estado con las citas filtradas
    }
    else {
      setDateInputVisible(!dateInputVisible)
    }
  };

  const isFilterActive = (key, value) => filterCriteria[key] === value;

  const filterAppointments = (appointments, criteria) => {
    return appointments.filter((appointment) => {
      if (criteria.status && appointment?.status !== criteria.status) {
        return false;
      }
      if (
        criteria.important !== null &&
        appointment?.important !== criteria.important
      ) {
        return false;
      }
      if (criteria.date && appointment?.date !== criteria.date) {
        return false;
      }
      return true;
    });
  };


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAppointments = filteredAppointments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const nextHandler = () => {
    if (currentPage < Math.ceil(filteredAppointments.length / itemsPerPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevHandler = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  useEffect(() => {
    getAppoinmentsCrm()
  }, [])


  function obtenerCodigo(link) {
    if (typeof link !== 'string' || link.trim() === '') {
      throw new Error('El enlace proporcionado no es válido.');
    }

    // Divide el enlace por las barras "/"
    const partes = link.split('/');

    // Devuelve la última parte del enlace
    return partes.pop() || partes.pop(); // Maneja un posible "/" al final
  }


  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  return (
    <div className={styles.appointmentSection}>
      <div className={styles.appointment}>
        <h3>{t("profile.modules.my_appointments.text")}</h3>
        <div className={styles.appointmentItems}>
          {
            loading ?
              (
                <Loading />
              )

              :
              currentAppointments.length > 0 ? (
                currentAppointments.map((item, index) => (
                  <div key={index} className={styles.itemAppointment}>
                    <div className={styles.itemHeader}>
                      <div className={styles.itemHeaderIconText}>
                        <div className={styles.icon}>
                          <FaUserDoctor />
                        </div>
                        <div className={styles.itemHeaderText}>
                          <h4 className={styles.title}>{item?.name_medic}</h4>

                          <p className={styles.shortDescription}>
                            {item?.doctorSpecialty}
                          </p>
                        </div>
                      </div>

                      <p className={styles.itemDate}>
                        {formattedToday === item?.date
                          ? `Hoy a las ${item?.time}`
                          : item?.date}
                      </p>
                    </div>
                    <div className={styles.itemDetails}>
                      <p className={styles.longDescription}>
                        {item?.appointmentDescription}
                      </p>
                    </div>

                    <div className={styles.itemLinks}>
                      <p>
                        Código para ingresar: <span onClick={() => {
                          goToTeleConsultation(obtenerCodigo(item?.link_telesalud))
                        }} style={{
                          borderBottom: "1px solid var(--bluePrimary) !important",
                          width: "fit-content", cursor: "pointer"
                        }}>{obtenerCodigo(item?.link_telesalud)}</span>
                      </p>
                      <p>
                        Asesor(a): <span>{item?.name_adviser}</span>
                      </p>

                      <p>
                        Fecha: <span>{item?.fecha}</span>
                      </p>
                      <p>
                        Procedimiento: <span>{item?.surgerie_name}</span>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.noItems}>
                  <div className={styles.message}>
                    {t("profile.modules.my_appointments.without_appointments")}
                    <AiOutlineExclamationCircle
                      size={20}
                      color="var(--bluePrimary)"
                    />
                  </div>
                  <Link to="/" className={styles.link}>
                    {t("profile.modules.my_appointments.go_home")}
                  </Link>
                </div>
              )

          }

        </div>
        {currentAppointments.length > 0 && (
          <Paginado
            nextHandler={nextHandler}
            prevHandler={prevHandler}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            data={filteredAppointments}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>

      <div className={styles.filters}>
        <h3>{t("profile.modules.my_appointments.filters.text")}</h3>
        <div className={styles.filterButtons}>
          {filters.map(({ key, value, label, icon, renderInput }) => (
            <div key={label} className={styles.filterContainer}>
              <button
                className={`${styles.buttonFilter} ${isFilterActive(key, value) ? styles.activeFilter : ""
                  }`}
                onClick={() => handleButtonClick(key, value)}
              >
                {icon} {t(`profile.modules.my_appointments.filters.${label}`)}
              </button>
              {renderInput}
            </div>
          ))}
          
        </div>
      </div>
    </div>
  );
}

export default AppointmentRequests;
