import React, { useState } from "react";
import style from "./style.module.css";
import useLoginUser from "@/ui/viewModels/session/login.user.viewModel";
import useRegisterUser from "@/ui/viewModels/session/register.user.viewModel";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { LuCalendarCheck, LuLoader2 } from "react-icons/lu";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import PhoneInput from "react-phone-input-2";
import { formatToDDMMYYYY } from "@/common/services/dateUtils";
import DateInputWithPicker from "@/ui/views/BuildPackage/pages/std/components/SelectedDate/DateInputWithPicker";
import SearchInputNew from "../SearchableInput/SearchInputNew";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const Login = ({ setActiveLogin, url }) => {
  const {
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  } = useLoginUser();

  const [t, i18n] = useTranslation("global");

  return (
    <div className={style.modalFormLogin}>
      <h3>{t("login.modal_title")}</h3>
      <form className={style.formLabelInputs}>
        {inputsList.map((input, key) => {
          const error = getInputError(input);
          return (
            <div key={key} className={style.labelInput}>
              <label className={style.label}>{input.label}</label>
              <div className={`${style.input} ${error && style.error}`}>
                <input
                  type={input.type}
                  id={input.id}
                  value={input.value}
                  placeholder={input.placeholder}
                  onChange={input.func}
                  onBlur={input.onBlur}
                />
                {input.id === "password" && (
                  <button
                    type="button"
                    className={style.eyePassword}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <FaEye color="var(--greySecundary)" size={15} />
                    ) : (
                      <FaEyeSlash color="var(--greySecundary)" size={16} />
                    )}
                  </button>
                )}
              </div>
              {error && <p className={style.messageInput}>{error}</p>}
            </div>
          );
        })}
      </form>
      <div className={style.buttonsActions}>
        <button
          onClick={() => !btnDisable && onLogin(true, url)}
          className={`${style.formBtnSend} ${btnDisable ? style.disabled : style.formBtnSendAvaliable
            }`}
        >
          {loading ? (
            <LuLoader2 size={19} className={style.spinLoginModal} />
          ) : (
            <p className="formBtnSendText">{t("login.log_in")}</p>
          )}
        </button>

        <div className={style.separationOr}>
          <div className="separation-line"></div>
          {t("login.or")}
          <div className="separation-line"></div>
        </div>

        <button
          onClick={() => setActiveLogin(false)}
          className={style.register}
        >
          {t("login.register")}
        </button>
      </div>
    </div>
  );
};

const Register = ({ setActiveLogin, url }) => {
  const {
    inputsList,
    btnDisabled,
    showPassword,
    setShowPassword,
    loading,
    inputErrors,
    handleRegister,
    validateInput,
    setFormValues
  } = useRegisterUser();

  const [t] = useTranslation("global");
  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    }
  );

  const [birthDate, setBirthDate] = useState("")
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);

  const passwordRequirements = [
    { regex: /[A-Z]/, text: t("register.password_requirements.min_uppercase") },
    { regex: /[a-z]/, text: t("register.password_requirements.min_lowercase") },
    { regex: /\d/, text: t("register.password_requirements.min_number") },
    {
      regex: /[!@#$%^&*(),.?":{}|<>]/,
      text: t("register.password_requirements.min_special"),
    },
    { regex: /^.{8,16}$/, text: t("register.password_requirements.length") },
  ];

  const passwordInput = inputsList.find((input) => input.id === "password");

  // Verifica si el campo de contraseña existe
  if (!passwordInput) {
    throw new Error("El campo de contraseña no existe.");
  }
  const isPasswordEmpty = passwordInput.value.trim() === "";
  const isPasswordValid = !isPasswordEmpty && passwordRequirements.every((req) => passwordInput.value.match(req.regex));
  const validationResult = !isPasswordValid && isPasswordEmpty;
  const isPasswordValidPass = passwordRequirements.every((req) =>
    inputsList.find((input) => input.id === "password").value.match(req.regex)
  );

  const handleRegisterEvent = async (modal, urlT) => {
    try {
      await handleRegister(modal, urlT);
    } catch (error) {
      notify(error.message, "❌");
    }
  };

  return (
    <div className={style.modalFormLogin}>
      <h3>{t("register.modal_title")}</h3>
      <form className={style.formLabelInputs}>
        {inputsList.map((input, key) => {
          const isError = inputErrors[input.id];
          const showBtn = ["password", "confirmPassword"].includes(
            input.id
          );

          return (
            <div key={key} className={style.userRegisterInputContainer}>
              {input.fields && input.type === "flex" ? (
                <div className={style.flexRowContainer}>
                  {input.fields.map((input, index) => (
                    <div key={index} className={style.flexRowContainerItem}>
                      {input.id === "prex_and_phone_public" && (
                        <div className={style.flexRowContainItemInput}>
                          <label className={style.label}>
                            {input.label}
                          </label>
                          <PhoneInput
                            country={"co"}
                            value={input.value}
                            onChange={input.func}
                            inputStyle={inputErrors[input.id] ? { border: "1px solid #FF5151", width: "100%", borderRadius: "8px"} : {}}
                            enableSearch={true}
                          />

                          {inputErrors[input.id] && (
                            <p className={style.messageInput}>
                              {inputErrors[input.id]}
                            </p>
                          )}
                        </div>
                      )}

                      {input.id === "birthDate" && (
                        <div className={style.flexRowContainItemInput} style={{marginTop: "10px"}}>
                          <label className={style.label} htmlFor="birthDate">
                            {input.label}
                          </label>

                          <DateInputWithPicker
                            inputId="birthDate"
                            selectedDate={selectedBirthDate}
                            setSelectedDate={(date) => {
                                setSelectedBirthDate(date);
                        
                                const formattedDate = date ? formatToDDMMYYYY(date) : "";
                                setBirthDate(formattedDate);
                        
                                validateInput("birthDate", formattedDate);
                        
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  birthDate: formattedDate,
                                }));
                            }}
                            setBirthDate={setBirthDate}
                            isBirthday={true}
                            placeholder="dd/mm/yyyy"
                            icon={() => <LuCalendarCheck color="var(--blueLightTransparent)" size={17} />}
                            hasButton={false}
                            validationForm={!!inputErrors[input.id]}
                          />

                          {inputErrors[input.id] && (
                            <p className={style.messageInput}>
                              {inputErrors[input.id]}
                            </p>
                          )}
                        </div>
                      )}

                      {input.id === "gender" && (
                        <div className={style.flexRowContainItemInput} style={{marginTop: "10px"}}>
                          <label className={style.label}>
                            {input.label}
                          </label>
                          <SearchInputNew
                            name={input.id}
                            options={input.options}
                            value={input.value}
                            onChange={input.func}
                            placeholder={input.placeholder}
                            onBlur={input.onBlur}
                            type={input.type}
                            id={input.id}
                            inputErrors={inputErrors[input.id]}
                          />

                          {inputErrors[input.id] && (
                            <p className={style.messageInput}>
                              {inputErrors[input.id]}
                            </p>
                          )}
                        </div>
                      )} 
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <label className={style.label}>
                    {input.label}
                  </label>
                  <div className={`${style.input} ${isError && style.error}`}>
                    <input
                      type={
                        input.id === "password" && showPassword
                          ? "text"
                          : input.type
                      }
                      name={input.id}
                      id={input.id}
                      value={input.value}
                      placeholder={input.placeholder} 
                      className="text-sm"
                      onChange={input.func}
                      onBlur={input.onBlur}
                    />

                    {(input.id === "password" ||
                      input.id === "confirmPassword") && (
                        <button
                          type="button"
                          className={style.eyePassword}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <FaEye color="var(--greySecundary)" size={15} />
                          ) : (
                            <FaEyeSlash color="var(--greySecundary)" size={16} />
                          )}
                        </button>
                      )}
                  </div>
                </>
              )}

              {isError && (
                <p className={style.messageInput}>{isError}</p>
              )}
            </div>
          );
        })}

        <div className="passwordRequirementsContentMessage">
          {inputsList.some((input) => input.id === "password") && (
            <div
              className={`${validationResult || isPasswordValidPass
                ? "passwordRequirements close"
                : "passwordRequirements open"
                }`}
            >
              <div className="arrowRight"></div>
              <h4>{t("register.password_requirements.title")}:</h4>
              <ul>
                {passwordRequirements.map((req, index) => (
                  <li key={index}>
                    <SvgIcon
                      name={
                        inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? iconList.IconCheckSucces
                          : iconList.IconCloseCircleOutLine
                      }
                      color={
                        inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? "var(--greenPrimary)"
                          : "var(--redPrimary)"
                      }
                      size={20}
                    />
                    <span
                      style={{
                        color: inputsList
                          .find((input) => input.id === "password")
                          .value.match(req.regex)
                          ? "var(--greenPrimary)"
                          : "var(--redPrimary)",
                      }}
                    >
                      {req.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

      </form>
      <div className={style.buttonsActions}>
        <button
          onClick={() => !btnDisabled && handleRegisterEvent(true, url)}
          className={`${style.formBtnSend} ${btnDisabled ? style.disabled : style.formBtnSendAvaliable
            }`}
        >
          {loading ? (
            <LuLoader2 size={19} className={style.spinLoginModal} />
          ) : (
            <p className="formBtnSendText">{t("register.sign_up")}</p>
          )}
        </button>
        <div className={style.separationOr}>
          <div className="separation-line"></div>
            {t("register.or")}
          <div className="separation-line"></div>
        </div>

        <button onClick={() => setActiveLogin(true)} className={style.register}>
          {t("register.sign_in")}
        </button>
      </div>
    </div>
  );
};

const SessionModal = ({ activeSessionModal, setActiveSessionModal, url = false }) => {
  const [activeLogin, setActiveLogin] = useState(true);  

  return (
    <div
      className={`${style.modalContainerLogin} ${activeSessionModal ? style.open : style.close}`}
    >
      <div
        className={`${style.modalContent} ${activeSessionModal ? style.open : style.close}`}
        onClick={(e) => e.stopPropagation()}
      >
        {activeLogin ? (
          <Login setActiveLogin={setActiveLogin} url={url} />
        ) : (
          <Register setActiveLogin={setActiveLogin} url={url} />
        )}
      </div>
    </div>
  );
};

export default SessionModal;
