// HotelInfo.jsx
import React from 'react';
import styles from "../index.module.css";
import { Grid } from '@mui/material';
import { GoStarFill } from 'react-icons/go';
import { useState } from 'react';
import { MdCameraAlt } from 'react-icons/md';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const [month, day, year] = dateString.split('/');
  return `${month}/${day}/${year.slice(-2)}`;
};


const HotelInfo = ({ dataHotel }) => {

  const stars = parseInt(dataHotel?.stars, 10);

  const [loadedImages, setLoadedImages] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const handleImageLoad = (key) => {
    setLoadedImages(prevState => ({
      ...prevState,
      [key]: true
    }));
    setImageErrors(prevState => ({
      ...prevState,
      [key]: false
    }));
  };

  const handleImageError = (key) => {
    setImageErrors(prevState => ({
      ...prevState,
      [key]: true
    }));
  };

  const imageKey = 'hotelImage';

  return (
    <Grid container spacing={2} className={styles.mainHotelDiv}>
      <Grid item xs={12} md={3} className={styles.imageHotelDiv}>
        {(!loadedImages[imageKey] || imageErrors[imageKey]) && (
          <div className='img-transport-skeleton-cards'>
            <MdCameraAlt size={40} color="#c8c8c8" />
          </div>
        )}
        <img
          src={dataHotel?.photo}
          alt=""
          onLoad={() => handleImageLoad(imageKey)}
          onError={() => handleImageError(imageKey)}
          style={{ display: loadedImages[imageKey] && !imageErrors[imageKey] ? 'block' : 'none' }}
        />
      </Grid>

      <Grid item xs={12} md={6} className={styles.DataHotelDiv} sx={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.topInfoHotel} >
          <h3>{dataHotel?.hotel} </h3>
          <h4>{dataHotel?.address}</h4>
        </div> 

        <div className={styles.divInfoBottomHotel} >
          <div className={styles.divHotelData} >
            <h4>Fecha de ingreso</h4>
            <p>{formatDate(dataHotel?.arrival)}</p>
          </div>
          <div className={styles.divHotelData} >
            <h4>Check In</h4>
            <p>{dataHotel?.checkin}</p>
          </div>
          <div className={styles.divHotelData} >
            <h4>Fecha de salida</h4>
            <p>{formatDate(dataHotel?.departure)}</p>
          </div>
          <div className={styles.divHotelData} >
            <h4>Check Out</h4>
            <p>{dataHotel?.checkout}</p>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3} className={styles.starsHotelDivMain} sx={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }} >
        <div className={styles.starsHotelDiv} >
          {[...Array(5)].map((_, index) => (
            <GoStarFill
              key={index}
              className={index < stars ? styles.activeStarHotel : styles.inactiveStarHotel}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default HotelInfo;