import React, { useState, useEffect } from 'react';

function useJoinScreen(props) {
  const { getMeetingAndToken, meetingId } = props;
  const [code, setCode] = useState('');
  const [disableButton, setDisableButton] = useState(true)
  const [activeCall, setActiveCall] = useState(false)
  const [closeMeeting, setCloseMeeting] = useState(false);

  async function createRoom(btn) {
    try {
      if (!props?.creatingRoom && btn) {
        await getMeetingAndToken(code);
        
      }
      else if (!props?.creatingRoom && !btn && !disableButton) {
        await getMeetingAndToken(code);
    
      }


    } catch (error) {
      console.log("Error in createRoomClick()", error)
    }
  };

  useEffect(() => {
    if (meetingId) {
      setCode(meetingId)
    }
  }, [meetingId]);

  
  useEffect(() => {
    const res = code !=='' && code.length >= 12;
      setDisableButton(!res);    
  }, [code]);

  return {
    code,
    setCode,
    createRoom,
    disableButton,
    activeCall,
    setActiveCall,
    closeMeeting, 
    setCloseMeeting
  }
}

export default useJoinScreen;