import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useDynamicRefetch = (fetchFn, options = {}) => {
  const { interval = 30000 } = options; // Refetch interval by default (30 seconds)
  const location = useLocation();
  const intervalRef = useRef(null);
  const isRefetchingRef = useRef(false); // Flag to avoid duplication of requests

  // REFETCH FUNCTION WITH CONTROL
  const refetch = async () => {
    if (isRefetchingRef.current) return; // Prevents it from running if there is already an ongoing request

    isRefetchingRef.current = true; // Mark as "in progress"
    try {
      await fetchFn(); // Execute the function provided
    } catch (error) {
      console.error("Error during refetching:", error);
    } finally {
      isRefetchingRef.current = false; // Brand as completed
    }
  };

  // Refetch when changing route
  useEffect(() => {
    refetch(); // Make a request when changing the route
  }, [location.pathname]);

  // Refetch when returning to the active tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        clearInterval(intervalRef.current); // We stop any existing interval
        refetch(); // Make an immediate request
        intervalRef.current = setInterval(refetch, interval); // Restart the interval
      } else if (document.visibilityState === "hidden") {
        // We stop the interval when changing to another tab
        clearInterval(intervalRef.current);
      } else {
        // We restart the interval when returning to the active tab
        intervalRef.current = setInterval(refetch, interval);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalRef.current); // Cleaning when disassembling the component
    };
  }, [interval]);

  // Periodic refetch
  useEffect(() => {
    intervalRef.current = setInterval(refetch, interval);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [interval]);

  return null;
};

export default useDynamicRefetch;
