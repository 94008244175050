import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Puedes cambiar esto a otro almacenamiento si prefieres
import { combineReducers } from "redux";
import questions from "./slices/questions";
import dataSearchEngineReducer from "./slices/dataEnginePackage/dataEnginePackageSlice";
import dataEnginePackageReducer from "./slices/dataStepz/dataStepzSlice";
import exampleReducer from "./slices/example/exampleSlice";
import dataCartBuyReducer from "./slices/dataCartBuy/dataCartBuySlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  questions,
  dataSearchEngine: dataSearchEngineReducer,
  example: exampleReducer,
  dataStepz: dataEnginePackageReducer,
  dataCartBuy: dataCartBuyReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };