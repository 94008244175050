import { imageListClasses, Tooltip } from '@mui/material'
import React, { useState } from 'react' // Importa useState
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../../utils';
import { MdCameraAlt, MdLabelImportant } from "react-icons/md";
import { HiStar } from 'react-icons/hi';

const CardPackageSlider = ({ pack, imgType, width = "" }) => {
    const { image, price, name, currency, due_date, start_date, id } = pack;
    const navigate = useNavigate()

    // Estado para controlar si la imagen ha cargado
    // Estado para controlar si las imágenes se han cargado o no
    const [loadedImages, setLoadedImages] = useState({});

    // Estado para controlar si hay errores al cargar las imágenes
    const [imageErrors, setImageErrors] = useState({});


    function formatearFecha(fecha) {
        let fechaObj = new Date(fecha);
        let meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
        let dia = fechaObj.getDate();
        let mes = meses[fechaObj.getMonth()];
        return `${dia} de ${mes}`;
    }

    const handleClick = () => {
        navigate(`/packages/${id}`)
    }



    function textCutter(cantidadCaracteres, texto) {
        if (texto.length <= cantidadCaracteres) {
            return texto;
        }
        return texto.slice(0, cantidadCaracteres) + "...";
    }

    // Ajustar la ruta de la imagen según el tipo de imagen
    const imageUrl = `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${image}`;


    // Función para manejar cuando una imagen se carga correctamente
    const handleImageLoad = (key) => {
        setLoadedImages(prevState => ({
            ...prevState,
            [key]: true
        }));
        setImageErrors(prevState => ({
            ...prevState,
            [key]: false
        }));
    };

    // Función para manejar cuando una imagen no se carga correctamente
    const handleImageError = (key) => {
        setImageErrors(prevState => ({
            ...prevState,
            [key]: true
        }));
    };


    return (
        <div className='card-packages-new ' style={{ width: width ? width : "" }}>
            {/* Muestra el esqueleto solo si la imagen no ha cargado */}
            {(!loadedImages["imageKey"] || imageErrors["imageKey"]) && (
                <div className='img-transport-skeleton-cards'>
                    <MdCameraAlt size={40} color="#c8c8c8" />
                </div>
            )}
            <div className="image-container">
                <div className="icon-container-tourist">
                    <MdLabelImportant className="important-icon-tourist" />
                    <div className="star-icon-container-tourist">
                        <HiStar className="star-icon-tourist" />
                    </div>
                </div>
                <img
                    src={imageUrl}
                    alt={name}
                    onLoad={() => handleImageLoad("imageKey")}
                    onError={() => handleImageError("imageKey")}
                    style={{ display: (loadedImages["imageKey"] && !imageErrors["imageKey"]) ? 'block' : 'none' }}
                />

            </div>


            <div className='description'>
                <Tooltip title={name} placement="bottom" >
                    <h2>{textCutter(22, name)}</h2>
                </Tooltip>
                Paquete disponible hasta el {due_date}
            </div>
            <div className='price'>
                <span className='back-price'>
                    {formatMoney(price)} {currency}
                </span>
                <span className='see-more' onClick={handleClick}>
                    Ver más
                </span>
            </div>
        </div>
    )
}

export default CardPackageSlider