import "./styles.css";
import useInitianRoom from "../../../viewModels/videoSdk/initialRoom.viewModel";
import { MeetingProvider } from "@videosdk.live/react-sdk";
import { authToken } from "../../../../infra/constants/tokens.video.sdk";
import JoinScreen from "../joinScreen";
import MeetingView from "../meetingView";
import { useState } from "react";

function InitianRoomComponent({
  meetingId,
  getMeetingAndToken,
  onMeetingLeave,
  creatingRoom,
  user,
  isDisabledRoom,
  isValidatingRoom,
  setIsValidatingRoom,
}) {
  return (
    <div className="videsdk initianRoomComponent">
      
        {/* <MeetingView
          meetingId={meetingId}
          onMeetingLeave={onMeetingLeave}
          user={user}
          isDisabledRoom={isDisabledRoom}
          isValidatingRoom={isValidatingRoom}
        /> */}

        <JoinScreen
          getMeetingAndToken={getMeetingAndToken}
          meetingId={meetingId}
          user={user}
          isDisabledRoom={isDisabledRoom}
          isValidatingRoom={isValidatingRoom}
          setIsValidatingRoom={setIsValidatingRoom}
          onMeetingLeave={onMeetingLeave}
          creatingRoom={creatingRoom}
          token={authToken}
        />
      
    </div>
  );
}

function InitialRoom({ code }) {
  const {
    meetingId,
    getMeetingAndToken,
    onMeetingLeave,
    creatingRoom,
    user,
    isDisabledRoom,
    isValidatingRoom,
    setIsValidatingRoom,
  } = useInitianRoom(code);

  return (
    <InitianRoomComponent
      meetingId={meetingId}
      getMeetingAndToken={getMeetingAndToken}
      onMeetingLeave={onMeetingLeave}
      creatingRoom={creatingRoom}
      user={user}
      isDisabledRoom={isDisabledRoom}
      isValidatingRoom={isValidatingRoom}
      setIsValidatingRoom={setIsValidatingRoom}
    />
  );
}

export default InitialRoom;
