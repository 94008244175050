import styles from "./index.module.css";
import { useEffect, useState } from "react";
import useSearchBuildPackage from "@/Hooks/BuildPackage/useBuildPackageSearchEngine/UseBuildPackageSearchEngine";
import SvgIcon, { iconList } from "@/ui/views/share/components/iconSVG";
import { Grid } from "@mui/material";
import Hotel from "./Hotel";
import Clinic from "./Clinic";
import Specialist from "./Specialist";
import Flight from "./Flight";
import { IoChevronUp } from "react-icons/io5";
import axios from "axios";
import Std from "./Std";
import MyItineraryMain from "./ItineraryApiComponentes/MyItineraryMain";
import { useTranslation } from "react-i18next";

const truncateText = (text, maxLength) => {
  let truncatedText = text;
  if (text.length > maxLength) {
    truncatedText = text.substring(0, maxLength) + '...';
  }
  return truncatedText.charAt(0).toUpperCase() + truncatedText.slice(1).toLowerCase();
};

const truncateTextSinPuntos = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '';
  }
  return text;
};

const MyItinerary = ({ userId }) => {

  const {
    dataPackage,
  } = useSearchBuildPackage();

  const [itinerary, setItinerary] = useState([]);
  const {t, i18n} = useTranslation("global");

  const sections = [
    {
      key: 'flight',
      icon: iconList.IconPlane,
      title: dataPackage.flight ? `${t('profile.modules.my_itinerary.flight_to')} ${dataPackage.flight.itinerary[0].to}` : null,
      name: dataPackage.flight ? `${t('profile.modules.my_itinerary.from')} ${dataPackage.flight.itinerary[0].from}` : null,
    },
    {
      key: 'specialist',
      icon: iconList.IconDoctor,
      title: dataPackage.specialist?.name,
      name: dataPackage.specialist?.categories[0].name,
    },
    {
      key: 'hotel',
      icon: iconList.IconHotel,
      title: dataPackage.hotel?.dataHot?.nombre_h,
      name: dataPackage.hotel ? `${dataPackage.hotel.dataHab.dir}` : null,
    },
    {
      key: 'clinic',
      icon: iconList.IconHospital,
      title: dataPackage.clinic?.name,
      name: dataPackage.clinic?.address,
    },
  ];

  const stdData = JSON.parse(localStorage.getItem('data-std'));
  const includeStd = stdData && (
    stdData.height || stdData.weight || stdData.operationDate ||
    stdData.drinkAlcohol || stdData.smoke || stdData.sons ||
    stdData.consumeMedicaments || stdData.drugsConsume ||
    stdData.haveIllness || stdData.haveSurgery || stdData.youAlergic ||
    stdData.youAlergicKnowed || stdData.whichAlergic || stdData.whichAlergicKnowed ||
    stdData.whichDrugs || stdData.whichIllness || stdData.whichMedicaments || stdData.whichSurgery
  );

  if (includeStd) {
    sections.push({
      key: 'std',
      icon: iconList.IconWalletOutLine,
      title: i18n.language === 'es' ? 'STD' : 'TDS',
      name: t('profile.modules.my_itinerary.std'),
    });
  }

  const [itineraryApi, setItineraryApi] = useState([]);

  const [selectedSection, setSelectedSection] = useState(null);

  const visibleSections = sections.filter(section => section.name);
  const columnSize = visibleSections.length === 5 ? 2.4 :
    visibleSections.length === 4 ? 3 :
      visibleSections.length === 3 ? 4 :
        visibleSections.length === 2 ? 6 :
          visibleSections.length === 1 ? 12 :
            Math.floor(12 / visibleSections.length);

  const handleSectionClick = (key) => {
    if (selectedSection === key) {
      setSelectedSection(null);
    } else {
      setSelectedSection(key);
    }
  };

  const getUserItinerary = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-itinerary/${userId}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
       console.error(`${t('profile.modules.my_itinerary.errors.get_itinerary')}:`, error);
      return [];
    }
  };

  const saveUserSelectedItineraryFromLocalToDB = async () => {
    try {
      const body = {
        user_id: userId,
        flight: dataPackage.flight ? JSON.stringify(dataPackage.flight) : null,
        specialist: dataPackage.specialist ? JSON.stringify(dataPackage.specialist) : null,
        hotel: dataPackage.hotel ? JSON.stringify(dataPackage.hotel) : null,
        clinic: dataPackage.clinic ? JSON.stringify(dataPackage.clinic) : null,
      }

      const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-itinerary`;
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
       console.error(`${t('profile.modules.my_itinerary.errors.save_itinerary')}:`, error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUserItinerary = async () => {
      const itinerary = await getUserItinerary();

      const dataToSave = {
        flight: itinerary[0]?.flight ? JSON.parse(itinerary[0].flight) : null,
        specialist: itinerary[0]?.specialist ? JSON.parse(itinerary[0].specialist) : null,
        hotel: itinerary[0]?.hotel ? JSON.parse(itinerary[0].hotel) : null,
        clinic: itinerary[0]?.clinic ? JSON.parse(itinerary[0].clinic) : null,
      }

      setItinerary(dataToSave);
    };

    fetchUserItinerary();
  }, []);

  useEffect(() => {
    const hasSavedItinerary = localStorage.getItem('has-saved-itinerary');

    if (dataPackage && hasSavedItinerary !== 'true') {
      const saveUserSelectedItinerary = async () => {
        try {
          const itinerary = await saveUserSelectedItineraryFromLocalToDB();
          localStorage.setItem('has-saved-itinerary', 'true');
          setItinerary(itinerary);
        } catch (error) {
          console.error(`${t('profile.modules.my_itinerary.errors.save_itinerary')}:`, error);
        }
      }

      if (dataPackage) {
        saveUserSelectedItinerary();
      }
    }
  }, [dataPackage]);


  const getItineraryUser = async () => {
    try {
      const body = {
        user_id: userId,
      };

      const url = `https://pdtclientsolutions.com/crm-public/api/wellezy/get/std/${userId}`;
      const response = await axios.post(url, body);
      return response.data;
    } catch (error) {
       console.error(`${t('profile.modules.my_itinerary.errors.get_itinerary')}:`, error);
      return [];
    }
  };

  useEffect(() => {
    const fetchItinerary = async () => {
      const data = await getItineraryUser(); 
      setItineraryApi(data);
    };

    fetchItinerary();
  }, []);

  return (
    <div className={styles.myMovements}>
      <h1>{t('profile.modules.my_itinerary.text')}</h1>
      <h2>{t('profile.modules.my_itinerary.inprocess_package')}</h2>
      <div className={styles.containerGrayItinerary}>
        <Grid container spacing={0}>
          {visibleSections.length === 0 ? (
            <div className={styles.avoidSections}>
              <h3>{t('profile.modules.my_itinerary.no_inprocess_package')}</h3>
              <button>
                <a href="/build-package">
                  {t('profile.modules.my_itinerary.build_package')}
                </a>
              </button>
            </div>
          ) : (
            visibleSections.map((section, index) => (
              <Grid item xs={12} sm={6} md={columnSize} key={section.key}
                className="itemPackageProfile"
                sx={{
                  borderRight: {
                    xs: 'none', // No borderRight en pantallas pequeñas
                    md: index !== visibleSections.length - 1 ? '3px solid #104172' : 'none', // borderRight en pantallas medianas y grandes
                  },
                  padding: '4px',
                }}
              >
                <div className={`${styles.divTopCardPacgaMain} ${selectedSection === section.key ? styles.divTopCardPacgaMainactive : ''}`} onClick={() => handleSectionClick(section.key)}>
                  <div>
                    <SvgIcon color={selectedSection === section.key ? "white" : "var(--bluePrimary)"} name={section.icon} size={56} />
                  </div>
                  <div className={`${styles.divBottomCardPacga} ${selectedSection === section.key ? styles.divBottomCardPacgaActive : ''}`}>
                    <h3>{truncateTextSinPuntos(section.title, 10)}</h3>
                    <span>{truncateText(section.name, 13)}</span>
                  </div>
                </div>
              </Grid>
            ))
          )}

          <div style={{ borderBottom: '2px solid #ECECEC', width: '100%', marginTop: '1.5rem', marginBottom: '1.7rem' }}> <p style={{ display: 'none' }}>.</p> </div>
        </Grid>

        {selectedSection === 'flight' && (
          <Flight dataPackage={dataPackage} />
        )}
        {selectedSection === 'specialist' && (
          <Specialist dataPackage={dataPackage} />
        )}
        {selectedSection === 'hotel' && (
          <Hotel dataPackage={dataPackage} />
        )}
        {selectedSection === 'clinic' && (
          <Clinic dataPackage={dataPackage} />
        )}
        {selectedSection === 'std' && (
          <Std />
        )}

        {selectedSection && (
          <div className={styles.CloseUpItinerary} onClick={() => setSelectedSection(null)}>
            <IoChevronUp />
          </div>
        )}
      </div>

      <MyItineraryMain dataItineraryApi={itineraryApi} />
    </div>
  );
}

export default MyItinerary;
