import { useState } from 'react';
import axios from 'axios';
import "./styles.css";
import { FaCheckCircle } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import toast, { Toaster } from "react-hot-toast";
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel';
import { useEffect } from 'react';

function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
}

const ReservationForm = ({ handleClose, packageMedicData }) => {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [document, setDocument] = useState('');
    const [cel, setCel] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [comments, setComments] = useState('');

    const { userDetail } = useHeaderSession() 

    const navigate = useNavigate();

    useEffect(() => {
        if (userDetail?.email) {
            setEmail(userDetail.email);
        }
        if (userDetail?.phone_public) {
            setCel(userDetail.phone_public);
        }
        if (userDetail?.name) {
            const nameParts = userDetail.name.split(' ');
            setName(nameParts[0]);
            setLastname(nameParts.slice(1).join(' '));
        }
    }, [userDetail]);


    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleNumberInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setter(value);
    };

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    const validateForm = () => {
        if (!comments || !city || !name || !lastname || !document || !cel || !email) {
            notify("Debes llenar todos los datos", "❌");
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            notify("Debes ingresar un correo electrónico válido", "❌");
            return false;
        }

        return true;
    };

    const createLinkPay = async () => {
        try {
            const authUserSession = await JSON.parse(localStorage.getItem("@authUserSession") || "{}");

            if (!authUserSession?.user?.id) {
                navigate("/login");
                return;
            }

            if (!validateForm()) {
                return;
            }

            const data = {
                "name": name,
                "lastname": lastname,
                "document": document,
                "email": email,
                "cel": cel,
                "localizator": document,
                "type": "medicalPackage",
                "currency": "COP",
                "price": 200000, 
                "url": `${getBaseUrl(window.location.href)}/TourismHealth/medicalPackagePay`,
            }; 

            localStorage.setItem('data-pay-medicalpackage', JSON.stringify(data));
            const url = process.env.REACT_APP_URL_CREATE_LINK;
            const medicalPaymentLinkCreation = await axios.post(url, data)

            if (medicalPaymentLinkCreation.data?.status?.status === "FAILED") {
                notify("Error al crear link de pago", "❌");
            } else {
                localStorage.setItem("request-id-medicalpackage", medicalPaymentLinkCreation.data?.requestId);

                // Guardar datos en localStorage
                const requestData = {
                    ...data,
                    city: city,
                    comments: comments,
                    packageId: packageMedicData.id,
                    packagePrice: packageMedicData.price,
                    packageName: packageMedicData.name,
                    packageImage: packageMedicData.image,
                    package:packageMedicData
                };

                // Eliminar datos anteriores en localStorage
                localStorage.removeItem('request-medical-datapackage');

                const existingRequests = JSON.parse(localStorage.getItem('request-medical-datapackage')) || [];
                existingRequests.push(requestData);
                localStorage.setItem('request-medical-datapackage', JSON.stringify(existingRequests));

                window.location.href = medicalPaymentLinkCreation.data?.processUrl;
            }
        } catch (error) {
            notify("Error al crear link de pago", "❌");
            console.log(error);
        }
    };

    const fields = [
        { label: 'Nombres *', placeholder: 'Nombre del tomador', value: name, setter: setName, type: 'text', show: !userDetail?.name },
        { label: 'Apellidos *', placeholder: 'Apellido del tomador', value: lastname, setter: setLastname, type: 'text', show: !userDetail?.name },
        { label: 'Documento *', placeholder: 'Documento del tomador', value: document, setter: setDocument, type: 'number', show: true },
        { label: 'Teléfono *', placeholder: 'Teléfono del tomador', value: cel, setter: setCel, type: 'number', show: !userDetail?.phone_public },
        { label: 'Email *', placeholder: 'Email del tomador', value: email, setter: setEmail, type: 'text', show: !userDetail?.email },
        { label: 'Ciudad *', placeholder: 'Ciudad del tomador', value: city, setter: setCity, type: 'text', show: true },
        { label: 'Comentarios adicionales *', placeholder: 'Ingrese comentarios adicionales', value: comments, setter: setComments, type: 'text', show: true },
    ];

    return (
        <div className="formMedicalPackage">
            <Toaster position="top-center" reverseOrder={false} />

            <div className='topReservePackage'>
                <p>Confirmar reserva de paquete</p>
                <IoClose onClick={handleClose} />
            </div>

            <Grid container alignItems="center" spacing={2} mt={'1px'}>
                {fields.map((field, index) => (
                    field.show && (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            <div className='InputDivPackage'>
                                <p>{field.label}</p>
                                <input
                                    type={field.type === 'number' ? 'text' : field.type}
                                    placeholder={field.placeholder}
                                    value={field.value}
                                    onChange={field.type === 'number' ? handleNumberInputChange(field.setter) : handleInputChange(field.setter)}
                                />
                            </div>
                        </Grid>
                    )
                ))}
            </Grid>

            <div className='divCheckPay'>
                <button onClick={createLinkPay}>
                    <FaCheckCircle />
                    Comprar reserva
                </button>
            </div>
        </div>
    );
};

export default ReservationForm;