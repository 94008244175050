import "./styles.css";
import { ReactComponent as IconPlane2 } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconPlane2.svg';
import { ReactComponent as IconDoctor } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconDoctor.svg';
import { ReactComponent as IconHospital } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconHospital.svg';
import { ReactComponent as IconHotel } from '../../../../../Components/SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconHotel.svg';
import { GrFormAdd } from "react-icons/gr";
import { useTranslation } from "react-i18next";

function BrowseHome({ pathname }) {
  const [t, i18n] = useTranslation("global");

  return ( 
    <section className="browseHomeColumn">
      <div className="divBanner">
        <img src="/images/bannerHome.png" alt="wellezy clinic" className="bannerImg bannerImg-desktop" />
        <img src="/images/BannerResponsive.png" alt="wellezy clinic" className="bannerImg bannerImg-mobile" />

        <div className="textOverlay">
          <div className="textOverlayTop">
            <div className="textOverlayTitle">
              <h2 className="titleOverlay1">{t("home.banner.first_title")}</h2>
              <h2 className="titleOverlay2">{t("home.banner.second_title")}</h2>
              <h3>{t("home.banner.subtitle")}</h3>
            </div>
          </div>


          <div className="contentMidOverlay">
            {t("home.banner.description")}
          </div>

          <div className="divIccons">
            <div className="flex flex-col items-center">
              <IconPlane2 className="iconDivHome w-[60px]" />
              <p>{t("home.banner.first_benefit")}</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div className="flex flex-col items-center">
              <IconHotel className="iconDivHome w-[60px]" />
              <p>{t("home.banner.second_benefit")}</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div className="flex flex-col items-center">
              <IconDoctor className="iconDivHome w-[60px]" />
              <p>{t("home.banner.third_benefit")}</p>
            </div>
            <span><GrFormAdd className="iconSumHome" /></span>
            <div className="flex flex-col items-center">
              <IconHospital className="iconDivHome w-[60px]" />
              <p>{t("home.banner.fourth_benefit")}</p>
            </div>
          </div>

          <div className="mainIconsDiv">
            <button>
              <a href="/build-package">
                {t("home.banner.button_text")}
              </a>
            </button>

            <p>{t("home.banner.footer_text")}</p>
          </div>

        </div>
      </div>
    </section>
  );
}
export default BrowseHome;
