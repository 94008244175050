import { createSlice } from "@reduxjs/toolkit";

export const dataCartBuySlice = createSlice({
    name: 'dataCartBuy',
    initialState: {
        dataCart: [],
    },
    reducers: {
        setDataCart: (state, action) => {
            state.dataCart = action.payload;
        },
    }
});

export const { 
    setDataCart
} = dataCartBuySlice.actions;

export default dataCartBuySlice.reducer;