import { useState, useEffect, useRef, useContext } from 'react';
import { logOut } from './index';
import UserContext from '../../../Context/user/userProvider';
import {useTranslation} from "react-i18next"

function useHeaderSession() {
  const { userDetail } = useContext(UserContext);
  const cardRef = useRef()
  const [t,i18n] = useTranslation("global")
  const [isOpen, setIsOpen] = useState(false);
  const [isIssetSession, setIsIssetSession] = useState(false);

  const optionListWithSession = [
    {
      text: t("home.nav_bar.profile.my_profile"),
      url: '/profile'
    },
    {
      text: t("home.nav_bar.profile.logout"),
      action: closeSession
    },
  ]
  const optionListWithOutSession = [
    {
      text: t("browse_home.nav_bar.join_club.register_me"),
      url: '/register'
    },
    {
      text: t("browse_home.nav_bar.join_club.login"),
      url: '/login'
    },
  ]

  const optionList = isIssetSession ? optionListWithSession : optionListWithOutSession;

  async function closeSession() {
    try {
      await logOut()
      localStorage.clear();
    } catch (error) {
      console.error('Error Logout from header:', error)
    }
  }

  useEffect(() => {
    setIsIssetSession(!!userDetail);
  }, [isOpen])

  useEffect(() => {
    function handleClickOutside(event) {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  return {
    isOpen,
    setIsOpen,
    cardRef,
    optionList,
    userDetail,
  }
}

export default useHeaderSession;
