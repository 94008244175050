import Axios from "axios";
import { useState, useCallback, useEffect, useContext } from "react";
import UserContext from "../../../Context/user/userProvider";
import { keys, set as setLocalStorage } from "../../localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { formatToISO } from "@/common/services/dateUtils";
import { genderList } from "@/Api/genderList";
import { useTranslation } from "react-i18next";

function useRegisterUser() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const { setUserDetail, setUserToken } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    prex_and_phone_public: "",
    phone_prefix: "",
    email: "",
    password: "",
    confirmPassword: "",
    birthDate: "",
    gender: "",
  });

  // Function to validate entries
  const validateInput = (id, value) => {
    let error = "";
    if (value.trim() === "") {
      error = t("register.errors.required_field");
    } 

    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
  };

  const handleInputChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      validateInput(name, value);
    },
    [formValues]
  );

  const handleBlur = (event) => {
    const { id, value } = event.target;
    validateInput(id, value);
  };

  const inputsList = [
    {
      label: t("register.full_name"),
      type: "text",
      id: "name",
      value: formValues.name,
      placeholder: i18n.language === "es" ? "Ingresa tu nombre" : "Enter your name",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      type: "flex",
      fields: [
        {
          label: t("register.prefix_and_phone"),
          type: "text",
          id: "prex_and_phone_public",
          value: formValues.prex_and_phone_public,
          placeholder: i18n.language === "es" ? "Ingresa un prefijo y un número" : "Enter a prefix and a number",
          required: true,
          func: (value, data) => {
            // Save the prefix directly in form values
            setFormValues((prevValues) => ({
              ...prevValues,
              prex_and_phone_public: value,
              phone_prefix: data.dialCode
            }));

            validateInput("prex_and_phone_public", value);
          },
        },
        {
          label: t("register.birth_date"),
          type: "date",
          id: "birthDate",
          value: formValues.birthDate,
          required: true,
          func: (value) => {
            setFormValues((prevValues) => ({
              ...prevValues,
              birthDate: value,
            }));

            validateInput("birthDate", value);
          },
        },
        {
          label: t("register.gender"),
          type: "text",
          id: "gender",
          value: formValues.gender,
          options: genderList.map((item) => t(`gender_list.${item}`)),
          placeholder: i18n.language === "es" ? "Selecciona un género" : "Select a gender",
          required: true,
          func: handleInputChange,
          onBlur: handleBlur,
        },
      ],
    },
    {
      label: t("register.email"),
      type: "email",
      id: "email",
      value: formValues.email,
      placeholder: i18n.language === "es" ? "nombre@gmail.com" : "name@gmail.com",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      label: t("register.password"),
      type: showPassword ? "text" : "password",
      id: "password",
      value: formValues.password,
      placeholder: "***************",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
    {
      label: t("register.confirm_password"),
      type: showPassword ? "text" : "password",
      id: "confirmPassword",
      value: formValues.confirmPassword,
      placeholder: "***************",
      required: true,
      func: handleInputChange,
      onBlur: handleBlur,
    },
  ];

  useEffect(() => {
    const hasErrors = Object.values(inputErrors).some((error) => error);
    const allFieldsFilled = Object.keys(formValues).every(
      (key) => formValues[key] && formValues[key].trim() !== ""
    );
  
    setBtnDisabled(hasErrors || !allFieldsFilled);
  }, [formValues, inputErrors]);    
  

  const handleRegister = async (modal, urlT) => {
    if (loading) return;
    setLoading(true);

    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/register`;
      const { name, email, password, prex_and_phone_public, phone_prefix, birthDate, gender } = formValues;
      
      const phoneNumber = prex_and_phone_public.slice(phone_prefix.length); // Remove the prefix from the phone number to send them separately

      const { data: userData } = await Axios.post(url, {
        name,
        email,
        password,
        prex_phone_public: phone_prefix,
        phone_public: phoneNumber,
        birth_date: formatToISO(birthDate),
        gender: i18n.language === "es" ? gender : gender === "Male" ? "Masculino" : gender === "Female" ? "Femenino" : "No especificado",
      });

      if (userData && userData.token) {
        const session = await setLocalStorage(keys.authUserSession, userData);
        if (session) {
          const screen = !modal ? "/" : urlT ? urlT : location.pathname;
          setUserDetail(userData.user);
          setUserToken(userData.token);
          navigate(screen);
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const { errors } = error.response.data;

        if (errors.email?.length) {
          const errorMsg = t("register.errors.email_taken");

          setInputErrors((prevErrors) => ({
            ...prevErrors,
            email: errorMsg,
          }));
        }

        if (errors.birth_date?.length) {
          const errorMsg = t("register.errors.required_birth_date");

          setInputErrors((prevErrors) => ({ 
            ...prevErrors,
            birthDate: errorMsg,
          }));
        }

        throw new Error(t("register.errors.sign_up"));
      }
    } finally {
      setLoading(false);
    }
  };


  return {
    inputsList,
    btnDisabled,
    showPassword,
    setShowPassword,
    loading,
    inputErrors,
    handleRegister,
    validateInput,
    setFormValues
  };
}

export default useRegisterUser;