import useInitialRoomPX from '../../../../viewModels/videoSdk/initialRoomPX.voewModel';
import ErrorMeet from '../errorMeet';
import './styles.css';
import { MeetingProvider } from "@videosdk.live/react-sdk";
import MeetingViewPX from '../meetingView';
import Controls from '../../components/controls';


const FormNamePX = ({ name, change, join }) => {
  return (
    <div className='formNamePX'>
      <p className="formNamePXTitlePX">Bienvenid@!</p>
      <p className="formNamePXSubtitlePX">Para poder continuar debes ingresar tu nombre.</p>
      <input
        type="text"
        placeholder="Write your name here..."
        value={`${name}`}
        onChange={(e) => {
          change(e?.target?.value);
        }}
      />
      <div onClick={join} className='formNamePXSubtitleBtnPX'>
        <p className='formNamePXSubtitleBtnTextPX'>Join</p>
      </div>
    </div>
  )
}




function InitialRoomPX() {

  const {
    mssg,
    isErrorMeet,
    namePX,
    step,
    setStep,
    changeName,
    joinToMeet,
    authToken,
    idRoom,

  } = useInitialRoomPX();


  

  return (
    <div className='initialRoomPX'>
      {isErrorMeet && <ErrorMeet mssg={mssg} />}
      {!isErrorMeet && step === 1 && <FormNamePX name={namePX} change={changeName} join={joinToMeet} />}

      {!isErrorMeet && step === 2 &&
        <MeetingProvider
          config={{
            meetingId: idRoom,
            micEnabled: true,
            webcamEnabled: true,
            name: namePX,
          }}
          token={authToken}
        >
          <>
            <MeetingViewPX
              name={namePX}
              back={() => { }}
              meetingId={idRoom}
              setIsShowControls={() => { }}
              setTotalParticipants={() => { }}
              setMicOn={() => { }}
              setWebcamOn={() => { }}
              setParticipantId={() => { }}
            />
            
          </>

        </MeetingProvider>
      }
    </div>
  )
}
export default InitialRoomPX