import React, { useContext, useEffect, useRef, useState } from "react";
import { IoMdNotificationsOutline, IoMdNotifications } from "react-icons/io";
import NotificationModal from "./NotificationModal";
import useDynamicRefetch from "@/common/hooks/useDynamicRefetch";
import UserContext from "@/Context/user/userProvider";
import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";

function NotificationBell() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastNotificationLength, setLastNotificationLength] = useState(0);
  const modalRef = useRef(null);
  const { userDetail } = useContext(UserContext);

  const {notifications, setNotifications, getUserNotifications, markUserNotificationAsRead, unreadNotificationLength} = useUserProfile()

  const fetchNotifications = async () => {
    try {
      if (!userDetail) {
        return;
      }

      const newNotifications = await getUserNotifications();

      if (newNotifications.length > lastNotificationLength) {
        setNotifications(() => [...newNotifications]);
        setLastNotificationLength(newNotifications.length);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      if (!userDetail) {
        return;
      }

      await markUserNotificationAsRead(notificationId);

      const newNotifications = await getUserNotifications();

      setNotifications(newNotifications);
      
      fetchNotifications();
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  useDynamicRefetch(fetchNotifications, { interval: 20000 });

  useEffect(() => {
    fetchNotifications();
  }, [userDetail]);

  useEffect(() => {
    const handleNotificationUpdate = (event) => {
      fetchNotifications(); // Update notifications after listened event of notificationUpdated
    };

    window.addEventListener("notificationUpdated", handleNotificationUpdate);

    return () => {
      window.removeEventListener("notificationUpdated", handleNotificationUpdate);
    };
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      {/* Campana */}
      <div
        className="relative cursor-pointer text-white"
        onClick={() => setIsModalOpen((prev) => !prev)}
        title="Notificaciones"
      >
        {unreadNotificationLength > 0 ? (
          <IoMdNotifications className="text-white w-6 h-6" />
        ) : (
          <IoMdNotificationsOutline className="text-white w-6 h-6" />
        )}
        {unreadNotificationLength > 0 && (
          <span className="absolute -top-1 -right-2 bg-red-500 text-white text-xs font-bold rounded-full px-1">
            {unreadNotificationLength}
          </span>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div ref={modalRef}>
          <NotificationModal
            notifications={notifications}
            onClose={() => setIsModalOpen(false)}
            onMarkAsRead={handleMarkAsRead}
          />
        </div>
      )}
    </div>
  );
}

export default NotificationBell;