import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoginUser from "../../../viewModels/session/login.user.viewModel";
import TextCarousel from "../components/TextCarousel/TextCarousel";
import { global } from "../../../global";
import "./styles.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { LuLoader2 } from "react-icons/lu";
import { useTranslation } from "react-i18next";

function UserLogin() {
  const {
    screensList,
    inputsList,
    btnDisable,
    showPassword,
    setShowPassword,
    loading,
    getInputError,
    onLogin,
  } = useLoginUser();

  const [t, i18n] = useTranslation("global");
  const { BannerTours } = global.img.pictures;
  const {
    logos: { wellezyLogo },
  } = global.img;

  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !btnDisable) {
      event.preventDefault();
      onLogin();
    }
  };

  useEffect(() => {
    const authUserSession = JSON.parse(
      localStorage.getItem("@authUserSession") || "{}"
    )

    if (authUserSession?.user?.id) {
      navigate("/profile");
    }
  }, []);

  return (
    <div className="userRegister" onKeyDown={handleKeyPress}>
      <div className="userRegisterContainer">
        <section className="sectionRight">
          <Link to="/">
            <img
              src={wellezyLogo}
              alt="Logo Wellezy"
              className="logo-wellezy-login"
            />
          </Link>
          <div className="sectionRightTextContainer">
            <TextCarousel />
          </div>
          <img src={BannerTours} alt="background" className="background" />
        </section>

        <section className="sectionLeft">
          <div className="sectionLeftLogin">
            <div></div>
            <div className="userRegisterFormContainer paddingLogin">
              <div className="userRegisterFormContainerHead">
                <p className="userRegisterFormTitle">{t("login.title")}</p>
              </div>
              <form className="userRegisterFormContainerBody">
                {inputsList.map((input, key) => {
                  const error = getInputError(input);
                  return (
                    <div key={key} className="userRegisterInputContainer">
                      <label className="userRegisterInputLabel">
                        {input.label}
                      </label>
                      <div
                        className={`userRegisterInput ${
                          error ? "userRegisterInputError" : ""
                        }`}
                      >
                        <input
                          type={input.type}
                          id={input.id}
                          value={input.value}
                          placeholder={input.placeholder}
                          onChange={input.func}
                          onBlur={input.onBlur}
                          className="userRegisterInputInput"
                        />
                        {input.id === "password" && (
                          <div
                            className="userRegisterInputShowPassword"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!showPassword ? (
                              <FaEye color="var(--greySecundary)" size={19} />
                            ) : (
                              <FaEyeSlash
                                color="var(--greySecundary)"
                                size={20}
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {error && (
                        <p className="userRegisterInputErrorLabel">{error}</p>
                      )}
                    </div>
                  );
                })}
              </form>

              <div className="userRegisterFormContainerFoot !mt-4">
                <button
                  onClick={() => !btnDisable && onLogin()}
                  className={`formBtnSend ${
                    btnDisable ? "disabled" : "formBtnSendAvaliable"
                  }`}
                >
                  {loading ? (
                    <LuLoader2 size={19} className="loaderLoginRegister" />
                  ) : (
                    <p className="formBtnSendText">{t("login.log_in")}</p>
                  )}
                </button>
              </div>

              <div className="separation-or">
                <div className="separation-line"></div>
                {t("login.or")}
                <div className="separation-line"></div>
              </div>

              <Link className="button-register" to={screensList.register}>
                {t("login.register")}
              </Link>
              <div className="links-web-mobile">
              <Link className="ofertsLinkLogin" to="/">
                {t("login.nav_bar.home")}
              </Link>
              <Link className="ofertsLinkLogin" to="/about">
                {t("login.nav_bar.about_us")}
              </Link>
              <Link className="ofertsLinkLogin" to="/contact">
                {t("login.nav_bar.contact_us")}
              </Link>
            </div>
            </div>

            <div className="links-web" style={{ top: "85%" }}>
              <Link className="ofertsLinkLogin" to="/">
                {t("login.nav_bar.home")}
              </Link>
              <Link className="ofertsLinkLogin" to="/about">
                {t("login.nav_bar.about_us")}
              </Link>
              <Link className="ofertsLinkLogin" to="/contact">
                {t("login.nav_bar.contact_us")}
              </Link>
            </div>
            <div className="item-between"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default UserLogin;
